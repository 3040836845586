<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card
        data-test="card-operacao-remessa"
        class="box card-cambio"
        :class="operation.exchangeBuy ? 'isBuy' : 'isSell'"
      >
        <v-row class="pt-4">
          <v-col
            cols="12"
            md="3"
            class="py-0 my-0"
          >
            <v-radio-group
              data-test="radio-group-tipo-operacao"
              class="py-0 my-0"
              v-model="operation.exchangeBuy"
              row
              mandatory
            >
              <v-radio
                data-test="radio-venda"
                :value="false"
                color="colorSell"
                label="Venda/Saída"
              />
              <v-radio
                data-test="radio-compra"
                :value="true"
                color="colorBuy"
                label="Compra/Entrada"
              />
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-cliente-remessa"
              label="Cliente / CPF / CNPJ"
              :items="customerList"
              v-model="operation.customer"
              item-value="id"
              :item-text="itemTextCustomers"
              return-object
              dense
              clearable
              autofocus
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
              :loading="customerSearchLoading"
              :search-input.sync="customerSearchQuery"
              hide-no-data
              cache-items
              :filter="caseInsensitiveAccentsInsensitiveIgnoreDotsDashes"
              ref="customerAutocomplete"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-banco"
              label="Banco"
              :items="bankList"
              v-model="operation.bank"
              item-text="fantasyName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[
                validationIsRequiredAutocompleteId,
                validationBankHasLimit,
                customerHasPaymentAccountForBank
              ]"
              :item-disabled="(item) => !!bankIsDisable(item)"
              persistent-hint
              :hint="bankLimitHint"
            >
              <template #item="data">
                <v-row
                  no-gutters
                  class="py-2"
                >
                  <v-col>
                    <v-row no-gutters>
                      <v-col>
                        <span class="bank-title">{{ data.item.fantasyName }}</span>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col>
                        <span class="subtitle">
                          Limite: <b>{{ getLimitBank(data.item) }}</b>
                        </span>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col>
                        <span class="subtitle">
                          Expira em: <b>{{ getExpireBank(data.item) }}</b>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <span class="subtitle"
                          ><b>{{ bankIsDisable(data.item) }}</b></span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-natureza-operacao-remessa"
              label="Natureza"
              :items="natureList"
              v-model="operation.nature"
              item-value="id"
              item-text="exibitionName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            >
              <template #append-outer>
                <InfoPopover
                  v-if="operation.nature?.observation"
                  :label="operation.nature.observation"
                />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <CurrencySelect
              data-test="currencyselect-moeda"
              :currency-list="currencies"
              v-model="operation.currency"
              :required="true"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-quantidade-me"
              label="Quantidade ME"
              prefix=" "
              v-model="operation.amount"
              :rules="[validationIsRequiredFieldNumeric, validationOperationHasLimit]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-taxa-cliente"
              label="Taxa do cliente"
              prefix="R$ "
              suffix=""
              :precision="operation.currency.precision"
              v-model="operation.customerRate"
              persistent-hint
              :hint="customerRateHint"
              :min="operation.customerRateMin"
              :max="operation.customerRateMax"
              :rules="[validationIsRequiredFieldNumeric]"
              truncate
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-spread"
              label="Spread"
              suffix=" %"
              prefix=""
              v-model="operation.spread"
              persistent-hint
              :hint="spreadHint"
              :min="operation.spreadMin"
              :max="operation.spreadMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-lucro"
              label="Lucro"
              :precision="2"
              disabled
              v-model="profit"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-tarifa"
              label="Tarifa"
              :prefix="operationProp.useUsdExchangeContract ? 'US$ ' : 'R$ '"
              suffix=""
              v-model="operation.exchangeContractCost"
              :rules="[validationIsRequiredFieldNumericAllowZero]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-liquidacao"
              label="Liquidação"
              :items="liquidationList"
              v-model="operation.liquidation"
              item-value="id"
              item-text="description"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-conta"
              :label="operation.exchangeBuy ? 'Conta Brasil' : 'Conta exterior'"
              :items="accountList"
              v-model="account"
              item-value="id"
              :item-text="itemTextAccount"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
              :filter="customFilterAccount"
            >
              <template #append-outer>
                <v-tooltip
                  bottom
                  data-test="tooltip-copiar-informacoes"
                >
                  <template #activator="{ on }">
                    <v-btn
                      data-test="botao-copiar-informacoes"
                      :disabled="
                        operation.exchangeBuy ? !operation.account.bank : !operation.account.bank
                      "
                      icon
                      class=""
                      @click="copyToClipboard"
                      text
                      v-on="on"
                    >
                      <i class="far fa-copy" />
                    </v-btn>
                  </template>
                  <span>
                    Copiar informações da
                    {{ operation.exchangeBuy ? 'conta no Brasil' : 'conta no exterior' }}
                  </span>
                </v-tooltip>
              </template>
              <template
                v-if="!operation.exchangeBuy"
                #item="data"
              >
                <v-row class="pa-2">
                  <v-col
                    cols="12"
                    col="6"
                    class="px-0 d-block"
                  >
                    <p class="item-title">
                      Conta bancária no exterior - {{ data.item.accountName }}
                    </p>
                    <p class="item-text">Banco - {{ data.item.bank }}</p>
                    <p class="item-text">IBAN - {{ data.item.numberIBAN }}</p>
                    <p class="item-text">Código Swift/BIC - {{ data.item.swiftCodeBIC }}</p>
                    <p class="item-text">Número ABA/Routing - {{ data.item.tabNumberRouting }}</p>
                    <p
                      class="item-text"
                      v-if="data.item.forFurtherCredit"
                    >
                      FFC - {{ data.item.forFurtherCredit }}
                    </p>
                    <p class="item-text pb-2">Nome do titular - {{ data.item.ownerName }}</p>

                    <template v-if="data.item.intermediary">
                      <p class="item-title pb-0">Banco intermediário</p>
                      <p class="item-text">País - {{ data.item.intermediaryCountry }}</p>
                      <p class="item-text">Banco - {{ data.item.intermediaryBankName }}</p>
                      <p class="item-text">
                        ABA/Routing - {{ data.item.intermediaryAbaSwiftCode }}
                      </p>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <template
                v-else
                #item="data"
              >
                <v-row class="pa-2">
                  <v-col
                    cols="12"
                    col="6"
                    class="px-0"
                  >
                    <p class="item-title">Conta bancária do Brasil</p>
                    <p class="item-text">Banco - {{ data.item.bankName }}</p>
                    <p class="item-text">Agência - {{ data.item.agency }}</p>
                    <p class="item-text">Conta - {{ data.item.account }}</p>
                    <p class="item-text">Digito - {{ data.item.digit }}</p>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-corban"
              label="Corban"
              :items="agentList"
              v-model="operation.agent"
              item-value="id"
              item-text="name"
              dense
              clearable
              outlined
              v-disabled-icon-focus
              return-object
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            v-if="operation.nature.useDiDue"
            cols="12"
            md="2"
            class="py-0"
          >
            <v-text-field
              data-test="input-di-due"
              label="Protocolo de DI/DUE"
              v-model="operation.diDue"
              v-mask="'##.###.###-#'"
              dense
              outlined
            />
          </v-col>
          <v-col
            v-if="operation.nature.bilateralAgreementCountries.length"
            class="py-0"
            cols="12"
            md="3"
          >
            <CountryField
              label="País"
              v-model="operation.country"
              :rules="[validationIsRequiredAutocompleteText]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-textarea
              data-test="textarea-observacoes"
              auto-grow
              outlined
              rows="2"
              v-model="operation.observation"
              label="Observações"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import MoneyField from '@/components/comum/MoneyField';
import CountryField from '@/components/comum/CountryField.vue';
import InfoPopover from '@/components/comum/InfoPopover';
import CurrencySelect from './CurrencySelect.vue';

// Api
import CustomerApi from '@/api/customer/customer-api';
import CorbanApi from '@/api/correspondent/correspondent-api';
import CurrencyApi from '@/api/spread/currency-api';

// Cache
import bankCache from '../../../../common/cache/bank-cache';

// Models
import OperationModel from '@/model/operation-model';
import CustomerModel from '@/model/customer-model';
import CustomerExchangeBankModel from '@/model/customer-exchange-bank-model';
import CorrespondentModel from '@/model/correspondent-model';
import CurrencyModel from '@/model/currency-model';
import OperationCustomerAccountModel from '@/model/operation-customer-account-model';
import CustomerFiltersModel from '@/model/customer/customer-filters-model';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';
import mixinAuthorization from '@/mixin/mixin-authorization';
import mixinMessage from '@/mixin/mixin-message';
import mixinAutoCompleteFilters from '@/mixin/mixin-autocomplete-filters';
import mixinGeral from '@/mixin/mixin-geral';

// Constants
import { LIQUIDATION, OPERATION_TYPE } from '@/constants/general-constants';
import { CUSTOMER_WORKFLOW } from '../../../../common/workflows/customersWorkflow';

// Utils
import numberUtils from '../../../../common/utils/number';
import dateUtils from '../../../../common/utils/date';
import operationUtils from '@/utils/operation';
import operationMessagesTemplate from '../../utils/operationMessagesTemplate';
import moment from 'moment';

export default {
  name: 'OperationRemittance',
  mixins: [
    mixinValidationRules,
    mixinAuthorization,
    mixinMessage,
    mixinAutoCompleteFilters,
    mixinGeral
  ],
  components: {
    MoneyField,
    CountryField,
    InfoPopover,
    CurrencySelect
  },
  inject: ['appData'],
  data() {
    return {
      operation: this.operationProp,
      customerList: [],
      exchangeBankList: [],
      deliveryTypes: [],
      deliveryTimes: [],
      agentList: [],
      methodList: [],
      currencies: [],
      liquidationList: LIQUIDATION,
      customer: new CustomerModel(),
      account: {},
      listOfBanks: [],
      validationOperationHasLimit: true,
      customerSearchTimer: undefined,
      customerSearchQuery: '',
      customerSearchLoading: false,
      corbanApi: new CorbanApi(this.appData.currentUser),
      customerApi: new CustomerApi(this.appData.currentUser),
      currencyApi: new CurrencyApi(this.appData.currentUser)
    };
  },
  model: {
    prop: 'operationProp',
    event: 'onChange'
  },
  props: {
    operationProp: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    operation() {
      this.emitOperation();
    },
    account() {
      this.emitOperation();
    },
    natureList() {
      if (this.natureList.length == 1) {
        this.operation.nature = this.natureList[0];
      }
    },
    bankList() {
      if (this.bankList.length == 1) this.operation.bank = this.bankList[0];
    },
    accountList() {
      if (this.accountList.length == 1) this.account = this.accountList[0];
    },
    exchangeBankList() {
      this.mountOperationUpdate();
      this.mountOperationFromQueryString();
    },
    'operation.customer'() {
      if (this.operation.customer == undefined) {
        this.operation = new OperationModel({
          exchangeType: this.operation.exchangeType,
          exchangeBuy: this.operation.exchangeBuy
        });
        this.customer = new CustomerModel();

        return;
      }

      /* This prevents the constant api request of the same customer */
      if (!this.operation.customer?.id || this.operation.customer.id == this.customer.id) {
        return;
      }

      this.getExchangeBanks();
      this.getAgent();
      this.getCustomer();
    },
    'operation.reducedLimit'() {
      this.validationOperationHasLimit = operationUtils.validationOperationHasLimit(
        this.operation,
        this.exchangeBankList
      );
    },
    customerSearchQuery() {
      clearTimeout(this.customerSearchTimer);

      if (this.customerSearchQuery == this.itemTextCustomers(this.customer)) {
        return;
      }

      if ((this.customerSearchQuery?.length || 0) < 3) {
        this.customerList = [];

        return;
      }

      this.customerSearchTimer = setTimeout(() => {
        this.customerSearchLoading = true;
        this.getCustomersList().then(() => {
          this.customerSearchLoading = false;
        });
      }, 500);
    }
  },
  async mounted() {
    this.getagentList();
    this.getCurrencies();

    if (this.operation.customer.id) {
      this.getExchangeBanks();
      this.getCustomer();
    }

    this.listOfBanks = await bankCache.getBankList();
  },
  computed: {
    profit() {
      if (!this.operationProp.totalValue || !this.operation.spread) return 0;

      return this.operationProp.totalValue * (this.operation.spread / 100);
    },

    itemTextCustomers() {
      return (item) =>
        !item.name ? item.companyName + ' - ' + item.cpfCnpj : item.name + ' - ' + item.cpfCnpj;
    },
    bankList() {
      return (
        this.exchangeBankList
          .map((i) => i.bank)
          .filter(
            (b) =>
              b.exchangeType.id == this.operation.exchangeType.id ||
              b.exchangeType.id == OPERATION_TYPE.BOTH.id
          )
          .sort((a, b) => {
            const isAInactive = !!this.bankIsDisable(a);
            const isBInactive = !!this.bankIsDisable(b);

            return isAInactive - isBInactive;
          }) || []
      );
    },
    itemTextBanks() {
      return (item) => {
        let limit = this.exchangeBankList
          .filter((i) => i.bank.id == item.id)
          .map((j) => j.limit)
          .pop();

        let remainingLimit = 0;

        if (limit?.isInOut) {
          remainingLimit =
            (this.operation.exchangeBuy ? limit?.remainingIn : limit?.remainingOut) || 0;
        } else {
          remainingLimit = limit?.remaining || 0;
        }

        let formatedRemaining = item.useUsdLimit
          ? this.formatUSD(remainingLimit)
          : this.formatBRL(remainingLimit);
        let formatedExpire = dateUtils.maskDateIso(limit?.expireAt);

        return item.fantasyName + ' - ' + formatedRemaining + ' - ' + formatedExpire;
      };
    },
    itemTextStores() {
      return (item) => item.name + ' - ' + item.cnpj;
    },
    natureList() {
      return (
        this.exchangeBankList
          .filter((i) => i.bank.id == this.operation.bank.id)
          .map((j) => j.operationNatures)
          .flat() || []
      );
    },
    itemTextCurrencies() {
      return (item) => item.code + ' - ' + item.name;
    },
    itemTextAccount() {
      return (item) => {
        if (this.operation.exchangeBuy) {
          return (
            'Banco: ' +
            item.bankName +
            ' - Agência: ' +
            item.agency +
            ' - Conta: ' +
            item.account +
            ' - Dígito: ' +
            item.digit
          );
        } else {
          return (
            item.accountName +
            ' - Banco: ' +
            item.bank +
            ' - IBAN: ' +
            item.numberIBAN +
            ' - Código Swift/BIC: ' +
            item.swiftCodeBIC +
            ' - Número ABA/Routing: ' +
            item.tabNumberRouting
          );
        }
      };
    },
    spreadHint() {
      if (this.operation.spreadMin && this.operation.spreadMax) {
        return `Min: ${this.operation.spreadMin}% - Max: ${this.operation.spreadMax}%`;
      }

      return '';
    },
    customerRateHint() {
      if (this.operation.customerRateMin && this.operation.customerRateMax) {
        return `Min: ${this.formatBRL(
          this.operation.customerRateMin,
          this.operation.currency.precision
        )} - Max: ${this.formatBRL(
          this.operation.customerRateMax,
          this.operation.currency.precision
        )}`;
      }

      return '';
    },
    accountList() {
      let accountList = [];

      if (this.operation.exchangeBuy) {
        accountList = this.operation.customer.nationalBanks;

        accountList.map((i) => {
          let bank = this.listOfBanks.find((b) => b.code == i.bank);
          i.bankName = bank?.name;

          return i;
        });
      } else {
        accountList = this.operation.customer.internationalBanks;
        accountList = accountList.filter((a) => a.bank && a.numberIBAN && a.swiftCodeBIC);
      }

      return accountList;
    },
    bankLimitHint() {
      if (!this.operation?.bank) return '';

      return `Limite: ${this.getLimitBank(this.operation.bank)}`;
    },
    customerHasPaymentAccountForBank() {
      return (
        !this.operation.paymentAccount.paymentAccount ||
        !!(this.operation.paymentAccount.paymentAccount && this.operation.paymentAccount.bank) ||
        'Este cliente não possui conta de pagamento cadastrada para este banco'
      );
    }
  },
  methods: {
    getCustomersList() {
      let filters = new CustomerFiltersModel({
        registerStatusList: [CUSTOMER_WORKFLOW.CADASTRO_APROVADO.id],
        searchText: this.customerSearchQuery
      });

      return this.customerApi
        .findAll(filters)
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCustomer() {
      this.customerApi
        .findId(this.operation.customer.id)
        .then((response) => {
          this.operation.customer = new CustomerModel(response.data);
          this.customer = new CustomerModel(response.data);

          if (this.$route.query.idCustomer || this.$route.params.id) {
            this.customerList = [this.customer];

            this.$nextTick(() => {
              this.$refs.customerAutocomplete.isMenuActive = false;
            });
          }

          this.getAgent();
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getExchangeBanks() {
      this.customerApi
        .findExchangeBanks(this.operation.customer.id, this.operation.exchangeType.id)
        .then((response) => {
          this.exchangeBankList = response.data
            .map((c) => new CustomerExchangeBankModel(c))
            .filter((i) => i.limit.id);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getagentList() {
      this.corbanApi
        .findAll()
        .then((response) => {
          this.agentList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      this.currencyApi
        .findAll()
        .then((response) => {
          this.currencies = response.data.map((c) => new CurrencyModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getAgent() {
      let id = this.operation.customer.correspondent.id;

      if (!this.operation.customer.id || !id) {
        this.sendMessage('Esse cliente não possui um corban!', 'error');

        return;
      }

      this.corbanApi
        .findId(id)
        .then((response) => {
          this.operation.agent = new CorrespondentModel(response.data);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    formatBRL(value, precision) {
      return numberUtils.formatCurrency('BRL', value, precision);
    },
    formatUSD(value, precision) {
      return numberUtils.formatCurrency('USD', value, precision);
    },

    emitOperation() {
      let account = {};

      if (this.operation.exchangeBuy) {
        account = new OperationCustomerAccountModel(this.account);
      } else {
        account = new OperationCustomerAccountModel({
          internationalAccount: true,
          ...this.account
        });
      }

      this.operation.account = account;
      this.$emit('onChange', this.operation);
    },

    validationBankHasLimit() {
      return operationUtils.validationOperationLimitNotExpired(
        this.operation,
        this.exchangeBankList
      );
    },

    mountOperationFromQueryString() {
      this.mountBankFromQuery();
      this.mountNatureFromQuery();
      this.mountCurrencyFromQuery();
    },

    mountBankFromQuery() {
      if (this.$route.query.idBank) {
        let bank = this.bankList.find((b) => b.id == this.$route.query.idBank);

        if (bank) {
          this.operation.bank = bank;
        }

        this.$route.query.idBank = undefined;
      }
    },

    mountNatureFromQuery() {
      if (this.$route.query.idNature) {
        let nature = this.natureList.find((n) => n.id == this.$route.query.idNature);

        if (nature) {
          this.operation.nature = nature;
        }

        this.$route.query.idNature = undefined;
      }
    },

    mountCurrencyFromQuery() {
      if (this.$route.query.idCurrency) {
        let currency = this.currencies.find((c) => c.id == this.$route.query.idCurrency);

        if (currency) {
          this.operation.currency = currency;
        }

        this.$route.query.idCurrency = undefined;
      }
    },

    mountOperationUpdate() {
      this.mountBankUpdate();
      this.mountCurrencyUpdate();
      this.mountAccountUpdate();
    },

    mountBankUpdate() {
      if (this.operation.bank?.id) {
        let bank = this.bankList.find((b) => b.id == this.operation.bank.id);

        if (bank) {
          this.operation.bank = bank;
        }
      }
    },

    mountCurrencyUpdate() {
      if (this.operation.currency?.id) {
        let currency = this.currencies.find((c) => c.id == this.operation.currency.id);

        if (currency) {
          this.operation.currency = currency;
        }
      }
    },

    mountAccountUpdate() {
      if (this.operation.account?.id) {
        let operationAccount = this.operation.account;
        let account = this.accountList.find(
          (a) =>
            a.account == operationAccount.account &&
            a.agency == operationAccount.agency &&
            a.bank == operationAccount.bank
        );

        if (account) {
          this.account = account;
        }
      }
    },
    copyToClipboard() {
      let template = operationMessagesTemplate.getBankInformation(this.operation);
      navigator.clipboard.writeText(template);
    },

    getLimitBank(item, formated = true) {
      let limit = this.exchangeBankList
        .filter((i) => i.bank.id == item.id)
        .map((j) => j.limit)
        .pop();
      let remainingLimit = 0;

      remainingLimit = limit?.isInOut
        ? (this.operation.exchangeBuy ? limit?.remainingIn : limit?.remainingOut) || 0
        : limit?.remaining || 0;

      return formated
        ? item.useUsdLimit
          ? this.formatUSD(remainingLimit)
          : this.formatBRL(remainingLimit)
        : remainingLimit;
    },

    getExpireBank(item, formated = true) {
      let limit = this.exchangeBankList
        .filter((i) => i.bank.id == item.id)
        .map((j) => j.limit)
        .pop();

      return formated ? dateUtils.maskDateIso(limit?.expireAt) : limit?.expireAt;
    },

    bankIsDisable(item) {
      let diffDays = moment(this.getExpireBank(item, false)).diff(moment(), 'days');

      let description = undefined;
      let noLimit = this.getLimitBank(item, false) <= 0;
      let expired = diffDays <= 0;

      if (noLimit || expired)
        description = `(Inativo - ${noLimit ? 'Sem limite ' : ''} ${
          noLimit && expired ? 'e Expirado' : 'Expirado'
        })`;

      return description;
    },

    customFilterAccount(item, queryText) {
      const text = this.getTextAccount(item).toLowerCase();
      const query = queryText.toLowerCase();

      return text.indexOf(query) > -1;
    },

    getTextAccount(item) {
      return (
        item.bank +
        ' ' +
        item.swiftCodeBIC +
        ' ' +
        item.numberIBAN +
        ' ' +
        item.ownerName +
        ' ' +
        item.tabNumberRouting +
        ' ' +
        item.accountName +
        ' ' +
        item.forFurtherCredit + 
        ' ' +
        item.intermediaryCountry +
        ' ' +
        item.intermediaryBankName +
        ' ' +
        item.intermediaryAbaSwiftCode
      );
    }
  }
};
</script>

<style scoped>
.item-text {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0 0 0 15px;
  margin: 0;
}

.item-title {
  color: var(--v-primary-base);
  font-size: 0.9rem;
  font-weight: 600;
}

.isBuy {
  border: 3px solid var(--v-colorBuy-base) !important;
}

.isSell {
  border: 3px solid var(--v-colorSell-base) !important;
}

.bank-title {
  font-weight: 400;
}

.theme--light .subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}

.theme--dark .subtitle-dark {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px !important;
}
</style>
