<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-card class="box card-cambio">
        <v-row no-gutters>
          <v-col>
            <h1>Países com acordo bilateral</h1>
            <v-divider />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-row
              no-gutters
              class="py-2"
            >
              <v-col
                cols="12"
                md="9"
                align-self="center"
              >
                <v-btn
                  class="btn-primary ml-0"
                  text
                  @click="openAddAgreementModal"
                >
                  <i class="far fa-plus" />
                  Adicionar
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="3"
                sm="4"
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  single-line
                  hide-details
                  class="mt-0"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <v-card class="card-cambio">
                  <v-data-table
                    :headers="columns"
                    :items="nature.bilateralAgreementCountries"
                    :search="search"
                    sort-by="id"
                    sort-desc
                    :items-per-page="paginationDefault"
                    height="250px"
                    fixed-header
                  >
                    <template #[`item.country`]="{ item }">
                      {{ item.country | formatCountry }}
                    </template>
                    <template #[`item.ir`]="{ item }">
                      {{ item.ir | formatNumber }}
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <div class="buttons-column">
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              text
                              color="primary"
                              @click="editAgreementModal(item)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <i class="far fa-pencil" />
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <BaseModal
      v-model="addAgreementModal"
      title="Acordo bilateral"
      modal-width="100vh"
      :primary-button-action="update ? updateAgreement : addAgreement"
      :secondary-button-action="() => addAgreementModal = false"
      :fullscreen="appData.isMobile"
    >
      <v-form
        id="add-agreement"
        ref="form"
      >
        <v-row>
          <v-col
            class="py-0"
            cols="12"
          >
            <CountryField
              label="País"
              v-model="agreement.country"
            />
          </v-col>

          <v-col
            class="py-0"
            cols="12"
          >
            <NumberField
              v-model="agreement.ir"
              outlined
              dense
              label="IR venda(%)"
              suffix="%"
              :precisao-decimal="6"
            />
          </v-col>
        </v-row>
      </v-form>  
    </BaseModal>
  </v-row>
</template>

<script>
// Api
import apiBilateralAgreement from "@/api/configuration/bilateral-agreement-country-api";

// Components
import NumberField from "@/components/comum/NumberField";
import CountryField from "@/components/comum/CountryField.vue";
import BaseModal from "@/components/comum/BaseModal";

// Models
import BilateralAgreementCountryModel from "@/model/bilateral-agreement-country-model";

// Utils
import countryUtils from "@/utils/country";
import numberUtils from "../../../../../common/utils/number";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import { PAGINATION_DEFAULT } from '../../../constants/general-constants';

export default {
name: "BilateralAgreementCountries",
  mixins: [mixinMessage],
  components: {
    NumberField,
    CountryField,
    BaseModal
  },
  model: {
    prop: "natureProp",
    event: "onChange",
  },
  props: {
    natureProp: {
      type: Object,
      required: true,
    },
  },
  inject: ["appData"],
  data() {
    return {
      nature: this.natureProp,
      search: "",
      columns: [
        { text: "País", value: "country", align: "left", sortable: true },
        { text: "IR venda(%)", value: "ir", align: "right", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      agreement: new BilateralAgreementCountryModel(),
      addAgreementModal: false,
      update: false,
      key: 0,
      paginationDefault: PAGINATION_DEFAULT
    };
  },
  filters: {
    formatCountry(value) {
      return countryUtils.formatCountry(value);
    },
    formatNumber(value) {
      const precision = 6;

      return numberUtils.formatNumber(value, precision);
    },
  },
  methods: {
    openAddAgreementModal() {
      this.update = false;
      this.agreement = new BilateralAgreementCountryModel();
      this.addAgreementModal = true;
      this.key = this.key + 1;
    },

    editAgreementModal(agreement) {
      this.update = true;
      this.agreement = Object.assign({}, agreement);
      this.addAgreementModal = true;
      this.key = this.key + 1;
    },

    addAgreement() {
      if (!this.nature.id) {
        this.nature.bilateralAgreementCountries.push(new BilateralAgreementCountryModel(this.agreement));
        this.addAgreementModal = false;

        return;
      }

      apiBilateralAgreement
        .add(this.agreement, this.nature.id)
        .then((response) => {
          this.nature.bilateralAgreementCountries.push(new BilateralAgreementCountryModel(response.data));
          this.addAgreementModal = false;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, "error");
        });
    },

    updateAgreement() {
      let natureId = parseInt(this.nature.id);
      apiBilateralAgreement
        .update(this.agreement, natureId)
        .then((response) => {
          let index = this.nature.bilateralAgreementCountries.findIndex((a) => a.id == this.agreement.id);
          this.nature.bilateralAgreementCountries.splice(
            index,
            1,
            new BilateralAgreementCountryModel(response.data)
          );
          this.addAgreementModal = false;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, "error");
        });
    },
  }
};
</script>

<style scoped>
.buttons-column{
  float: right;
}

::v-deep h1 {
  min-height: 30px;
  line-height: 30px;
}
</style>
