import BaseApi from '../baseApi';

export default class TriggerApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  trigger(trigger) {
    trigger = trigger || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/triggers', trigger)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  forgotPassword(trigger) {
    trigger = trigger || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/forgot-password', trigger)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
