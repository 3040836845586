const CUSTOMER_EXCHANGE_BANK_WORKFLOW = {
  ENVIADO_AO_BANCO: {
    id: 1,
    description: 'Enviado ao banco',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_BANCO, CUSTOMER_EXCHANGE_BANK_WORKFLOW.PLD_COMPLIANCE];
    },
  },
  PENDECIA_BANCO: {
    id: 2,
    description: 'Pendência junto ao banco',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.PLD_COMPLIANCE];
    },
  },
  PLD_COMPLIANCE: {
    id: 3,
    description: 'PLD/Compliance',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_BANCO, CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_APROVADO, CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_ENCERRADO, CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_RECUSADO];
    },
  },
  CADASTRO_APROVADO: {
    id: 4,
    description: 'Cadastro aprovado',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.ATUALIZACAO_CADASTRAL, CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO];
    },
  },
  CADASTRO_ENCERRADO: {
    id: 5,
    description: 'Cadastro encerrado',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.ATUALIZACAO_CADASTRAL];
    },
  },
  CADASTRO_RECUSADO: {
    id: 6,
    description: 'Cadastro recusado',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO];
    },
  },
  ATUALIZACAO_CADASTRAL: {
    id: 7,
    description: 'Atualização cadastral',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANDAMENTO];
    },
  },
  CADASTRO_EM_ANDAMENTO: {
    id: 8,
    description: 'Cadastro em andamento',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_FINALIZADO];
    },
  },
  CADASTRO_FINALIZADO: {
    id: 9,
    description: 'Cadastro finalizado',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANALISE, CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO];
    },
  },
  CADASTRO_EM_ANALISE: {
    id: 10,
    description: 'Cadastro em análise',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_DOCUMENTAL, CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO];
    },
  },
  PENDECIA_DOCUMENTAL: {
    id: 11,
    description: 'Pendência documental',
    next: () => {
      return [CUSTOMER_EXCHANGE_BANK_WORKFLOW.CADASTRO_EM_ANDAMENTO, CUSTOMER_EXCHANGE_BANK_WORKFLOW.ENVIADO_AO_BANCO];
    },
  },
}

const CUSTOMER_EXCHANGE_BANK_WORKFLOW_LIST = Object.values(CUSTOMER_EXCHANGE_BANK_WORKFLOW);

export  {
  CUSTOMER_EXCHANGE_BANK_WORKFLOW,
  CUSTOMER_EXCHANGE_BANK_WORKFLOW_LIST,
}