/**
 * Formats a number to the brazilian currency format
 * @param {String} currency The currency code
 * @param {Number} value The value
 * @returns {String} The formated value
 */
function formatCurrency(currency, value, precision) {
  if (!value) {
    value = 0;
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: precision,
  });

  return formatter.format(value);
}
function formatNumber(value, precision) {
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: precision
  }).format(value);
}

function truncateDecimalValue(value, precision) {
  let currentPrecision = value.toString().split('.')[1]?.length || 0;

  if (currentPrecision <= precision) {
    return value;
  }

  const correctionFactor = Math.pow(10, precision);

  return Math.trunc(value * correctionFactor) / correctionFactor;
}

export default {
  formatCurrency,
  formatNumber,
  truncateDecimalValue
};
