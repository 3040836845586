import getApi from '../api';

function findAll(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/audits/', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findById(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/audits/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findByEntity(entityId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/audits/entity/${entityId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findById,
  findByEntity
};
