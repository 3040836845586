<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          width="400px"
          class="py-4"
        />
        <v-divider />
        <v-skeleton-loader
          type="button"
          min-width="100px"
          class="py-5"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        class="d-flex justify-space-between"
        cols="12"
        md="12"
      >
        <h1 class="titulo py-3">Interbancário</h1>
        <div class="d-flex flex-column mx-7 mt-3">
          <span
            class="title-date-acces pb-0"
            cols="12"
          >{{ dateAccessTitle }}
          </span>
          <span
            cols="12"
            class="subtitle-customer-cpfcnpj py-0"
          >{{ dateUpdateTitle }}
          </span>
        </div>
      </v-col>

      <v-col cols="12">
        <v-card
          class="card-cambio d-flex"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Comprado no dia</v-list-item-subtitle>

              <v-list-item-title class="report-interbank-title mb-1"> -- </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Vendido no dia</v-list-item-subtitle>

              <v-list-item-title class="report-interbank-title mb-1"> -- </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle>Saldo</v-list-item-subtitle>

              <v-list-item-title class="report-interbank-title report-red mb-1"
                >USD -3,000</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-tabs v-model="tab">
              <v-tab key="operacoes">
                <span>Operações realizadas</span>
              </v-tab>
              <v-tab key="movimentacoes">
                <span>Movimentações</span>
              </v-tab>
            </v-tabs>
          </v-col>

          <v-tabs-items v-model="tab">
            <!-- Operações realizadas -->
            <v-tab-item
              eager
              key="operacoes"
            >
              <v-col
                class="d-flex align-center justify-space-between px-3"
                cols="12"
              >
                <div class="d-flex flex-column mt-3">
                  <span
                    class="total-selected-title pb-0"
                    cols="12"
                    >Total selecionado
                  </span>
                  <span
                    cols="12"
                    class="result-total-selected-title py-0"
                    >USD 0,000.000
                  </span>
                </div>

                <div>
                  <v-btn
                    class="btn-primary ml-2"
                    text
                    @click="dialog = true"
                  >
                    Registrar compra
                  </v-btn>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-card class="card-cambio">
                  <v-data-table
                    :headers="columns"
                    :items="interbanks"
                    :search="search"
                    show-select
                    sort-by="id"
                    sort-desc
                    :items-per-page="paginationDefault"
                    item-key="id"
                  >
                    <template #[`item.name`]="{ item }">
                      <span>{{ item.name }}</span>
                    </template>
                    <template #[`item.actions`]>
                      <v-chip
                        label
                        color="#ff5a64"
                        text-color="white"
                        small
                      >
                        Venda
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-tab-item>

            <!-- Movimentações -->
            <v-tab-item
              eager
              key="movimentacoes"
            >
              <v-col
                cols="12"
                md="12"
              >
                <v-card class="card-cambio">
                  <v-data-table
                    :headers="columns"
                    :items="interbanks"
                    :search="search"
                    show-select
                    sort-by="id"
                    sort-desc
                    :items-per-page="paginationDefault"
                    item-key="id"
                  >
                    <template #[`item.name`]="{ item }">
                      <span>{{ item.name }}</span>
                    </template>
                    <template #[`item.actions`]>
                      <v-chip
                        label
                        color="#8871ff"
                        text-color="white"
                        small
                      >
                        Operação
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal registrar compra -->
    <v-dialog
      persistent
      scrollable
      min-height="800px"
      max-width="400px"
      v-model="dialog"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Registrar compra</h1>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <form autocomplete="off">
            <v-row class="mt-2">
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  label="Valor comprado"
                  autocomplete="off"
                  dense
                />
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  :items="[]"
                  label="SPOT"
                  deletable-chips
                  autocomplete="off"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  label="Banco"
                  dense
                />
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="dialog = false"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinAuthorization from '../../mixin/mixin-authorization';

// Tracking
import mixpanel from 'mixpanel-browser';

import moment from 'moment';

//TODO: Usado para simular a chamada da API. Deverá ser removido assim que o endpoint de interbancário for criado.
import accessProfileApi from '../../api/access/access-profile-api';
import AccessProfileModel from '@/model/access/access-profile-model';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: 'InterbanksControl',
  mixins: [mixinMessage, mixinAuthorization],
  data() {
    return {
      tab: null,
      dialog: false,
      loading: true,
      search: '',
      interbanks: [],
      dateUpdate: new Date(),

      columns: [
        { text: 'Código da operação', value: 'id', align: 'left', sortable: true },
        { text: 'Quantidade ME', value: 'id', align: 'left', sortable: true },
        { text: 'Moeda', value: 'observation', align: 'left', sortable: true },
        { text: 'Spot', value: 'observation', align: 'left', sortable: true },
        { text: 'Tipo de operação', value: 'actions', align: 'center', sortable: false }
      ],
      paginationDefault: PAGINATION_DEFAULT
    };
  },
  mounted() {
    this.getInterbanksList();

    mixpanel.track('page_view', {
      name_of_page_viewed: 'interbanks_control',
      user_id: this.user.id
    });
  },

  computed: {
    dateAccessTitle() {
      return moment().locale('pt-br').format('[Dia] DD [de] MMMM [de] YYYY');
    },

    dateUpdateTitle() {
      return moment(this.dateUpdate).format('[Atualizado às] HH:mm');
    }
  },

  methods: {
    //TODO: Simulando chamada da API. Não deverá ser chamada a requisição de acessos aqui.
    getInterbanksList() {
      return accessProfileApi
        .findAll()
        .then(({ data }) => {
          this.interbanks = data.map((p) => new AccessProfileModel(p));
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }

          this.interbanks = [];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.title-date-acces {
  font-weight: 500;
  font-size: 1.1rem;
}

.report-interbank-title {
  font-weight: 500;
  font-size: 1.1rem;
}

.report-interbank-title.report-red {
  color: red;
}

.subtitle-customer-cpfcnpj,
.total-selected-title {
  font-weight: 500;
  font-size: 0.9rem;
}

.result-total-selected-title {
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--v-primary-base);
}
</style>
