import getApi from '../api';

function add(spread, storeId) {
  spread = spread || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post(`/exchange-stores/${storeId}/spreads`, spread)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(spread, storeId) {
  spread = spread || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/exchange-stores/${storeId}/spreads/${spread.id}`, spread)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findByStore(storeId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/exchange-stores/${storeId}/spreads`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(spreadId, storeId) {
  spreadId = spreadId || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/exchange-stores/${storeId}/spreads/${spreadId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findByStore,
  findId,
  add,
  update
};
