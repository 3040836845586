<template>
  <div
    class="mx-2 mt-4 ma-md-5"
    data-test="customer-form-container"
  >
    <!-- Skeleton Loader -->
    <v-row
      v-if="loading"
      data-test="skeleton-loader"
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          min-width="100px"
          class="my-5"
        />
        <v-divider />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!externalAcess"
      class="pb-2"
      data-test="form-type-selection"
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-card class="box card-cambio">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h1 data-test="formulario-titulo">Tipo de formulário</h1>
            </v-col>
            <template v-if="customer.id && !isInAnalysis">
              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  text
                  class="btn-secondary float-right mx-2"
                  @click="startOperationExchange"
                  data-test="btn-operar-cambio"
                >
                  <i class="far fa-money-bill-wave" />
                  Operar câmbio turismo
                </v-btn>
                <v-btn
                  text
                  class="btn-secondary float-right mx-2"
                  @click="startOperationRemmitance"
                  data-test="btn-operar-remessa"
                >
                  <i class="far fa-hand-holding-usd" />
                  Operar remessa internacional
                </v-btn>
              </v-col>
            </template>
          </v-row>
          <v-divider />
          <v-spacer />
          <v-radio-group
            v-model="customer.registerType.id"
            row
            data-test="tipo-formulario"
            hide-details
            class="pt-md-3"
          >
            <v-radio
              v-for="(item, i) in listOfRegisterTypes"
              :key="i"
              :value="item.id"
              :label="item.description"
              data-test="radio-tipo-registro"
            />
          </v-radio-group>
        </v-card>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      v-model="valid"
      v-if="customer.registerType.id"
      data-test="formulario-principal"
    >
      <div
        v-if="externalAcess"
        data-test="acesso-externo"
      >
        <p
          style="font-style: italic; text-align: center"
          data-test="informacao-salvamento"
        >
          Suas informações serão salvas temporariamente neste dispositivo até que você termine seu
          cadastro.
        </p>
        <LogoType data-test="logo-type" />
        <CustomerCover
          :is-p-f="isPF"
          :is-exchange="isExchange"
          :is-basic-remitance="isBasicRemitance"
          :is-complete-remittance="isCompleteRemittance"
          v-model="customer"
        />
        <CustomerContact v-model="customer" />
        <CustomerAddress
          :is-p-f="isPF"
          :is-exchange="isExchange"
          v-model="customer"
        />
        <CustomerWork
          v-if="isCompleteRemittance"
          v-model="customer"
        />
        <CustomerPartnersShareholders v-model="customer" />
        <CustomerDirectorsProxies v-model="customer" />
        <CustomerFinancial
          v-model="customer"
          :is-exchange="isExchange"
          :is-legal-person="isLegalPerson"
          :is-remitance="isRemitance"
          :is-complete-remittance="isCompleteRemittance"
        />
        <CustomerPoliticallyExposedPerson v-model="customer" />
        <CustomerUSPerson v-model="customer" />
        <PFCustomerTaxDomicileAbroad v-model="customer" />
        <CustomerInternationalAccount
          v-if="isRemitance || isLegalPerson"
          v-model="customer"
        />
        <CustomerCompliance
          v-if="isLegalPerson"
          v-model="customer"
        />
        <CustomerRegistrationResponsible v-model="customer" />
        <CustomerDocuments
          :is-legal-person="isLegalPerson"
          :is-exchange="isExchange"
          :is-basic-remitance="isBasicRemitance"
          :is-complete-remittance="isCompleteRemittance"
          v-model="customer"
        />
        <CustomerOperationalDocuments v-model="customer" />
        <CustomerUserAgreement
          v-model="agreeToTerms"
          :is-exchange="isExchange"
        />
      </div>
      <div v-else>
        <CustomerFormTab
          :is-legal-person="isLegalPerson"
          :is-p-f="isPF"
          :is-exchange="isExchange"
          :is-remittance="isRemitance"
          :is-basic-remittance="isBasicRemitance"
          :is-complete-remittance="isCompleteRemittance"
          v-model="customer"
          :invalid-tabs="invalidTabs"
        />
      </div>
    </v-form>
    <v-row
      data-test="botoes-acao"
      class="pt-2"
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          v-if="!externalAcess && !isInAnalysis"
          class="btn-tertiary float-right"
          text
          @click.prevent="this.$router.go(-1)"
          data-test="btn-cancelar"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="btn-primary mx-2 float-right"
          @click="customer.id ? update(true) : save()"
          text
          data-test="btn-salvar"
        >
          Salvar
        </v-btn>
        <template
          v-if="
            editMode &&
              isAdministrativeAccess &&
              customer.registerStatus.id != CUSTOMER_WORKFLOW.CADASTRO_ENVIADO.id &&
              customer.registerStatus.id != CUSTOMER_WORKFLOW.CADASTRO_EM_ANDAMENTO.id
          "
        >
          <v-btn
            class="btn-secondary mx-2 float-right"
            text
            @click="customerComplentaryInfoModal = true"
            data-test="btn-solicitar-atualizacao"
          >
            <i class="far fa-envelope" />
            Solicitar atualização cadastral
          </v-btn>
          <v-btn
            class="btn-secondary mx-2 float-right"
            text
            @click="requestRegisterFormAddAccount()"
            data-test="btn-solicitar-cadastro-conta"
          >
            <i class="far fa-envelope" />
            Solicitar cadastro de conta no exterior
          </v-btn>
        </template>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      scrollable
      eager
      min-width="20vw"
      max-width="80vh"
      v-model="customerComplentaryInfoModal"
      data-test="dialog-informacoes-complementares"
    >
      <v-card data-test="card-informacoes-complementares">
        <v-card-title data-test="titulo-informacoes-complementares">
          <h1 class="titulo-modal">Informação complementar</h1>
        </v-card-title>
        <v-divider />
        <v-card-text
          class="pt-8"
          data-test="texto-informacoes-complementares"
        >
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              md="12"
            >
              <v-textarea
                auto-grow
                outlined
                rows="1"
                v-model="customerComplentaryInfo"
                label="Informações complementares"
                data-test="textarea-informacoes-complementares"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions data-test="acoes-informacoes-complementares">
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="requestRegisterFormUpdate()"
              data-test="btn-salvar-informacoes-complementares"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="customerComplentaryInfoModal = false"
              data-test="btn-cancelar-informacoes-complementares"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Components
import CustomerCover from '@/components/form-customer/CustomerCover';
import CustomerContact from '@/components/form-customer/CustomerContact';
import CustomerAddress from '@/components/form-customer/CustomerAddress';
import CustomerPartnersShareholders from '@/components/form-customer/CustomerPartnersShareholders.vue';
import CustomerDirectorsProxies from '@/components/form-customer/CustomerDirectorsProxies.vue';
import CustomerFinancial from '@/components/form-customer/CustomerFinancial.vue';
import CustomerWork from '@/components/form-customer/CustomerWork.vue';
import CustomerPoliticallyExposedPerson from '@/components/form-customer/CustomerPoliticallyExposedPerson.vue';
import CustomerCompliance from '@/components/form-customer/CustomerCompliance.vue';
import CustomerRegistrationResponsible from '../../components/form-customer/CustomerRegistrationResponsible.vue';
import CustomerInternationalAccount from '../../components/form-customer/CustomerInternationalAccount.vue';
import CustomerDocuments from '../../components/form-customer/CustomerDocuments.vue';
import CustomerUserAgreement from '../../components/form-customer/CustomerUserAgreement.vue';
import CustomerOperationalDocuments from '../../components/form-customer/CustomerOperationalDocuments.vue';
import CustomerFormTab from '@/components/form-customer/CustomerFormTab';
import LogoType from '@/components/comum/LogoType.vue';
import CustomerUSPerson from '@/components/form-customer/PF/usPerson/PFCustomerUSPerson.vue';
import PFCustomerTaxDomicileAbroad from '@/components/form-customer/PF/taxDomicileAbroad/PFCustomerTaxDomicileAbroad.vue';

// Constants
import { REGISTER_TYPE, OPERATION_TYPE } from '@/constants/general-constants.js';
import { SEND_EMAIL_TRIGGER_CODES } from '../../../../common/constants/generic/triggers';
import { CUSTOMER_WORKFLOW } from '../../../../common/workflows/customersWorkflow';
import { CUSTOMER_EXCHANGE_BANK_WORKFLOW } from '../../../../common/workflows/customerExchangeBankWorkflow';

// Apis
import CustomerApi from '@/api/customer/customer-api';
import CustomerDocumentsApi from '@/api/customer/customer-documents-api';
import CustomerOperationalDocumentsApi from '@/api/customer/customer-operational-documents-api';
import TriggerApi from '@/api/generic/trigger-api';
import CustomerExchangeBanksApi from '@/api/customer/customer-exchange-banks-api';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinGeral from '@/mixin/mixin-geral';
import mixinNestedFormValidation from '@/mixin/mixin-nested-form-validation';
import mixinAuthorization from '@/mixin/mixin-authorization';

// Models
import CustomerModel from '@/model/customer-model';
import RegisterTypeModel from '@/model/register-type-model';
import TriggerModel from '@/model/generic/trigger-model';

// Utils
import storage from '@/utils/storage.js';
import utils from '@/utils/general.js';

// Tracking
import mixpanel from 'mixpanel-browser';

export default {
  name: 'FormCustomer',
  mixins: [mixinMessage, mixinGeral, mixinNestedFormValidation, mixinAuthorization],
  props: {
    isInAnalysis: { type: Boolean, default: false }
  },
  components: {
    CustomerCover,
    CustomerContact,
    CustomerAddress,
    CustomerPartnersShareholders,
    CustomerDirectorsProxies,
    CustomerFinancial,
    CustomerWork,
    CustomerPoliticallyExposedPerson,
    CustomerCompliance,
    CustomerRegistrationResponsible,
    CustomerInternationalAccount,
    CustomerDocuments,
    CustomerUserAgreement,
    CustomerOperationalDocuments,
    CustomerFormTab,
    LogoType,
    CustomerUSPerson,
    PFCustomerTaxDomicileAbroad
  },
  inject: ['appData'],
  data() {
    return {
      loading: false,
      editMode: false,
      externalAcess: false,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      customer: new CustomerModel(),
      registerType: new RegisterTypeModel(),
      agreeToTerms: false,
      valid: false,
      timer: null,
      customerComplentaryInfo: '',
      customerComplentaryInfoModal: false,
      customerApi: new CustomerApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
      apiCustomersDocuments: new CustomerDocumentsApi(this.appData.currentUser),
      apiCustomerOperationalDocuments: new CustomerOperationalDocumentsApi(
        this.appData.currentUser
      ),
      customerExchangeBanksApi: new CustomerExchangeBanksApi(this.appData.currentUser),
      CUSTOMER_WORKFLOW,
      invalidTabs: [],
      fieldsChecked: false
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.loading = true;
      this.getCustomerById(this.$route.params.id);
      this.editMode = true;
    } else if (this.$route.params.token) {
      storage.saveTokenOnStorage(this.$route.params.token);

      this.editMode = true;
      this.externalAcess = true;

      let customer = utils.parseJwt(this.$route.params.token);
      let storageCustomer = storage.getCustomerOnStorage();

      if (customer.id == storageCustomer?.id) {
        this.customer = new CustomerModel(storageCustomer);
        this.loading = false;
      } else {
        this.getCustomerById(customer.id);
      }

      this.updateStatusToRegisterInProgress(customer);
    } else {
      this.editMode = false;
    }

    this.$eventBus.$on('customer-auto-save', this.autoSave);
    this.$eventBus.$on('create-customer-pre-registration', this.createCustomerPreRegistration);
  },

  beforeDestroy() {
    this.$eventBus.$off('customer-auto-save', this.autoSave);
    this.$eventBus.$off('create-customer-pre-registration', this.createCustomerPreRegistration);
  },

  watch: {
    customer: {
      handler() {
        this.customer.setPersonType();

        if (this.fieldsChecked) {
          this.reassignFormInputs(this.$refs.form);
          this.$refs.form.validate();
          this.fillInvalidFields();
        }

        if (!this.externalAcess) {
          return;
        }

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          storage.saveCustomerOnStorage(this.customer);
        }, 5000);
      },
      deep: true
    }
  },
  computed: {
    isLegalPerson() {
      return this.customer && this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? true
        : false;
    },
    isCompleteRemittance() {
      return this.customer && this.customer.registerType.id == REGISTER_TYPE.COMPLETE_REMITTANCE.id
        ? true
        : false;
    },
    isBasicRemitance() {
      return this.customer && this.customer.registerType.id == REGISTER_TYPE.BASIC_REMITTANCE.id
        ? true
        : false;
    },
    isRemitance() {
      return (this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.BASIC_REMITTANCE.id) ||
        this.customer.registerType.id == REGISTER_TYPE.COMPLETE_REMITTANCE.id
        ? true
        : false;
    },
    isExchange() {
      return this.customer && this.customer.registerType.id == REGISTER_TYPE.EXCHANGE.id
        ? true
        : false;
    },
    isPF() {
      return this.customer && this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? false
        : true;
    }
  },
  methods: {
    getCustomerById(id) {
      this.customerApi
        .findId(id)
        .then((response) => {
          this.customer = new CustomerModel(response.data);
          this.loading = false;
          this.handleRegisterTypeRestrictions();
          this.redirectToFormCustomerFinallyCaseExtenalRegistrationCompleted();
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
          this.loading = false;
        });
    },
    save() {
      mixpanel.track('click', {
        button_name: 'save_customer'
      });

      if (!this.isFormValid()) {
        return;
      }

      this.createCustomer();
    },
    update(buttonSaveClicked) {
      mixpanel.track('click', {
        button_name: 'update_customer'
      });

      if (!this.isInAnalysis && !this.isFormValid()) {
        return;
      }

      this.updateCustomer(false, buttonSaveClicked);
    },
    isFormValid() {
      this.fieldsChecked = true;
      this.reassignFormInputs(this.$refs.form);
      this.$refs.form.validate();

      this.fillInvalidFields();

      if (!this.valid) {
        let invalidElement = this.$refs.form.inputs.find((i) => !i.valid);
        invalidElement.$el.scrollIntoView({ behavior: 'smooth', block: 'end' });

        if (this.isAdministrativeAccess)
          this.sendMessage('Existem campos obrigatórios não preenchidos', 'error');

          return false;
      }

      return true;
    },

    fillInvalidFields() {
      if (!this.fieldsChecked) return;

      this.invalidTabs = [];

      this.$refs.form?.inputs.filter(i => !i.valid).forEach((input) => {
        const key = input.$attrs?.['data-tab-key'];

        if (key === undefined) return;

        const index = this.invalidTabs.findIndex((i) => i.key === key);

        (index === -1)
          ? this.invalidTabs.push({ key, count: 1 })
          : this.invalidTabs[index].count++;
      });
    },

    async createCustomer(autoSave) {
      let customerDocuments = this.customer.documents;
      let operationalDocuments = this.customer.operationalDocuments;
      let prepared = this.prepareCustomerForSaving();

      if (autoSave) {
        prepared.registerStatus = CUSTOMER_WORKFLOW.CADASTRO_EM_ANALISE;
        let preRegistration;

        try {
          preRegistration = await this.customerApi.add(prepared);
          this.customer.id = preRegistration.data.id;
          this.$eventBus.$emit('customer-pre-registered');
        } catch (error) {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        }

        return;
      }

      prepared.registerStatus = CUSTOMER_WORKFLOW.CADASTRO_FINALIZADO;

      this.$eventBus.$emit('show-loading', true);
      this.customerApi
        .add(prepared)
        .then((response) => {
          this.sendMessage('Cliente salvo com sucesso!', 'success');

          this.saveDocuments(customerDocuments, response.data);
          this.saveOperationalDocuments(operationalDocuments, response.data);

          storage.removeCustomer();

          this.$router.push({
            name: 'CustomersAuthorizationControl'
          });
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },
    updateCustomer(autoSave, buttonSaveClicked) {
      let prepared = this.prepareCustomerForSaving();

      if (!autoSave) {
        this.$eventBus.$emit('show-loading', true);
      }

      this.customerApi
        .update(prepared)
        .then(() => {
          clearTimeout(this.timer);
          storage.removeCustomer();

          if (this.externalAcess) {
            this.updateStatusToRegisterComplete();
          } else {
            if (
              buttonSaveClicked &&
              ['FormCreateCustomer', 'FormAuthorizationCustomer'].includes(this.$route.name)
            ) {
              this.$router.push({
                name: 'CustomersAuthorizationControl'
              });
            }

            if (buttonSaveClicked && this.$route.name === 'FormEditCustomer')
              this.$router.push({
                name: 'CustomersControl'
              });
          }
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },
    saveDocuments(customerDocuments, customer) {
      customerDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append('customerId', customer.id);
        formData.append('idDocumentType', document.documentType.id);
        formData.append('document', document.document);

        this.apiCustomersDocuments.add(formData);
      });
    },
    saveOperationalDocuments(operationalDocuments, customer) {
      operationalDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append('customerId', customer.id);
        formData.append('idOperationalDocumentType', document.operationalDocumentType.id);
        formData.append('document', document.document);

        this.apiCustomerOperationalDocuments.add(formData);
      });
    },
    prepareCustomerForSaving() {
      let customerClone = structuredClone(this.customer);

      customerClone.documents = undefined;
      customerClone.operationalDocuments = undefined;
      customerClone.compliance.suppliers = customerClone.compliance.suppliersProxy
        .map((i) => i.name)
        .join(';');
      customerClone.compliance.products = customerClone.compliance.productsProxy
        .map((i) => i.name)
        .join(';');
      customerClone.compliance.typeExportedProducts =
        customerClone.compliance.typeExportedProductsProxy.map((i) => i.name).join(';');
      customerClone.compliance.dis = customerClone.compliance.disProxy.map((i) => i.name).join(';');
      customerClone.paymentAccounts = customerClone.paymentAccounts.filter(
        (i) => i.bank || i.agency || i.account || i.bankName
      );

      return customerClone;
    },

    startOperationRemmitance() {
      this.$router.push({
        name: 'FormOperation',
        query: {
          idExchangeType: OPERATION_TYPE.REMMITANCE.id,
          idCustomer: this.customer.id
        }
      });
    },

    startOperationExchange() {
      this.$router.push({
        name: 'FormOperation',
        query: {
          idExchangeType: OPERATION_TYPE.EXCHANGE.id,
          idCustomer: this.customer.id
        }
      });
    },

    requestRegisterFormUpdate() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_ATUALIZACAO_FICHA_CADASTRAL,
        idCustomer: this.customer.id,
        customerComplementaryInfo: this.customerComplentaryInfo
      });
      this.updateStatusFormUpdateRequest();

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage('Solicitação de atualização cadastral enviada com sucesso!', 'success');
          this.customerComplentaryInfo = '';
          this.customerComplentaryInfoModal = false;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    requestRegisterFormAddAccount() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_CADASTRO_CONTA_EXTERIOR,
        idCustomer: this.customer.id
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage(
            'Email de solicitação de cadastro de conta enviado com sucesso!',
            'success'
          );
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    updateStatusToRegisterInProgress(customer) {
      this.updateStatusCustomer(customer, CUSTOMER_WORKFLOW.CADASTRO_EM_ANDAMENTO);
    },
    updateStatusToRegisterComplete() {
      this.sendRegisterCompleteEmailTrigger();
      this.updateStatusCustomer(this.customer, CUSTOMER_WORKFLOW.CADASTRO_FINALIZADO, true);
    },

    updateStatusCustomer(customer, workflow, redirectToCustomerCreateSuccess) {
      this.customerApi
        .updateStatus(customer.id, workflow)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          if (redirectToCustomerCreateSuccess) {
            storage.removeAuth();
            this.$router.push({
              name: 'FormCustomerFinally'
            });
          }
        });
    },

    updateStatusFormUpdateRequest() {
      this.customerExchangeBanksApi
        .updateStatus(
          this.customer.id,
          this.$route.query.bankId,
          CUSTOMER_EXCHANGE_BANK_WORKFLOW.ATUALIZACAO_CADASTRAL
        )
        .then(() => {})
        .catch(() => {});
    },

    autoSave() {
      if (this.customer.id) {
        this.updateCustomer(true);
      }
    },

    createCustomerPreRegistration() {
      this.createCustomer(true);
    },

    sendRegisterCompleteEmailTrigger() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_CADASTRO_FINALIZADO,
        idCustomer: this.customer.id
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {})
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    handleRegisterTypeRestrictions() {
      if (this.isLegalPerson || this.isCompleteRemittance) {
        this.listOfRegisterTypes = this.listOfRegisterTypes.filter(
          (type) => type.id === this.customer.registerType.id
        );

        return;
      }

      if (this.isBasicRemitance) {
        this.listOfRegisterTypes = this.listOfRegisterTypes.filter(
          (type) =>
            type.id === REGISTER_TYPE.BASIC_REMITTANCE.id ||
            type.id === REGISTER_TYPE.COMPLETE_REMITTANCE.id
        );

        return;
      }

      if (this.isExchange) {
        this.listOfRegisterTypes = this.listOfRegisterTypes.filter(
          (type) =>
            type.id === REGISTER_TYPE.EXCHANGE.id ||
            type.id === REGISTER_TYPE.BASIC_REMITTANCE.id ||
            type.id === REGISTER_TYPE.COMPLETE_REMITTANCE.id
        );

        return;
      }
    },

    redirectToFormCustomerFinallyCaseExtenalRegistrationCompleted() {
      if (!this.externalAcess) return;

      const STATUS_FOR_EDIT = [
        CUSTOMER_WORKFLOW.CADASTRO_ENVIADO.id,
        CUSTOMER_WORKFLOW.CADASTRO_EM_ANDAMENTO.id
      ];

      if (!STATUS_FOR_EDIT.includes(this.customer.registerStatus.id))
        this.$router.push({
          name: 'FormCustomerFinally'
        });
    }
  }
};
</script>
