<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-show="loading">
      <v-col
        cols="12"
        lg="6"
      >
        <v-skeleton-loader
          class="mx-4"
          type="heading"
          min-width="100px"
        />
      </v-col>
      <v-col
        cols="12"
        lg="12"
      >
        <v-divider class="mx-4" />
        <v-skeleton-loader
          type="article"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-form
      ref="form"
      v-model="registrationValid"
      validation
    >
      <v-row v-show="!loading">
        <v-col
          cols="12"
          lg="12"
        >
          <h1 class="titulo">{{ !modeEdication ? 'Cadastro' : 'Edição' }} de templates</h1>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <v-text-field
            clearable
            v-model="template.description"
            autofocus
            label="Descrição"
            autocomplete="off"
            dense
            outlined
            :rules="requiredField"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          v-if="template.type?.id == TEMPLATE_TYPES.EMAIL.id"
        >
          <v-text-field
            clearable
            v-model="template.subject"
            autofocus
            label="Assunto"
            autocomplete="off"
            dense
            outlined
            :rules="requiredField"
            append-outer-icon="mdi-alpha-i-circle-outline"
            @click:append-outer="openModalDetailsVariable"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <v-autocomplete
            clearable
            v-model="template.type"
            :items="templateTypes"
            label="Tipo"
            item-text="description"
            autocomplete="off"
            :rules="requiredField"
            dense
            return-object
            outlined
          />
        </v-col>
        <template v-if="template.type?.id == TEMPLATE_TYPES.FICHA_CLIENTE.id">
          <v-col
            cols="12"
            lg="3"
          >
            <v-autocomplete
              clearable
              v-model="template.format"
              item-text="description"
              item-value="value"
              label="Formato"
              autocomplete="off"
              dense
              outlined
              :rules="requiredField"
              :items="PAGE_FORMAT_LIST"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <v-autocomplete
              clearable
              v-model="template.landscape"
              item-text="description"
              item-value="value"
              label="Orientação"
              autocomplete="off"
              dense
              outlined
              :rules="[validationIsRequiredRadioBtn]"
              :items="PAGE_ORIENTATION_LIST"
            />
          </v-col>
        </template>
        <v-col
          cols="12"
          lg="12"
        >
          <v-card class="card-cambio">
            <EditorTiny
              v-model="template.content"
              :key="componentKey"
              :altura-do-editor="800"
            />
          </v-card>
        </v-col>
        <v-row class="pa-4">
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              class="btn-primary mx-2"
              text
              :disabled="!registrationValid"
              @click="!modeEdication ? addTemplate() : updateTemplate()"
            >
              Salvar
            </v-btn>

            <v-btn
              class="btn-tertiary"
              text
              @click="cancel()"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <confirmation-window
        v-model="openConfirmationWindow"
        :callback-primary="callbackPrimary"
        :message="`Há dados preenchidos, deseja realmente abandonar ${
          modeEdication ? 'a edição?' : 'o cadastro?'
        }`"
        :text-primary-button="'Sim, abandonar'"
        :text-secondary-button="'Não'"
      />
    </v-form>

    <v-dialog
      v-model="modalVisible"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Variáveis para assunto</span>
        </v-card-title>
        <v-card-text>
          Temos algumas variáveis disponíveis para serem utilizadas no campo assunto
        </v-card-text>
        <div class="alignment-variables">
          <ul>
            <li>
              <code>${nome_completo}</code><span> Nome completo ou razão social do cliente</span>
            </li>
            <li>
              <code>${primeiro_nome}</code><span> Primeiro nome do cliente pessoa fisica</span>
            </li>
            <li><code>${nome_fantasia}</code><span> Nome fantasia do cliente</span></li>
            <li><code>${cpf_cnpj}</code><span> Cpf ou Cnpj do cliente</span></li>
            <li><code>${natureza}</code><span> Nome da natureza na operação</span></li>
            <li><code>${codigo_natureza}</code><span> Código da natureza na operação</span></li>
            <li><code>${codigo_operacao}</code><span> Código da operação</span></li>
          </ul>
          <br>
          <p>Alguns exemplos de utilização:</p>
          <br>
          <ol>
            <li>
              Olá <code>${nome}</code> sua operação <code>${codigo_operacao}</code> foi aprovada
            </li>
            <li>
              Operação de compra gerada para o <code>${nome}</code> - <code>${cpf_cnpj}</code>
            </li>
            <li>
              Aprovação para o cliente <code>${nome}</code> na natureza <code>${natureza}</code>
            </li>
          </ol>
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-primary mr-2"
            @click="closeModalDetailsVariable"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// API
import apiTemplates from '@/api/generic/template-api';
import apiTemplateTypes from '@/api/generic/template-types-api';

// Models
import TemplateModel from '@/model/generic/template-model';
import TemplateTypeModel from '@/model/generic/template-type-model';

//Components
import EditorTiny from '@/components/comum/EditorTinymce';
import ConfirmationWindow from '@/components/comum/ConfirmationWindow';

// Constants
import { TEMPLATE_TYPES } from '@/constants/general-constants';
import { PAGE_FORMAT_LIST, PAGE_ORIENTATION_LIST } from '../../../../../common/constants/global';

// Mixins
import mixpanel from 'mixpanel-browser';
import mixinMessage from '@/mixin/mixin-message';
import mixinValidationRules from '@/mixin/mixin-validation-rules';

export default {
  name: 'FormTemplate',
  components: {
    EditorTiny,
    ConfirmationWindow
  },
  mixins: [mixinMessage, mixinValidationRules],
  data() {
    return {
      componentKey: 0,
      registrationValid: false,
      requiredField: [(v) => !!v || 'Informação obrigatória'],
      loading: true,
      templates: [],
      template: new TemplateModel(),
      modeEdication: false,
      templateTypes: [],
      openConfirmationWindow: false,
      TEMPLATE_TYPES,
      PAGE_FORMAT_LIST,
      PAGE_ORIENTATION_LIST,
      modalVisible: false
    };
  },

  mounted() {
    this.getTemplateTypes();
    mixpanel.track('page_view', { nome_pagina: 'templates' });
  },

  methods: {
    openModalDetailsVariable() {
      this.modalVisible = true;
    },
    closeModalDetailsVariable() {
      this.modalVisible = false;
    },
    callbackPrimary() {
      mixpanel.track('click', {
        nome_pagina: 'cadastrar_templates',
        nome_botao: 'btn_abandonar_cadastro_templates'
      });

      this.$router
        .push({
          name: 'TemplatesControl'
        })
        .catch(() => {});
    },
    forceRerender() {
      this.componentKey += 1;
      this.checkMode();
    },
    checkMode() {
      let id = this.$route.params.id;

      if (id) {
        this.modeEdication = true;
        apiTemplates
          .findById(id)
          .then((resposta) => {
            this.template = new TemplateModel(resposta.data);
          })
          .catch(() => {
            this.sendMessage('Não foi possível obter a template para edição.', 'error');
          });
      } else {
        this.modeEdication = false;
        this.template = new TemplateModel();
      }
    },

    updateTemplate() {
      mixpanel.track('click', {
        nome_pagina: 'editar_templates',
        nome_botao: 'btn_atualizar_template'
      });

      if (!this.template.type.id) {
        return;
      }

      this.template.typeId = this.template.type.id;

      apiTemplates
        .update(this.template)
        .then(() => {
          this.sendMessage('Template atualizada com sucesso', 'success');

          this.$router
            .push({
              name: 'TemplatesControl'
            })
            .catch(() => {});
        })
        .catch(() => {
          this.sendMessage('Falha ao atualizar o template', 'error');
        });
    },

    cancel() {
      mixpanel.track('click', {
        nome_pagina: 'cadastrar_templates',
        nome_botao: 'btn_cancelar_templates'
      });

      if (this.template.description || this.template.content || this.template.typeId) {
        this.openConfirmationWindow = true;
      } else {
        history.back();
      }
    },

    addTemplate() {
      mixpanel.track('click', {
        nome_pagina: 'cadastrar_templates',
        nome_botao: 'btn_cadastrar_templates'
      });

      if (!this.template.type.id) {
        return;
      }

      this.template.typeId = this.template.type.id;

      apiTemplates
        .add(this.template)
        .then(() => {
          this.sendMessage('Template cadastrada com sucesso.', 'success');

          this.clearTemplate();

          this.$router
            .push({
              name: 'TemplatesControl'
            })
            .catch(() => {});
        })
        .catch((error) => {
          this.sendMessage(error.data, 'error');
        });
    },

    clearTemplate() {
      this.template = new TemplateModel();
    },

    getTemplateTypes() {
      apiTemplateTypes
        .findAll()
        .then((resposta) => {
          this.templateTypes = resposta.data.map((t) => new TemplateTypeModel(t));
          this.forceRerender();
          this.loading = false;
        })
        .catch(() => {
          /* Não fazer nada, é responsabilidade do sistema */
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.alignment-variables {
  margin-left: 15px;
}
</style>
