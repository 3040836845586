import BaseApi from '../baseApi';

export default class AccessApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  findAll() {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/access')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findUserAccess(user) {
    user = user || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/users/${user.id}/access`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
