import getApi from '../api';

function add(template) {
  template = template || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/templates', template)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(template) {
  template = template || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/templates/${template.id}`, template)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findAll() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/templates')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findById(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/templates/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function inactivate(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .delete(`/templates/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findById,
  add,
  update,
  inactivate
};
