<template>
  <v-row>
    <v-col
      class="py-0 d-flex flex-column"
      cols="12"
      md="auto"
    >
      <v-radio-group
        data-test="rd-nacionalidade"
        row
        class="py-0"
        v-model="customer.isBrazilian"
        :mandatory="true"
        :rules="[validationIsRequiredRadioBtn]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        hide-details
      >
        <v-radio
          data-test="rd-brasileiro"
          :value="true"
          :label="'Brasileiro'"
        />
        <v-radio
          data-test="rd-estrangeiro"
          :value="false"
          :label="'Estrangeiro'"
        />
      </v-radio-group>
      <small class="required">Informação obrigatória</small>
    </v-col>
    <v-col
      v-if="isBrazilian"
      class="py-0"
      cols="12"
      md="auto"
    >
      <CpfOrCnpjField
        data-test="txt-cpf"
        v-model="customer.cpfCnpj"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      :lg="isBrazilian ? 3 : 4"
    >
      <v-text-field
        data-test="txt-nome-completo"
        outlined
        dense
        label="Nome completo"
        v-model="customer.name"
        counter="200"
        maxlength="200"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <Datepicker
        data-test="dt-data-nascimento"
        v-model="customer.birthDate"
        label="Data de nascimento"
        v-disabled-icon-focus
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <v-radio-group
        data-test="rd-sexo"
        :rules="[validationIsRequiredRadioBtn]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        row
        class="py-0"
        v-model="customer.sex"
      >
        <v-radio
          data-test="rd-masculino"
          value="M"
          :label="'Masculino'"
        />
        <v-radio
          data-test="rd-feminino"
          value="F"
          :label="'Feminino'"
        />
      </v-radio-group>
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <v-autocomplete
        data-test="slc-tipo-documento-cliente"
        label="Tipo de documento"
        :items="
          isBrazilian ? listOfIdentificationDocumentTypes : [listOfIdentificationDocumentTypes[4]]
        "
        item-text="description"
        item-value="id"
        v-model="customer.identificationDocumentType"
        return-object
        clearable
        dense
        outlined
        v-disabled-icon-focus
        hint="Este campo é obrigatório"
        :rules="[validationIsRequiredAutocompleteId]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <v-text-field
        data-test="txt-numero-documento"
        outlined
        dense
        inputmode="decimal"
        label="Nº do documento"
        v-model="customer.documentNumber"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <Datepicker
        data-test="dt-data-emissao"
        v-model="customer.documentIssueDate"
        label="Data de emissão"
        v-disabled-icon-focus
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        @onChange="changeExpirationDate"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <Datepicker
        data-test="dt-data-validade"
        v-model="customer.documentDueDate"
        label="Data de validade"
        v-disabled-icon-focus
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        :hint="hint"
      />
    </v-col>
    <v-col
      v-if="isBrazilian"
      class="py-0"
      cols="12"
      md="auto"
    >
      <UFField
        data-test="slc-uf"
        v-model="customer.state"
        label="UF"
        :rules="[validationIsRequiredAutocompleteText]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        :max-width="appData.isMobile ? '' : '120px'"
      />
    </v-col>
    <v-col
      v-if="isBrazilian"
      class="py-0"
      cols="12"
      md="auto"
    >
      <v-text-field
        data-test="txt-orgao-expedidor"
        outlined
        dense
        label="Órgão expedidor"
        v-model="customer.issuingAgency"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <v-autocomplete
        data-test="slc-estado-civil"
        label="Estado civil"
        :items="listOfMaritalStatus"
        item-text="description"
        item-value="id"
        v-model="customer.additional.maritalStatus"
        return-object
        clearable
        dense
        outlined
        hint="Este campo é obrigatório"
        v-disabled-icon-focus
        :rules="[validationIsRequiredAutocompleteId]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        autocomplete="null"
      />
    </v-col>
    <template v-if="isMarried && isBrazilian">
      <v-col
        class="py-0"
        cols="12"
        md="auto"
      >
        <v-autocomplete
          data-test="slc-regime-casamento"
          label="Regime de Casamento"
          :items="listOfMarriageSystem"
          item-text="description"
          item-value="id"
          v-model="customer.additional.marriageSystem"
          return-object
          clearable
          dense
          outlined
          hint="Este campo é obrigatório"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteId]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="4"
      >
        <v-text-field
          data-test="txt-nome-conjuge"
          outlined
          dense
          label="Nome completo do Cônjuge"
          v-model="customer.additional.spouseName"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="auto"
      >
        <CpfOrCnpjField
          data-test="txt-cpf-conjuge"
          v-model="customer.additional.spouseCPF"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
    </template>

    <v-col
      class="py-0"
      cols="12"
      md="auto"
      v-if="!isBrazilian"
    >
      <CountryField
        data-test="slc-pais-nascimento"
        label="País de nascimento"
        v-model="customer.birthCountry"
        :rules="[validationIsRequiredAutocompleteText]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>

    <v-col
      class="py-0"
      cols="12"
      md="auto"
    >
      <MoneyField
        data-test="txt-renda-media-mensal"
        label="Renda média mensal"
        prefix="R$ "
        v-model="customer.additional.averageMonthlyIncome"
        :rules="[validationIsRequiredFieldNumeric]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>

    <v-col
      v-if="!isCompleteRemittance"
      class="py-0"
      cols="12"
      md="auto"
    >
      <v-text-field
        data-test="txt-profissao"
        v-model="customer.occupation.profession"
        outlined
        dense
        label="Profissão"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>

    <template v-if="isBasicRemittance || isCompleteRemittance">
      <v-col
        class="py-0"
        cols="12"
        md="auto"
      >
        <v-autocomplete
          data-test="slc-motivo-envio"
          label="Motivo do envio"
          :items="operationNatureList"
          v-model="customer.additional.operationNature"
          item-text="name"
          item-value="id"
          return-object
          clearable
          dense
          outlined
          hint="Este campo é obrigatório"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteId]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <template v-if="customer.additional.operationNature.id == 5">
        <v-col
          class="py-0"
          cols="12"
          md="auto"
        >
          <div class="min-width-dependent-name">
            <v-text-field
              data-test="txt-nome-dependente"
              v-model="customer.additional.dependentName"
              outlined
              dense
              label="Nome Completo do Dependente"
              :rules="[validationIsRequiredField]"
              :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
            />
          </div>
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="auto"
        >
          <div class="min-width-dependent-kinship">
            <v-text-field
              data-test="txt-grau-parentesco-dependente"
              v-model="customer.additional.dependentKinship"
              outlined
              dense
              label="Grau de Parentesco do Dependente"
              :rules="[validationIsRequiredField]"
              :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
            />
          </div>
        </v-col>
      </template>
    </template>
    <v-col
      class="pt-0 py-md-0"
      cols="12"
      md="auto"
    >
      <div class="min-width-dependent-kinship">
        <v-autocomplete
          data-test="slc-corban"
          label="Corban"
          :items="correspondentsList"
          v-model="customer.correspondent"
          item-value="id"
          item-text="name"
          return-object
          dense
          clearable
          outlined
          v-disabled-icon-focus
          v-if="isAdministrativeAccess"
          hide-details
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CpfOrCnpjField from '@/components/comum/CpfOrCnpjField.vue';
import Datepicker from '@/components/comum/Datepicker.vue';
import UFField from '@/components/comum/UFField.vue';
import CountryField from '@/components/comum/CountryField';
import MoneyField from '@/components/comum/MoneyField';

// Api
import OperationNatureApi from '@/api/configuration/operation-nature-api';
import CorbanApi from '@/api/correspondent/correspondent-api';

// Models
import OperationNatureModel from '@/model/operation-nature-model';
import CorrespondentModel from '@/model/correspondent-model';

// Constants
import {
  IDENTIFICATION_DOCUMENT_TYPE,
  LIST_IDENTIFICATION_DOCUMENT_TYPE,
  MARITAL_STATUS,
  MARRIAGE_SYSTEM
} from '@/constants/general-constants.js';
import { CUSTOMER_FORM_TAB } from "@/constants/customer-form-constants";

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';
import mixinAuthorization from '@/mixin/mixin-authorization';
import mixinMessage from '@/mixin/mixin-message';

export default {
  name: 'PFExchangeBasicCompleteRemittanceFields',
  mixins: [mixinValidationRules, mixinAuthorization, mixinMessage],
  components: { CpfOrCnpjField, Datepicker, UFField, CountryField, MoneyField },
  inject: ['appData'],
  data() {
    return {
      hint: '',
      customer: this.customerProp,
      listOfIdentificationDocumentTypes: LIST_IDENTIFICATION_DOCUMENT_TYPE,
      listOfMaritalStatus: Object.values(MARITAL_STATUS),
      listOfMarriageSystem: Object.values(MARRIAGE_SYSTEM),
      operationNatureList: [],
      correspondentsList: [],
      corbanApi: new CorbanApi(this.appData.currentUser),
      operationNatureApi: new OperationNatureApi(this.appData.currentUser),
      customerFormTab: CUSTOMER_FORM_TAB
    };
  },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      required: true
    },
    isBasicRemittance: {
      type: Boolean
    },
    isCompleteRemittance: {
      type: Boolean
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  },
  computed: {
    isMarried() {
      return this.customer.additional.maritalStatus &&
        this.customer.additional.maritalStatus.id == MARITAL_STATUS.MARRIED.id
        ? true
        : false;
    },
    isBrazilian() {
      return !!this.customer.isBrazilian;
    }
  },
  mounted() {
    this.getOperationNatureList();
    this.getCorrespondentsList();
  },
  methods: {
    changeExpirationDate() {
      this.hint = '';

      let conditional = !!(
        IDENTIFICATION_DOCUMENT_TYPE.RG.id == this.customer.identificationDocumentType.id ||
        IDENTIFICATION_DOCUMENT_TYPE.CNH.id == this.customer.identificationDocumentType.id
      );

      if (conditional) {
        const expiryYear = 10;
        let expirationDate = new Date(this.customer.documentIssueDate);
        let newExpiryYear = expirationDate.getFullYear() + expiryYear;

        this.customer.documentDueDate = new Date(
          expirationDate.setFullYear(newExpiryYear)
        ).toISOString();

        if (new Date(this.customer.documentDueDate) < new Date()) {
          this.hint = 'Este documento está vencido.';
        }
      }
    },
    getOperationNatureList() {
      this.operationNatureApi
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCorrespondentsList() {
      this.corbanApi
        .findAll()
        .then((response) => {
          this.correspondentsList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    }
  }
};
</script>

<style scoped>
.min-width-dependent-name {
  min-width: 280px;
}

.min-width-dependent-kinship {
  min-width: 310px;
}
</style>
