<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Dados bancários no Brasil</h1>
        <v-divider class="py-3" />
        <CustomerBank v-model="customer" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CustomerBank from "./shared/CustomerBank.vue";

export default {
  name: "CustomerFinancial",
  components: {
    CustomerBank,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
      default: () => {}
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
};
</script>

<style>
</style>