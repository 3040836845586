import Login from '@/views/Login.vue';
import Dashboard from '@/views/dashboard/Dashboard';
import UsersControl from '@/views/users/UsersControl';
import CustomersControl from '@/views/customers/CustomersControl';
import FormCustomer from '@/views/customers/FormCustomer';
import CustomersAuthorizationControl from '@/views/customers/CustomersAuthorizationControl';
import FormAuthorizationCustomer from '@/views/customers/FormAuthorizationCustomer';
import FormCorrespondent from '@/views/correspondents/FormCorrespondent';
import CorrespondentsControl from '@/views/correspondents/CorrespondentsControl';
import PreSaleReport from '@/views/reports/PreSaleReport';
import ExchangeOperationReport from '@/views/reports/ExchangeOperationReport';
import RemittanceOperationReport from '@/views/reports/RemittanceOperationReport';
import RemittanceOperationManagementReport from '@/views/reports/RemittanceOperationManagementReport';
import FormOperation from '@/views/operations/FormOperation';
import CommissionsControl from '@/views/operations/CommissionsControl';
import ExchangeBankControl from '@/views/registrations/exchangeBanks/ExchangeBankControl';
import ExchangeFormBank from '@/views/registrations/exchangeBanks/ExchangeFormBank';
import OperationNaturesControl from '@/views/registrations/operations/OperationNaturesControl';
import CurrenciesControl from '@/views/registrations/operations/CurrenciesControl';
import PaymentMethodsControl from '@/views/registrations/operations/PaymentMethodsControl';
import RateApiRouteControl from '@/views/registrations/integrations/RateApiRouteControl';
import TemplatesControl from '@/views/registrations/templates/TemplatesControl';
import FormTemplate from '@/views/registrations/templates/FormTemplate';
import Configuration from '@/views/configurations/generic/configuration/Configuration';
import PageNotFound from '@/views/generic/PageNotFound';
import PageNotPermission from '@/views/generic/PageNotPermission';
import FileView from '@/views/generic/FileView';
import FormCustomerFinally from '@/views/customers/FormCustomerFinally';
import CustomerDocumentsExternalAccess from '@/views/customers/CustomerDocumentsExternalAccess';
import OperationDocumentsExternalAccess from '@/views/operations/OperationDocumentsExternalAccess';
import AuditControl from '@/views/audit/AuditControl';
import EmailLogsControl from '@/views/logger/EmailLogsControl';
import ProfilesControl from '@/views/access/ProfilesControl';
import FormProfile from '@/views/access/FormProfile';
import InterbanksControl from '@/views/interbank/InterbanksControl';

const routes = [
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
    title: "Página não encontrada",
    meta: { requireAuth: false }
  },
  {
    path: "/403",
    name: "PageNotPermission",
    component: PageNotPermission,
    title: "Página não encontrada",
    meta: { requireAuth: false }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: "Login",
    meta: { requireAuth: false }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    title: "Dashboard",
    meta: { requireAuth: true }
  },
  {
    path: "/users",
    name: "UsersControl",
    component: UsersControl,
    title: "Controle de Usuários",
    meta: { requireAuth: true }
  },
  {
    path: "/customers",
    name: "CustomersControl",
    component: CustomersControl,
    title: "Controle de Clientes",
    meta: { requireAuth: true }
  },
  {
    path: "/customers/confirmation",
    name: "FormCustomerFinally",
    component: FormCustomerFinally,
    title: "Confirmação de cadastro",
    meta: { requireAuth: false },
  },
  {
    path: "/customers/create",
    name: "FormCreateCustomer",
    component: FormCustomer,
    title: "Criação e Edição de Clientes",
    meta: { requireAuth: true }
  },
  {
    path: "/customers/update/:id",
    name: "FormEditCustomer",
    component: FormCustomer,
    title: "Criação e Edição de Clientes",
    meta: { requireAuth: true },
  },
  {
    path: "/customers/external-acess/:token",
    name: "FormEditCustomerExternalAcess",
    component: FormCustomer,
    title: "Criação e Edição de Clientes",
    meta: { requireAuth: false },
  },
  {
    path: "/customers-authorizations",
    name: "CustomersAuthorizationControl",
    component: CustomersAuthorizationControl,
    title: "Autorização de Clientes",
    meta: { requireAuth: true },
  },
  {
    path: "/customers-authorizations/:id",
    name: "FormAuthorizationCustomer",
    component: FormAuthorizationCustomer,
    title: "Autorização de Clientes",
    meta: { requireAuth: true },
  },
  {
    path: "/correspondents",
    name: "CorrespondentsControl",
    component: CorrespondentsControl,
    title: "Controle de Corban",
    meta: { requireAuth: true }
  },
  {
    path: "/correspondents/create",
    name: "FormCreateCorrespondent",
    component: FormCorrespondent,
    title: "Criação e Edição de Corban",
    meta: { requireAuth: true }
  },
  {
    path: "/correspondents/update/:id",
    name: "FormEditCorrespondent",
    component: FormCorrespondent,
    title: "Criação e Edição de Corban",
    meta: { requireAuth: true },
  },
  {
    path: "/correspondents/external-acess/:token",
    name: "FormEditCorrespondentExternalAcess",
    component: FormCorrespondent,
    title: "Criação e Edição de Corban",
    meta: { requireAuth: false },
  },
  {
    path: "/operations",
    name: "FormOperation",
    component: FormOperation,
    title: "Cadastro de Operações",
    meta: { requireAuth: true }
  },
  {
    path: "/reports/operations/open",
    name: "PreSaleReport",
    component: PreSaleReport,
    title: "Relatório de Operações em Aberto",
    meta: { requireAuth: true }
  },
  {
    path: "/operations/commissions",
    name: "CommissionsControl",
    component: CommissionsControl,
    title: "Controle de Comissões",
    meta: { requireAuth: true }
  },
  {
    path: "/operations/:id",
    name: "FormEditOperation",
    component: FormOperation,
    title: "Editar Operação",
    meta: { requireAuth: true },
  },
  {
    path: "/reports/exchange",
    name: "ExchangeOperationReport",
    component: ExchangeOperationReport,
    title: "Relatório de Operações de Câmbio Turismo",
    meta: { requireAuth: true }
  },
  {
    path: "/reports/remittance",
    name: "RemittanceOperationReport",
    component: RemittanceOperationReport,
    title: "Relatório de Operações de Remessa",
    meta: { requireAuth: true }
  },
  {
    path: "/reports/remittance/management",
    name: "RemittanceOperationManagementReport",
    component: RemittanceOperationManagementReport,
    title: "Relatório Gerencial de Remessa",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/banks",
    name: "ExchangeBankControl",
    component: ExchangeBankControl,
    title: "Controle de Bancos",
    meta: { requireAuth: true },
  },
  {
    path: "/registrations/banks/create",
    name: "ExchangeFormCreateBank",
    component: ExchangeFormBank,
    title: "Criação e Edição de Bancos",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/banks/update/:id",
    name: "ExchangeFormEditBank",
    component: ExchangeFormBank,
    title: "Criação e Edição de Bancos",
    meta: { requireAuth: true },
  },
  {
    path: "/registrations/operation-natures",
    name: "OperationNaturesControl",
    component: OperationNaturesControl,
    title: "Controle de Naturezas de Operação",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/currencies",
    name: "CurrenciesControl",
    component: CurrenciesControl,
    title: "Controle de Moedas",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/payment-methods",
    name: "PaymentMethodsControl",
    component: PaymentMethodsControl,
    title: "Controle de Métodos de Pagamento",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/rate-apis",
    name: "RateApiRouteControl",
    component: RateApiRouteControl,
    title: "Apis de Cotação",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/templates",
    name: "TemplatesControl",
    component: TemplatesControl,
    title: "Templates",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/templates/create",
    name: "FormTemplate",
    component: FormTemplate,
    title: "Cadastro de templates",
    meta: { requireAuth: true }
  },
  {
    path: "/registrations/templates/:id",
    name: "FormEditTemplate",
    component: FormTemplate,
    title: "Editar Template",
    meta: { requireAuth: true },
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: Configuration,
    title: "Configurações",
    meta: { requireAuth: true }
  },
  {
    path: "/file/view/*",
    name: "FileView",
    component: FileView,
    title: "Visualizando Arquivo",
    meta: { requireAuth: true },
  },
  {
    path: "/customer/documents/external-acess/:token",
    name: "CustomerDocumentsExternalAccess",
    component: CustomerDocumentsExternalAccess,
    title: "Documentos do Cliente",
    meta: { requireAuth: false },
  },
  {
    path: "/operation/documents/external-acess/:token",
    name: "OperationDocumentsExternalAccess",
    component: OperationDocumentsExternalAccess,
    title: "Documentos da Operação",
    meta: { requireAuth: false },
  },
  {
    path: "/audit/",
    name: "AuditControl",
    component: AuditControl,
    title: "Auditoria",
    meta: { requireAuth: true }
  },
  {
    path: "/logs/email",
    name: "EmailLogsControl",
    component: EmailLogsControl,
    title: "Logs de Envio de E-mail",
    meta: { requireAuth: true }
  },
  {
    path: "/profiles",
    name: "ProfilesControl",
    component: ProfilesControl,
    title: "Perfil",
    meta: { requireAuth: true }
  },
  {
    path: "/interbank",
    name: "InterbanksControl",
    component: InterbanksControl,
    title: "Interbancário",
    meta: { requireAuth: true }
  },
  {
    path: "/profiles/create",
    name: "FormCreateProfile",
    component: FormProfile,
    title: "Criação e Edição de Perfis",
    meta: { requireAuth: true }
  },
  {
    path: "/profiles/update/:id",
    name: "FormEditProfile",
    component: FormProfile,
    title: "Criação e Edição de Perfis",
    meta: { requireAuth: true },
  },
];

export default routes;