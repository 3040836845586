<template>
  <div>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        :md="hasPix ? '3' : '5'"
      >
        <v-autocomplete
          data-test="slc-banco"
          label="Banco"
          :item-text="itemTextBank"
          clearable
          item-value="code"
          dense
          outlined
          v-disabled-icon-focus
          v-model="bank.bank"
          :items="listOfBanks"
          :rules="required ? [validationIsRequiredAutocompleteText] : []"
          :data-tab-key="required ? customerFormTab.BANK_CHANNELS.key : ''"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          data-test="txt-agencia"
          v-model="bank.agency"
          outlined
          dense
          label="Agência"
          :rules="required ? [validationIsRequiredField] : []"
          :data-tab-key="required ? customerFormTab.BANK_CHANNELS.key : ''"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          data-test="txt-conta"
          v-model="bank.account"
          outlined
          dense
          label="Conta"
          :rules="required ? [validationIsRequiredField] : []"
          :data-tab-key="required ? customerFormTab.BANK_CHANNELS.key : ''"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="1"
      >
        <v-text-field
          data-test="txt-digito"
          v-model="bank.digit"
          outlined
          dense
          label="Digito"
          :rules="required ? [validationIsRequiredField] : []"
          :data-tab-key="required ? customerFormTab.BANK_CHANNELS.key : ''"
        />
      </v-col>
      <v-col
        v-if="hasPix"
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          data-test="txt-chave-pix"
          v-model="bank.pix"
          outlined
          dense
          label="Chave pix"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Constants
import { CUSTOMER_FORM_TAB } from "@/constants/customer-form-constants";
// Cache
import bankCache from '../../../../common/cache/bank-cache';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Components

export default {
  name: 'BankComponent',
  mixins: [mixinValidationRules],
  components: {},
  model: {
    prop: 'bankProp',
    event: 'onChange'
  },
  props: {
    bankProp: { type: Object, default: () => ({}) },
    hasPix: { type: Boolean, default: false },
    required: { type: Boolean, default: false }
  },
  data() {
    return {
      bank: this.bankProp,
      listOfBanks: [],
      customerFormTab: CUSTOMER_FORM_TAB
    };
  },

  watch: {
    bank: {
      handler() {
        this.$emit('onChange', this.bank);
      },
      deep: true
    }
  },

  async mounted() {
    this.listOfBanks = await bankCache.getBankList();
  },

  computed: {
    itemTextBank() {
      return (item) =>
        !item.code
          ? '000 - ' + item.name.toUpperCase()
          : item.code + ' - ' + item.name.toUpperCase();
    }
  },

  methods: {}
};
</script>

<style></style>
