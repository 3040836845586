<template>
  <div>
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="3"
      >
        <CardDashboard
          description="Saldo Disponível"
          :value="this.account.balance | formatCurrency"
          icon
          :icon-class="ICON_TYPE.CURRENCY.ICON"
          :color="ICON_TYPE.CURRENCY.COLOR"
        />
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <CardDashboard
          description="Total do Período"
          :value="totalAmount || 0 | formatCurrency"
          icon
          :icon-class="ICON_TYPE.CURRENCY.ICON"
          :color="ICON_TYPE.CURRENCY.COLOR"
        />
      </v-col>

      <v-col
        cols="12"
        align="end"
      >
        <span class="mx-2">
          <v-btn
            class="btn-primary"
            text
            @click="modalCreatePaymentOrder = true"
            data-test="btn-solicitar-pagamento"
          >
            Solicitar pagamento
          </v-btn>
        </span>
        <span>
          <v-btn
            class="btn-secondary"
            text
            @click="drawer = true"
          >
            <v-icon>mdi-filter-variant</v-icon>
            Filtrar
          </v-btn>
        </span>
      </v-col>

      <v-col cols="12">
        <v-card class="card-cambio">
          <v-card-text class="pa-5">
            <v-row>
              <v-col cols="12">
                <v-tabs v-model="tab">
                  <v-tab key="operacoesCreditadas">
                    <span>Operações creditadas</span>
                  </v-tab>
                  <v-tab
                    key="solicitacoesPagamento"
                    data-test="tab-solicitacoes-em-aberto"
                  >
                    <span>Solicitações de pagamento</span>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12">
                <v-tabs-items v-model="tab">
                  <v-tab-item key="operacoesCreditadas">
                    <v-card class="card-cambio">
                      <v-data-table
                        dense
                        :headers="columnsCreditedOperation"
                        :items="operations"
                        sort-by="date"
                        no-data-text="Não há informações"
                        sort-desc
                        :items-per-page="paginationDefault"
                        height="calc(100dvh - 410px)"
                        fixed-header
                      >
                        <template #[`item.effectiveOperationDate`]="{ item }">
                          {{ item.effectiveOperationDate | date }}
                        </template>
                        <template #[`item.customerNameOrCompanyName`]="{ item }">
                          <span
                            class="customer-name"
                            v-if="item.customer.companyName"
                          >
                            {{ item.customer.companyName }}
                          </span>
                          <span
                            class="customer-name"
                            v-else
                          >
                            {{ item.customer.name }}
                          </span>
                        </template>
                        <template #[`item.commissionValue`]="{ item }">
                          <template v-if="item.commissionValue">
                            {{ item.commissionValue | formatCurrency }}
                          </template>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item key="solicitacoesPagamento">
                    <v-card class="card-cambio">
                      <v-data-table
                        dense
                        :headers="columnsPaymentOrders"
                        :items="paymentOrders"
                        sort-by="id"
                        no-data-text="Não há informações"
                        sort-desc
                        :items-per-page="paginationDefault"
                        height="calc(100dvh - 410px)"
                        fixed-header
                      >
                        <template #[`item.requestDate`]="{ item }">
                          {{ item.requestDate | date }}
                        </template>

                        <template #[`item.paymentDate`]="{ item }">
                          {{ item.paymentDate | date }}
                        </template>

                        <template #[`item.value`]="{ item }">
                          <template v-if="item.value">
                            {{ item.value | formatCurrency }}
                          </template>
                        </template>

                        <template #[`item.nf`]="{ item }">
                          <template v-if="item.value && item.brazilianAccount">
                            <DataTableDocumentInput
                              download-file-name="NF"
                              :callback-add-document="addDocument"
                              :callback-download-document="downloadDocument"
                              v-model="item.nf"
                            />
                          </template>
                        </template>

                        <template #[`item.proof`]="{ item }">
                          <template v-if="item.value">
                            <DataTableDocumentInput
                              download-file-name="Comprovante"
                              :callback-add-document="addDocument"
                              :callback-download-document="downloadDocument"
                              v-model="item.proof"
                              data-test="document-comprovante-pagamento"
                            />
                          </template>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Create Payment Order -->
    <v-dialog
      persistent
      v-model="modalCreatePaymentOrder"
      max-width="30vw"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Solicitar pagamento</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row>
            <v-col cols="12">
              <v-form
                id="add-payment-order"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="pb-2"
                    cols="12"
                  >
                    <legend class="legend-conta-exterior">Deseja receber em qual conta?</legend>
                    <v-radio-group
                      data-test="rd-conta-exterior"
                      row
                      class="py-0"
                      v-model="paymentOrder.brazilianAccount"
                      :rules="[validationIsRequiredRadioBtn]"
                    >
                      <v-radio
                        :value="true"
                        label="Brasil"
                        v-if="currentCorban.addNationalBank"
                      />
                      <v-radio
                        :value="false"
                        label="Exterior"
                        v-if="currentCorban.addInternationalBank"
                      />
                    </v-radio-group>
                  </v-col>

                  <template v-if="paymentOrder.brazilianAccount">
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-autocomplete
                        label="Conta Brasil"
                        :items="currentCorban.nationalBanks"
                        v-model="paymentAccount"
                        item-value="id"
                        item-text="name"
                        return-object
                        dense
                        clearable
                        outlined
                        v-disabled-icon-focus
                        :rules="[validationIsRequiredAutocompleteId]"
                        data-test="autocomplete-conta-brasil"
                      >
                        <template #item="data">
                          <v-row class="pa-2">
                            <v-col
                              cols="12"
                              col="6"
                              class="px-0"
                            >
                              <p class="item-title">Conta bancária do Brasil</p>
                              <p class="item-text">Banco - {{ data.item.name }}</p>
                              <p class="item-text">Agência - {{ data.item.agency }}</p>
                              <p class="item-text">Conta - {{ data.item.account }}</p>
                              <p class="item-text">Digito - {{ data.item.digit }}</p>
                              <p
                                class="item-text"
                                v-if="data.item.pix"
                              >
                                Pix - {{ data.item.pix }}
                              </p>
                            </v-col>
                          </v-row>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </template>

                  <template v-if="!paymentOrder.brazilianAccount">
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-autocomplete
                        label="Conta exterior"
                        :items="currentCorban.internationalBanks"
                        v-model="paymentAccount"
                        item-value="id"
                        item-text="accountName"
                        return-object
                        dense
                        clearable
                        outlined
                        v-disabled-icon-focus
                        :rules="[validationIsRequiredAutocompleteId]"
                      >
                        <template #item="data">
                          <v-row class="pa-2">
                            <v-col
                              cols="12"
                              col="6"
                              class="px-0 d-block"
                            >
                              <p class="item-title">
                                Conta bancária no exterior - {{ data.item.accountName }}
                              </p>
                              <p class="item-text">Banco - {{ data.item.bank }}</p>
                              <p class="item-text">IBAN - {{ data.item.numberIBAN }}</p>
                              <p class="item-text">
                                Código Swift/BIC - {{ data.item.swiftCodeBIC }}
                              </p>
                              <p
                                class="item-text"
                                v-if="data.item.tabNumberRouting"
                              >
                                Número ABA/Routing - {{ data.item.tabNumberRouting }}
                              </p>
                              <p
                                class="item-text"
                                v-if="data.item.forFurtherCredit"
                              >
                                FFC - {{ data.item.forFurtherCredit }}
                              </p>
                              <p class="item-text pb-2">
                                Nome do titular - {{ data.item.ownerName }}
                              </p>
                            </v-col>
                          </v-row>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </template>

                  <v-col
                    class="py-0 mt-4"
                    cols="12"
                  >
                    <MoneyField
                      label="Valor Solicitado"
                      prefix="R$ "
                      v-model="paymentOrder.value"
                      :max="this.account.balance"
                      :rules="[validationIsRequiredFieldNumeric]"
                      data-test="ipt-valor-solicitado"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    v-if="paymentOrder.brazilianAccount"
                  >
                    <v-file-input
                      label="Nota Fiscal"
                      outlined
                      dense
                      prepend-icon="fa-paperclip"
                      v-model="paymentOrderDocumentNf"
                      v-disabled-icon-focus
                      :rules="[validationIsRequiredFile]"
                      data-test="file-nota-fiscal"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="createPaymentOrder"
              data-test="btn-salvar"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="modalCreatePaymentOrder = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Filtros -->
    <v-navigation-drawer
      v-model="drawer"
      bottom
      fixed
      temporary
      right
      :width="500"
    >
      <v-list nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Filtro</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item>
        <v-list-item-content>
          <v-form id="filters-form">
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <Datepicker
                  v-model="startDate"
                  label="Data de início"
                  v-disabled-icon-focus
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <Datepicker
                  v-model="endDate"
                  label="Data de fim"
                  v-disabled-icon-focus
                />
              </v-col>
            </v-row>
          </v-form>
        </v-list-item-content>
      </v-list-item>

      <template #append>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col
                cols="6"
                class="d-flex justify-center"
              >
                <v-btn
                  class="btn-tertiary"
                  width="100%"
                  text
                  @click="resetFilters"
                >
                  Limpar
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="d-flex justify-center"
              >
                <v-btn
                  class="btn-primary"
                  text
                  width="100%"
                  @click="filter"
                >
                  Filtrar
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
// Apis
import OperationApi from '@/api/operation/operation-api';
import AgentCommissionAccountApi from '@/api/correspondent/agent-commission-account-api';
import PaymentOrderApi from '@/api/operation/payment-order-api';
import PaymentOrderDocumentsApi from '@/api/operation/payment-order-documents-api';
import CorbanbApi from '@/api/correspondent/correspondent-api';

// Models
import OperationFiltersModel from '@/model/operation-filters-model';
import AgentCommissionAccountModel from '@/model/agent-commission-account-model';
import OperationModel from '@/model/operation-model';
import CommissionPaymentOrderModel from '@/model/commission-payment-order-model';
import CommissionPaymentOrderDocumentModel from '@/model/commission-payment-order-document-model';
import CorrespondentModel from '@/model/correspondent-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Utils
import dateUtils from '../../../../common/utils/date';
import numberUtils from '../../../../common/utils/number';

// Components
import Datepicker from '@/components/comum/Datepicker.vue';
import MoneyField from '@/components/comum/MoneyField';
import DataTableDocumentInput from '@/components/comum/DataTableDocumentInput.vue';
import CardDashboard from '@/components/dashboard/CardDashboard.vue';

// Constants
import { COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE } from '../../../../common/constants/generic/types';
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';
import { ICON_TYPE } from '@/constants/dashboard-constants';

export default {
  name: 'CorbanComissionsControl',
  mixins: [mixinMessage, mixinValidationRules],
  components: {
    Datepicker,
    MoneyField,
    DataTableDocumentInput,
    CardDashboard
  },
  filters: {
    date(value) {
      if (new Date(value) == 'Invalid Date') {
        return value;
      }

      return dateUtils.maskDateIso(value);
    },
    formatCurrency(value) {
      return numberUtils.formatCurrency('BRL', value);
    }
  },
  inject: ['appData'],
  data() {
    return {
      loading: true,
      startDate: dateUtils.firstDayOfLastMonth(),
      endDate: new Date(),
      paymentOrder: new CommissionPaymentOrderModel(),
      paymentOrderDocumentNf: undefined,
      paginationDefault: PAGINATION_DEFAULT,
      drawer: false,
      ICON_TYPE,
      tab: 0,
      columnsCreditedOperation: [
        { text: 'Data', value: 'effectiveOperationDate', align: 'center', sortable: true },
        {
          text: 'Nome / Razão Social',
          value: 'customerNameOrCompanyName',
          align: 'left',
          sortable: true
        },
        { text: 'Código Operação', value: 'id', align: 'right' },
        { text: 'Comissão', value: 'commissionValue', align: 'right' }
      ],
      columnsPaymentOrders: [
        { text: 'Data de Solicitação', value: 'requestDate', align: 'center', sortable: true },
        { text: 'Data de Pagamento', value: 'paymentDate', align: 'center', sortable: true },
        { text: 'Valor', value: 'value', align: 'right' },
        { text: 'NF', value: 'nf', align: 'center' },
        { text: 'Comprovante', value: 'proof', align: 'center' }
      ],
      operations: [],
      paymentOrders: [],
      account: new AgentCommissionAccountModel(),
      operationApi: new OperationApi(this.appData.currentUser),
      paymentOrderApi: new PaymentOrderApi(this.appData.currentUser),
      agentCommissionAccountApi: new AgentCommissionAccountApi(this.appData.currentUser),
      modalCreatePaymentOrder: false,
      paymentOrderDocumentsApi: new PaymentOrderDocumentsApi(this.appData.currentUser),
      currentCorban: new CorrespondentModel(),
      corbanbApi: new CorbanbApi(this.appData.currentUser),
      paymentAccount: {}
    };
  },
  async mounted() {
    this.loading = true;
    await this.findAccount();
    this.createDefaultFilter();
    await this.getStatementData();
    await this.getCurrentCorban();
    this.loading = false;
  },

  computed: {
    totalAmount() {
      let total = 0;

      this.operations.forEach((operation) => {
        total += +operation.commissionValue;
      });

      return total;
    }
  },

  watch: {},

  methods: {
    resetFilters() {
      this.createDefaultFilter();
      this.getStatementData();
      this.drawer = false;
    },

    filter() {
      this.drawer = false;
      this.getStatementData();
    },

    createDefaultFilter() {
      this.startDate = dateUtils.firstDayOfLastMonth();
      this.endDate = new Date();
    },

    async getStatementData() {
      await this.getOperations();
      await this.getPaymentOrders();
    },

    getOperations() {
      let operationFilter = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate,
        statusList: [OPERATION_WORKFLOW.LIQUIDADA.id]
      });

      return this.operationApi
        .getOperations(operationFilter)
        .then((response) => {
          this.operations = [];
          let operations = response.data.map((o) => new OperationModel(o));

          operations.forEach((operation) => {
            if (operation.administrative.agentId == this.appData.currentUser.correspondentId) {
              operation.commissionValue = operation.administrative.agentValue;
            } else {
              operation.commissionValue = operation.administrative.indicatorValue;
            }

            this.operations.push(operation);
          });
        })
        .catch(() => {
          this.operations = [];
        });
    },

    getPaymentOrders() {
      let filters = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate
      });

      return this.paymentOrderApi
        .find(filters)
        .then((response) => {
          this.paymentOrders = [];
          let orders = response.data.map((o) => new CommissionPaymentOrderModel(o));

          orders.forEach((order) => {
            order.nf =
              order.documents.find(
                (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id
              ) ||
              new CommissionPaymentOrderDocumentModel({
                paymentOrderId: order.id,
                documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF
              });

            order.proof =
              order.documents.find(
                (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF.id
              ) ||
              new CommissionPaymentOrderDocumentModel({
                paymentOrderId: order.id,
                documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF
              });

            order.requestDate = order.requestDate || 'Pagamento sem requisição';
            order.paymentDate = order.paymentDate || 'Pagamento pendente';

            this.paymentOrders.push(order);
          });
        })
        .catch(() => {
          this.paymentOrders = [];
        });
    },

    findAccount() {
      return this.agentCommissionAccountApi
        .findAccounts()
        .then((response) => {
          this.account = new AgentCommissionAccountModel(response.data);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    createPaymentOrder() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.paymentOrder = new CommissionPaymentOrderModel({
        ...this.paymentOrder,
        ...this.paymentAccount
      });
      this.paymentOrder.id = undefined;
      this.paymentOrder.requestDate = new Date();
      this.$eventBus.$emit('show-loading', true);

      this.paymentOrderApi
        .add(this.paymentOrder)
        .then((response) => {
          this.createPaymentOrderNfDocument(response.data);
          this.findAccount();
          this.getPaymentOrders();

          this.modalCreatePaymentOrder = false;
          this.paymentOrder = new CommissionPaymentOrderModel();
          this.$refs.form.resetValidation();

          this.sendMessage('Solicitação aberta com sucesso!', 'success');
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    createPaymentOrderNfDocument(paymentOrder) {
      let formData = new FormData();

      formData.append('paymentOrderId', paymentOrder.id);
      formData.append('documentTypeId', COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id);
      formData.append('document', this.paymentOrderDocumentNf);

      this.paymentOrderDocumentsApi
        .add(formData)
        .then(() => {
          this.paymentOrderDocumentNf = undefined;
        })
        .catch(() => {});
    },

    addDocument(document) {
      if (document.documentType.id != COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id) {
        this.sendMessage('Você só pode adicionar documentos do tipo NF!', 'error');
        document.document = undefined;

        return document;
      }

      let formData = new FormData();

      formData.append('paymentOrderId', document.paymentOrderId);
      formData.append('documentTypeId', document.documentType.id);
      formData.append('document', document.document);

      return this.paymentOrderDocumentsApi
        .add(formData)
        .then((response) => {
          return (document = {
            ...response.data
          });
        })
        .catch(() => {
          this.sendMessage('Erro ao adicionar o anexo!', 'error');
          document.document = undefined;

          return document;
        });
    },

    downloadDocument(document) {
      return this.paymentOrderDocumentsApi.download(document.id);
    },

    getCurrentCorban() {
      return this.corbanbApi
        .findId(this.appData.currentUser.correspondentId)
        .then((response) => {
          this.currentCorban = new CorrespondentModel(response.data);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    }
  }
};
</script>

<style scoped lang="scss">
.remaining-value {
  color: var(--v-primary-base);
}

#add-payment-order {
  color: black;
}

.theme--dark .legend-conta-exterior {
  color: white;
}

.item-text {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0 0 0 15px;
  margin: 0;
}

.item-title {
  color: var(--v-primary-base);
  font-size: 0.9rem;
  font-weight: 600;
}
</style>
