import BaseApi from '../baseApi';

export default class ConfigurationApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  findAll() {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/configurations')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findById(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/configurations/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findBySession(session) {
    return new Promise((resolve, reject) => {
      return this.api
        .get(`/configurations/session/${session}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findByGroup(group) {
    return new Promise((resolve, reject) => {
      return this.api
        .get(`/configurations/group/${group}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateList(configurations) {
    configurations = configurations || [];

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/configurations/list`, configurations)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
