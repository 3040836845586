import BaseApi from '../baseApi';

export default class OperationApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  add(operation) {
    operation = operation || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/operations', operation)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  find(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/operations/', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  update(operation) {
    operation = operation || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/operations/${operation.id}`, operation)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findId(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/operations/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  download(documentId) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/operation/documents/' + documentId + '/download', { responseType: 'blob' })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  massUpdateComissions(filters, comission) {
    return new Promise((resolve, reject) => {
      return this.api
        .put('/operations/comissions', { comission }, { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getOperations(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/operations/', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  creditOperationCommissions(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .put('/operations/credit', {}, { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateStatus(operationId, workflow) {
    workflow = workflow || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/operations/${operationId}/update-status`, workflow)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  cancelOperation(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .delete(`/operations/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
