import BaseApi from '../baseApi';

export default class OperationDocumentApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  add(formData) {
    formData = formData || {};

    return new Promise((resolve, reject) => {
      return this.api({
        method: 'post',
        url: '/operation/documents',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findAll(operationId) {
    return new Promise((resolve, reject) => {
      return this.api
        .get(`/operation/${operationId}/documents`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  remove(documentId) {
    documentId = documentId || {};

    return new Promise((resolve, reject) => {
      return this.api
        .delete(`/operation/documents/${documentId}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  download(documentId) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/operation/documents/' + documentId + '/download', { responseType: 'blob' })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findExternalAccess() {
    return new Promise((resolve, reject) => {
      return this.api
        .get(`/operation/documents/external-access`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
