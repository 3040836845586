import BaseApi from '../baseApi';

export default class PaymentOrderApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  add(order) {
    order = order || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/payment-orders', order)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  find(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/payment-orders/', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  update(order) {
    order = order || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/payment-orders/${order.id}`, order)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findId(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/payment-orders/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findOpen() {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/payment-orders/open')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
