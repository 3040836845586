import ExchangeBankContactModel from './exchange-bank-contact-model';
import ExchangeBankTypeModel from './exchange-bank-type-model';
import ExchangeStoreModel from './exchange-store-model';

export default class ExchangeBankModel {
  constructor(obj) {
    obj = obj || {};
    let contacts = obj.contacts?.map(contact => new ExchangeBankContactModel(contact));
    let exchangeStores = obj.exchangeStores?.map(stores => new ExchangeStoreModel(stores));

    this.id = obj.id;
    this.cnpj = obj.cnpj;
    this.companyName = obj.companyName;
    this.fantasyName = obj.fantasyName;
    this.active = obj.active == undefined ? true : !!obj.active;
    this.exchangeType = new ExchangeBankTypeModel(obj.exchangeType);
    this.bank = parseInt(obj.bank) || undefined;
    this.agency = obj.agency;
    this.account = obj.account;
    this.pix = obj.pix;
    this.paymentAccount = obj.paymentAccount;
    this.linkedBank = obj.linkedBank;
    this.commission = obj.commission;
    this.exchangeContractCost = obj.exchangeContractCost;
    this.cep = obj.cep;
    this.country = obj.country;
    this.state = obj.state;
    this.city = obj.city;
    this.neighborhood = obj.neighborhood;
    this.street = obj.street;
    this.number = obj.number;
    this.complement = obj.complement;
    this.contacts = contacts?.length ? contacts : [];
    this.firstRegisterEmail = obj.firstRegisterEmail;
    this.secondRegisterEmail = obj.secondRegisterEmail;
    this.thirdRegisterEmail = obj.thirdRegisterEmail;
    this.firstOperationEmail = obj.firstOperationEmail;
    this.secondOperationEmail = obj.secondOperationEmail;
    this.thirdOperationEmail = obj.thirdOperationEmail;
    this.firstDocumentationEmail = obj.firstDocumentationEmail;
    this.secondDocumentationEmail = obj.secondDocumentationEmail;
    this.thirdDocumentationEmail = obj.thirdDocumentationEmail;
    this.firstDiDueEmail = obj.firstDiDueEmail;
    this.secondDiDueEmail = obj.secondDiDueEmail;
    this.thirdDiDueEmail = obj.thirdDiDueEmail;
    this.firstFinancialEmail = obj.firstFinancialEmail;
    this.secondFinancialEmail = obj.secondFinancialEmail;
    this.thirdFinancialEmail = obj.thirdFinancialEmail;
    this.exchangeStores = exchangeStores?.length ? exchangeStores : [];
    this.useInOutLimit = obj.useInOutLimit;
    this.useUsdLimit = obj.useUsdLimit;
    this.useUsdExchangeContract = obj.useUsdExchangeContract;
    this.exchangeContractCashBack = obj.exchangeContractCashBack;
    this.externalId = obj.externalId;
    this.pfRegisterFormTemplate = obj.pfRegisterFormTemplate;
    this.pjRegisterFormTemplate = obj.pjRegisterFormTemplate;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}