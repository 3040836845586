import OperationDocumentTypeModel from "./operation-document-type-model";

export default class OperationDocumentModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.operationId = obj.operationId;
    this.operationDocumentType = new OperationDocumentTypeModel(obj.operationDocumentType);
    this.path = obj.path;
    this.fileName = obj.fileName;
    this.description = obj.description;
    this.document = obj.document;
    this.size = obj.size;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}