<template>
  <v-row :key="key">
    <v-col
      cols="12"
      md="12"
      v-if="isExchange"
    >
      <legend>Pretende operar valores superiores a 60 mil reais por ano? Favor adicionar seu Imposto de Renda e Recibo.</legend>
      <v-radio-group
        row
        class="py-0"
        v-model="moreThanSixtyKYearly"
      >
        <v-radio
          :value="true"
          label="Sim"
        />
        <v-radio
          :value="false"
          label="Não"
        />
      </v-radio-group>
      <v-divider />
    </v-col>
    <template v-for="(customerDocument, i) in documents">
      <v-col
        v-if="i != 0 && i % 2 == 0"
        class="py-4"
        cols="12"
        md="12"
        :key="'dividerDocument' + i"
      >
        <v-divider />
      </v-col>
      <v-col
        class="py-5"
        cols="12"
        md="6"
        :key="'fileDocument' + i"
      >
        <DocumentInput
          :data-test="`document-input-${customerDocument.documentType.id}`"
          v-model="documents[i]"
          :api="apiDocument"
          :customer-id="customer.id"
          :required="documents[i].required"
          :info="documents[i].documentType.additionalInfo"
        />
      </v-col>
    </template>
    <v-col
      class="py-4"
      cols="12"
      md="12"
    >
      <v-row>
        <v-col
          class="py-4"
          cols="12"
          md="12"
        >
          <h1>Outros documentos</h1>
          <v-divider class="py-3" />
        </v-col>
        <v-col
          v-for="(other, i) in others"
          class="py-0"
          cols="12"
          md="6"
          :key="'others' + i"
        >
          <DocumentInput
            :description="others[i].description || 'Outros ' + (i+1)"
            v-model="others[i]"
            :api="apiDocument"
            :customer-id="customer.id"
            :required="others[i].required"
            @insert-document="insertOther"
          >
            <template #append-outer>
              <v-btn 
                v-if="others.length >= 2 && !(others[i].document || others[i].path)"
                class="py-0"
                icon
                @click="removeOther(i)"
                text
              >
                <i class="far fa-trash" />
              </v-btn>
              <v-btn
                class="py-0"
                icon
                @click="insertOther"
                text
              >
                <i class="far fa-plus" />
              </v-btn>
            </template>
          </DocumentInput>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Components
import DocumentInput from "@/components/comum/DocumentInput";

// Constants
import { DOCUMENT_TYPE } from "@/constants/general-constants.js";
import CustomerDocumentModel from "../../../../model/customer-document-model";

// APIs
import CustomerDocumentsApi from "@/api/customer/customer-documents-api";

export default {
  name: "PFCustomerDocuments",
  components: { DocumentInput },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
      default: () => {}
    },
    isExchange: {
      type: Boolean,
    },
    isBasicRemitance: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },
  },
  inject: ['appData'],
  data() {
    return {
      customer: this.customerProp,
      documents: [],
      moreThanSixtyKYearly: false,
      documentTypes: DOCUMENT_TYPE,
      apiDocument: new CustomerDocumentsApi(this.appData.currentUser),
      key: 0,
      others: [],
    };
  },
  mounted() {
    this.mountDocuments();
  },
  watch: {
    "customer.additional.operationNature.id"() {
      this.mountDocuments();
    },
    "customer.registerType.id"() {
      this.mountDocuments();
    },
    "customer.isBrazilian"() {
      this.mountDocuments();
    },
    moreThanSixtyKYearly() {
      this.mountDocuments();
    },
    documents: {
      handler() {
        this.updateCustomerDocuments();
      },
      deep: true,
    },
    others: {
      handler() {
        this.updateCustomerDocuments();
      },
      deep: true,
    },
  },
  methods: {
    mountDocuments() {
      this.documents = [];

      let groupedDocuments = this.customer.documents.reduce((grouped, current) => {
          const value = current.documentType.id;
          grouped[value] = (grouped[value] || []).concat(current);

          return grouped;
      }, {});

      // Passaporte
      if (!this.customer.isBrazilian) {
        let passaporte = groupedDocuments[16]?.shift() ||
          new CustomerDocumentModel({ documentType: this.documentTypes[15] });
        this.documents.push({ ...passaporte, required: true });
        this.key = this.key + 1;

        return;
      }

      let operationNature = parseInt(this.customer.additional?.operationNature?.id) || 1;

      // Identificacao
      let identificacao =
        groupedDocuments[1]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[0] });
      this.documents.push({ ...identificacao, required: true });

      // Identificacao Verso
      let idVerso =
        groupedDocuments[2]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[1] });
      this.documents.push({ ...idVerso, required: false });

      // Declaracao e Recibo IR
      let declaracaoIR =
        groupedDocuments[4]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[3] });
      let reciboIR =
        groupedDocuments[5]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[4] });

      if (
        (this.isExchange && this.moreThanSixtyKYearly) ||
        this.isCompleteRemittance
      ) {
        this.documents.push({ ...declaracaoIR, required: true });
        this.documents.push({ ...reciboIR, required: true });
      } else {
        this.documents.push({ ...declaracaoIR, required: false });
        this.documents.push({ ...reciboIR, required: false });
      }

      // Comprovante Endereco
      let comprovanteEndereco =
        groupedDocuments[3]?.shift() ||
        new CustomerDocumentModel({ documentType: this.documentTypes[2] });
      
      if (!this.isExchange || this.moreThanSixtyKYearly) {
        this.documents.push({ ...comprovanteEndereco, required: true });
      } else {
        this.documents.push({ ...comprovanteEndereco, required: false });
      }

      // Contrato de Compra de Imóvel
      if (operationNature == 2 && !this.isExchange) {
        let contratoCompraImovel =
          groupedDocuments[6]?.shift() ||
          new CustomerDocumentModel({ documentType: this.documentTypes[5] });
        this.documents.push({ ...contratoCompraImovel, required: false });
      }

      // Documento PJ no Exterior
      if (operationNature == 4 && !this.isExchange) {
        let docPjExterior =
          groupedDocuments[15]?.shift() ||
          new CustomerDocumentModel({ documentType: this.documentTypes[14] });
        this.documents.push({ ...docPjExterior, required: false });
      }

      // Outros
      this.others = groupedDocuments[7] ||
        [ new CustomerDocumentModel({ documentType: this.documentTypes[6] }) ];

      this.key = this.key + 1;
    },
    removeOther(index) {
      if (this.others.length > 1) {
        this.others.splice(index, 1);
        this.key = this.key + 1;
      }
    },
    insertOther() {
      this.others.push(new CustomerDocumentModel({ documentType: this.documentTypes[6] }));
    },
    updateCustomerDocuments() {
      let documents = this.documents.filter((d) => d.document || d.id);
      let others = this.others.filter((d) => d.document || d.id);
      this.customer.documents = [...documents, ...others];
      this.$emit("onChange", this.customer);
    },
  },
};
</script>

<style>

</style>