<template>
  <div>
    <!-- expand on hover is bugged, needs mini-variant to work properly. https://github.com/vuetifyjs/vuetify/issues/14585 -->
    <v-navigation-drawer
      v-model="drawer"
      app
      :expand-on-hover="!appData.isMobile"
      :mini-variant="!appData.isMobile"
      :width="325"
    >
      <MenuStatic />
    </v-navigation-drawer>
    <v-app-bar
      app
      class="mx-2 mx-md-5"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <LogoType max-height="35px" />
      <v-spacer />
      <v-menu offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                color="primary"
                icon
                class="ml-2"
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-badge
                  color="error"
                  bottom
                  dot
                  :value="appData.pendingPreSale"
                >
                  <i class="far fa-bell" />
                </v-badge>
              </v-btn>
            </template>
            <span>Notificações</span>
          </v-tooltip>
        </template>
        <v-list width="200px">
          <v-list-item
            v-if="appData.notificationList.length == 0"
          >
            <v-list-item-content>
              <v-list-item-subtitle>Não há novas notificações</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template
            v-else
            v-for="(item, index) in notificationList"
          >
            <v-list-item
              :key="index"
              class="pb-0"
            >
              <v-list-item-content class="pb-0">
                <v-list-item-subtitle>
                  {{ item.date | dateHour }}
                </v-list-item-subtitle>
                <v-list-item-content class="pb-0"><p>{{ item.message }}</p></v-list-item-content>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'divider' + index" />
          </template>
        </v-list>
      </v-menu>

      <div class="simulation-wrapper d-none d-sm-flex">
        <div class="pt-1">
          <v-btn
            v-if="!isOpenSimulator"
            class="btn-secondary"
            outlined
            @click="openOperationSimulator"
          >
            <i class="far fa-money-bill-wave-alt pr-2" />
            Simular operação
          </v-btn>
        </div>
        <v-chip
          v-if="isOpenSimulator"
          class="text-truncate"
          close
          color="primary"
          text-color="white"
          @click:close="closeOperationSimulator"
        >
          <span class="pr-2">
            Simulando operação
          </span>
        </v-chip>
      </div>
      <div class="d-flex d-sm-none">
        <v-btn
          v-if="!isOpenSimulator"
          icon
          color="primary"
          @click="openOperationSimulator"
        >
          <i class="far fa-money-bill-wave-alt pr-0" />
        </v-btn>
        <v-chip
          v-if="isOpenSimulator"
          class="ma-3 text-truncate "
          close
          color="primary"
          text-color="white"
          @click:close="closeOperationSimulator"
        >
          <i class="far fa-money-bill-wave-alt pr-2" />
        </v-chip>
      </div>
      
      <v-menu offset-y>
        <template #activator="{ on: menu, attrs }">
          <v-btn
            color="primary"
            icon
            v-bind="attrs"
            v-on="{ ...menu }"
            class="ml-2"
          >
            <i class="far fa-user-cog" />
          </v-btn>
        </template>
        <v-list width="180px">
          <v-list-item-group>
            <v-list-item @click="() => (isOpenCalculationMemory = true)">
              <i class="far fa-info-circle icon-tooltip" />
              Memória de cálculo
            </v-list-item>
            <v-list-item @click="changeTheme">
              <i
                v-if="$vuetify.theme.dark"
                class="far fa-sun"
              />
              <i
                v-else
                class="far fa-moon"
              />
              Alterar tema
            </v-list-item>
            <v-list-item 
              v-access="$access.MENU.CONFIGURACOES"
              @click="goToConfigurations"
            >
              <i class="far fa-wrench" />
              Configurações
            </v-list-item>
            <v-list-item
              v-access="$access.MENU.EMAILS_ENVIADOS"
              @click="goToEmailLogs"
            >
              <i class="far fa-envelope" />
              E-mails enviados
            </v-list-item>
            <v-list-item
              v-access="$access.MENU.ALTERAR_SENHA"
              @click="changePassword()"
            >
              <i class="far fa-lock-alt" />
              Alterar Senha
            </v-list-item>
            <v-list-item @click="goToHelpDesk">
              <i class="far fa-question-circle" />
              Ajuda
            </v-list-item>
            <v-list-item @click="logout()">
              <i class="far fa-sign-out" />
              Sair
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-dialog
      width="300px"
      v-model="changePasswordWindow"
    >
      <v-card>
        <v-card-title>
          <h3>Alterar Senha</h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-5">
          <v-text-field
            label="Nova Senha"
            required
            :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            autofocus
            v-model="user.newPassword"
          />
          <v-text-field
            label="Confirme sua senha"
            required
            :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            v-model="user.confirmNewPassword"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updatePassword()"
            class="btn-primary"
            text
          >
            Salvar
          </v-btn>
          <v-btn
            @click="close()"
            class="btn-tertiary"
            text
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isOpenCalculationMemory"
      max-width="50%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Memória de cálculo</span>
        </v-card-title>

        <div class="alignment-variables">
          <ul>
            <li class="pb-4">
              <strong>Lucro bruto:</strong><span> Total da operação - lucro do banco</span><br>
              <code><strong>Exemplo: </strong>Total operado 100 - Lucro do banco 20 = Lucro bruto 80</code>
            </li>
            <li class="pb-4">
              <strong>Corban:</strong><span> Lucro líquido * % comissão do corban</span><br>
              <code><strong>Exemplo: </strong>Lucro líquido 60 * comissão Corban 10% = Lucro do Corban 6</code>
            </li>
            <li class="pb-4">
              <strong>Corban indicador:</strong><span> Lucro líquido * % comissão do indicador</span><br>
              <code><strong>Exemplo: </strong>Lucro líquido 60 * comissão Indicador 5% = Lucro do Indicador 3</code>
            </li>
            <li class="pb-4">
              <strong>Total da operação:</strong>
              <ul>
                <li>
                  <strong>Para remessa:</strong><span> Quantidade da ME * taxa do cliente</span><br>
                  <code><strong>Exemplo: </strong>Quantidade ME 1000 * Taxa do cliente 5,50 = Total da Operação 5500</code>
                </li>
                <li>
                  <strong>Para câmbio:</strong><span> Quantidade da ME * taxa do cliente sem IOF</span><br>
                  <code><strong>Exemplo: </strong>Quantidade ME 1000 * Taxa do cliente sem IOF 5,30 = Total da Operação 5300</code>
                </li>
              </ul>
            </li>
            <li class="pb-4">
              <strong>PIS/COFINS:</strong><span> Lucro bruto * 0.0465</span><br>
              <code><strong>Exemplo: </strong>Lucro bruto 80 * 0.0465 = 3,72</code>
            </li>
            <li class="pb-4">
              <strong>Cashback do contrato:</strong><span> Valor do contrato * % da comissão</span><br>
              <code><strong>Exemplo: </strong>Valor do contrato 10000 * comissão 0,5% = Cashback do contrato 50</code>
            </li>
            <li class="pb-4">
              <strong>Lucro do banco:</strong><span> (1 - % da comissão) * lucro bruto</span><br>
              <code><strong>Exemplo: </strong>1 - comissão 25% * Lucro bruto 80 = Lucro do Banco 60</code>
            </li>
            <li class="pb-4">
              <strong>Lucro corban master:</strong><span> Lucro bruto - lucro do banco + cashback do contrato</span><br>
              <code><strong>Exemplo: </strong>Lucro bruto 80 - Lucro do banco 60 + Cashback do contrato 50 = Lucro Corban Master 70</code>
            </li>
          </ul>
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-primary mr-2"
            @click="() => (isOpenCalculationMemory = false)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

// Api
import authApi from "@/api/auth/auth-api";
import ConfigurationApi from "../../api/generic/configuration-api";

// Models
import UserModel from "@/model/user-model";
import ConfigurationModel from "@/model/generic/configuration-model";

// Components
import MenuStatic from "@/components/menu/MenuStatic.vue";
import LogoType from '@/components/comum/LogoType.vue';

// Utils
import utilsStorage from "@/utils/storage";
import dateUtils from '../../../../common/utils/date';

// Mixin
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";

// Tracking
import mixpanel from "mixpanel-browser";

import { OPTIONS_STORE_CONFIGURATIONS } from "@/vuex/module/configurations";

export default {
  name: "Menu",
  mixins: [mixinMessage, mixinGeral],
  components: {
    MenuStatic,
    LogoType
  },
  inject: ['appData'],
  data() {
    return {
      drawer: true,
      routes: [],
      isOpenCalculationMemory: false,
      changePasswordWindow: false,
      value: "password",
      user: new UserModel(),
      email: "",
      configurationApi: new ConfigurationApi(this.appData.currentUser),
    };
  },
  filters: {
    dateHour(value) {
      return dateUtils.maskHourWithoutSeconds(value);
    },
  },
  mounted() {
    let user = utilsStorage.getUserOnStorage();

    if (!user) return;

    this.user = new UserModel(user);

    this.startPendo();
    this.getAllConfigurationsForStore();
  },
  computed: {
    notificationList() {
      let notificationReverse = this.appData.notificationList;
      notificationReverse.reverse();

      return notificationReverse;
    }
  },
  props: {
    isOpenSimulator: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'isOpenSimulator',
    event: 'onChange'
  },

  methods: {
    openOperationSimulator() {
      this.$emit('onChange', true);
    },
    closeOperationSimulator() {
      this.$emit('onChange', false);
    },
    goToConfigurations() {
      this.$router.push({ name: "Configuration" })
        .catch(() => { });
    },

    goToEmailLogs() {
      this.$router.push({ name: "EmailLogsControl" })
        .catch(() => {});
    },

    goToHelpDesk() {
      window.open('https://deeprocket.atlassian.net/servicedesk/customer/portals', '_blank');
      mixpanel.track('click', {
        button_name: 'btn_help_desk'
      });
    },

    getAllConfigurationsForStore() {
      this.configurationApi.findAll()
        .then(response => {
          let configurations = response.data.map(c => new ConfigurationModel(c));
          this.updateAllConfigurationsStoreBasedAPI(configurations);
        })
        .catch(() => { });
    },
    updateAllConfigurationsStoreBasedAPI(configurations) {
      this.$store.dispatch(OPTIONS_STORE_CONFIGURATIONS.ACTIONS.UPDATE_CONFIGURATION_MODEL, configurations);
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      utilsStorage.setThemeSettings(this.$vuetify.theme.dark);
    },
    changePassword() {
      this.changePasswordWindow = true;
      mixpanel.track("click", {
        button_name: "btn_change_password",
      });
    },
    exit() {
      mixpanel.track("click", {
        button_name: "btn_logout",
      });
      this.logout();
    },

    logout() {
      utilsStorage.removeAuth();
      this.$eventBus.$emit('token-changed');
      this.$router.push({ name: "Login" });
    },
    startPendo() {
      if (!window.pendo) return;

      window.pendo.initialize({
        visitor: {
          id: this.user.id,
          email: this.user.email,
          full_name: this.user.name,
          role: this.user.profileId,
        },
        account: {
          id: this.user.id,
        },
      });
    },
    updatePassword() {
      if (this.user.newPassword === this.user.confirmNewPassword) {
        authApi
          .changePassword(this.user.newPassword)
          .then(() => {
            this.sendMessage("Senha alterada com sucesso", "success");
            this.changePasswordWindow = false;
            this.cleanFields();
          })
          .catch(() => {
            this.sendMessage("Não foi possível alterar a senha", "error");
          });
      } else {
        this.sendMessage("As senhas devem ser iguais", "error");
      }
    },

    close() {
      this.cleanFields();
      this.changePasswordWindow = false;
    },

    cleanFields() {
      this.user = new UserModel();
    },
  },
};
</script>

<style scoped>
.alignment-variables {
  margin-left: 15px;
}
.simulation-wrapper {
  display: flex;
  align-items: center;
}
</style>