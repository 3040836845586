<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-card
        class="box card-cambio"
        data-test="customer-form-tab-card"
      >
        <v-row data-test="customer-form-tab-header-row">
          <v-col
            cols="12"
            md="7"
            sm="12"
            data-test="customer-form-tab-tabs-col"
          >
            <v-tabs
              v-model="tab"
              data-test="customer-form-tabs"
            >
              <v-tab
                v-for="tabItem in tabsToShow"
                :key="tabItem.key"
                :data-test="tabItem.dataTest"
                v-administrative-access="tabItem.adminAccess"
              >
                <v-badge
                  v-if="invalidTabs.some(t => t.key === tabItem.key)"
                  color="error"
                  :content="invalidTabs.find(t => t.key === tabItem.key).count"
                >
                  <span>{{ tabItem.label }}</span>
                </v-badge>
                <span v-else>{{ tabItem.label }}</span>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col
            cols="12"
            md="5"
            class="customer-info pt-0 pt-md-3"
            data-test="customer-form-tab-info-col"
          >
            <v-tooltip
              top
              data-test="tooltip-external-id"
            >
              <template #activator="{ on, attrs }">
                <span
                  class="externalId"
                  v-if="customer.externalId"
                  v-bind="attrs"
                  v-on="on"
                  data-test="span-external-id"
                >
                  <i class="far fa-cloud-upload" />
                </span>
              </template>
              Cliente importado com ID externo {{ customer.externalId }}
            </v-tooltip>
            <div
              class="d-flex flex-column text-right"
              data-test="div-customer-info"
            >
              <span
                class="title-customer-name pb-0"
                cols="12"
                data-test="span-customer-name"
              >
                {{ customer.isPJ() ? customer.companyName : customer.name }}
              </span>
              <span
                cols="12"
                class="subtitle-customer-cpfcnpj py-0"
                data-test="span-customer-cpfcnpj"
              >
                {{ customer.cpfCnpj }}
              </span>
            </div>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item
            eager
            key="dadosCadastrais"
          >
            <v-card>
              <CustomerCover
                :is-p-f="isPF"
                :is-exchange="isExchange"
                :is-basic-remitance="isBasicRemittance"
                :is-complete-remittance="isCompleteRemittance"
                v-model="customer"
              />
              <CustomerContact v-model="customer" />
              <CustomerAddress
                :is-p-f="isPF"
                :is-exchange="isExchange"
                v-model="customer"
              />
              <CustomerWork
                v-if="isCompleteRemittance"
                v-model="customer"
              />
              <CustomerDirectorsProxies v-model="customer" />

              <CustomerPoliticallyExposedPerson v-model="customer" />
              <CustomerUSPerson v-model="customer" />
              <PFCustomerTaxDomicileAbroad v-model="customer" />
              <CustomerCompliance
                v-if="isLegalPerson"
                v-model="customer"
              />
              <CustomerRegistrationResponsible v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            v-if="isLegalPerson"
            eager
            key="partners"
          >
            <v-card class="box">
              <CustomerPartners
                v-model="customer.partnersShareholders"
                :company-name="customer.companyName"
                :linked-customer-id="customer.id"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="canaisBancarios"
          >
            <v-card>
              <CustomerInternationalAccount
                v-if="isRemittance || isLegalPerson"
                v-model="customer"
              />
              <CustomerFinancial
                v-model="customer"
                :is-exchange="isExchange"
                :is-legal-person="isLegalPerson"
                :is-remitance="isRemittance"
                :is-complete-remittance="isCompleteRemittance"
              />
              <CustomerPaymentAccounts v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="documentos"
          >
            <v-card>
              <CustomerDocuments
                :is-legal-person="isLegalPerson"
                :is-exchange="isExchange"
                :is-basic-remitance="isBasicRemittance"
                :is-complete-remittance="isCompleteRemittance"
                v-model="customer"
              />
              <CustomerOperationalDocuments v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="limites"
          >
            <v-card>
              <CustomerExchangeBanks v-model="customer" />
            </v-card>
          </v-tab-item>
          <v-tab-item
            eager
            key="spreads"
          >
            <v-card>
              <CustomerSpreads v-model="customer" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CustomerCover from '@/components/form-customer/CustomerCover';
import CustomerContact from '@/components/form-customer/CustomerContact';
import CustomerAddress from '@/components/form-customer/CustomerAddress';
import CustomerDirectorsProxies from '@/components/form-customer/CustomerDirectorsProxies.vue';
import CustomerFinancial from '@/components/form-customer/CustomerFinancial.vue';
import CustomerWork from '@/components/form-customer/CustomerWork.vue';
import CustomerPoliticallyExposedPerson from '@/components/form-customer/CustomerPoliticallyExposedPerson.vue';
import CustomerCompliance from '@/components/form-customer/CustomerCompliance.vue';
import CustomerRegistrationResponsible from '@/components/form-customer/CustomerRegistrationResponsible.vue';
import CustomerInternationalAccount from '@/components/form-customer/CustomerInternationalAccount.vue';
import CustomerDocuments from '@/components/form-customer/CustomerDocuments.vue';
import CustomerExchangeBanks from '@/components/form-customer/CustomerExchangeBanks.vue';
import CustomerSpreads from '@/components/form-customer/CustomerSpreads.vue';
import CustomerOperationalDocuments from '@/components/form-customer/CustomerOperationalDocuments.vue';
import CustomerPaymentAccounts from '../../components/form-customer/CustomerPaymentAccounts.vue';
import CustomerPartners from './CustomerPartners.vue';
import CustomerUSPerson from '@/components/form-customer/PF/usPerson/PFCustomerUSPerson.vue';
import PFCustomerTaxDomicileAbroad from "@/components/form-customer/PF/taxDomicileAbroad/PFCustomerTaxDomicileAbroad.vue";

// Constants
import { CUSTOMER_FORM_TABS, CUSTOMER_FORM_TAB } from '@/constants/customer-form-constants';

export default {
  name: 'CustomerFormTab',
  components: {
    CustomerCover,
    CustomerContact,
    CustomerAddress,
    CustomerPartners,
    CustomerDirectorsProxies,
    CustomerFinancial,
    CustomerWork,
    CustomerPoliticallyExposedPerson,
    CustomerCompliance,
    CustomerRegistrationResponsible,
    CustomerInternationalAccount,
    CustomerDocuments,
    CustomerOperationalDocuments,
    CustomerExchangeBanks,
    CustomerSpreads,
    CustomerPaymentAccounts,
    CustomerUSPerson,
    PFCustomerTaxDomicileAbroad
  },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => {}
    },
    isLegalPerson: {
      type: Boolean
    },
    isPF: {
      type: Boolean
    },
    isExchange: {
      type: Boolean
    },
    isRemittance: {
      type: Boolean
    },
    isBasicRemittance: {
      type: Boolean
    },
    isCompleteRemittance: {
      type: Boolean
    },
    invalidTabs: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  },

  computed: {
    tabsToShow() {
      this.tabs.find(tab => tab.key === CUSTOMER_FORM_TAB.PARTNERS.key).show = this.isLegalPerson;

      return this.tabs?.filter(tab => tab.show);
    },
  },
  inject: ['appData'],
  data() {
    return {
      customer: this.customerProp,
      tab: 0,
      tabs: CUSTOMER_FORM_TABS
    };
  },

  mounted() {
    if (this.appData.currentUser.correspondentId) {
      this.customer.correspondent.id = this.appData.currentUser.correspondentId;
    }
  }
};
</script>

<style scoped>
.card-cambio {
  position: relative;
}
.externalId {
  z-index: 9;
  border-radius: 8px;
  font-size: 1rem;
  background-color: var(--v-backgroundSecondary-base);
}

.title-customer-name {
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--v-primary-base);
}

.subtitle-customer-cpfcnpj {
  font-weight: 500;
  font-size: 0.9rem;
}

.customer-info {
  display: flex;
  justify-content: end;
  gap: 10px;
}

::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
  visibility: hidden;
}
</style>
