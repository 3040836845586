<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          width="400px"
          class="py-4"
        />
        <v-divider />
        <v-skeleton-loader
          type="button"
          min-width="100px"
          class="py-5"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <h1 class="titulo">Controle de clientes</h1>
        <v-divider />
      </v-col>

      <v-col
        cols="12"
        md="9"
        sm="8"
        class="pa-0 pl-3"
        align-self="center"
      >
        <v-btn
          class="btn-primary ml-0"
          text
          @click="addCustomers"
          data-test="btn-adicionar-cliente"
        >
          <i class="far fa-plus" />
          Adicionar
        </v-btn>
        <v-btn
          class="btn-secondary ml-2"
          text
          @click="openModalPreForm"
          data-test="btn-enviar-pre-cadastro"
        >
          <i class="far fa-paper-plane" />
          Enviar pré-cadastro
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="4"
        class="pa-0 pr-3"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          data-test="ipt-pesquisar-cliente"
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio pt-1">
          <v-data-table
            :headers="columns"
            :items="customers"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="10"
            single-expand
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            :options.sync="options"
            :loading="dataTableIsLoading"
            :server-items-length="customerCount"
            height="calc(100dvh - 325px)"
            fixed-header
            data-test="tbl-clientes"
          >
            <template #[`item.customerNameOrCompanyName`]="{ item }">
              <span
                class="customer-name"
                v-if="item.isPJ()"
              >{{ item.companyName }}
                <span v-if="item.fantasyName">
                  <InfoTooltip
                    icon-size="1x"
                    :label="'Nome fantasia: ' + item.fantasyName"
                  />
                </span>
              </span>
              <span
                class="customer-name"
                v-else
              >{{ item.name }}
              </span>

              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <span
                    v-if="item.externalId"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="fas pl-1 fa-tag tag-external-id" />
                  </span>
                </template>
                Cliente importado com ID externo {{ item.externalId }}
              </v-tooltip>
            </template>
            <template #[`item.phone`]="{ item }">
              <div>
                Cel.: {{ item.cellphone }}
              </div>
              <div>
                Fixo: {{ item.landline }}
              </div>
            </template>
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | date }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu
                offset-y
                offset-overflow
                left
                slide-y
                @click.stop
              >
                <template #activator="{ on: menu }">
                  <v-tooltip top>
                    <template #activator="{ on: tooltip, attrs }">
                      <v-btn
                        icon
                        text
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        @click.stop
                        data-test="btn-acoes-cliente"
                      >
                        <i class="far fa-ellipsis-v" />
                      </v-btn>
                    </template>
                    <span>Opções</span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-list-item
                    @click="openCustomerComplentaryInfoModal(item)"
                    v-administrative-access
                    data-test="btn-atualizacao-cadastral"
                  >
                    <i class="far fa-envelope pr-2 menu-buttons" />
                    <v-list-item-title>Atualização Cadastral</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="editCustomer(item)"
                    data-test="btn-editar-cliente"
                  >
                    <i class="far fa-pencil pr-2 menu-buttons" />
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card class="card-cambio my-5">
                  <v-data-table
                    :headers="expandedColumns"
                    :items="item.exchangeBanks"
                    disable-sort
                    disable-pagination
                    disable-filtering
                    hide-default-footer
                    :items-per-page="-1"
                    :loading="expandedIsLoading"
                    :hide-default-header="expandedIsLoading"
                    loading-text="Carregando informações do cliente"
                    class="my-5"
                  >
                    <template #[`item.limitIn`]="{ item }">
                      <template v-if="item.limit.isInOut">
                        {{ item | formatLimit(item.limit.remainingIn) }}
                      </template>
                      <template v-else>
                        <v-icon class="hidden-sm-and-down">mdi-arrow-right-bottom</v-icon>
                        <i class="fas fa-long-arrow-down fa-lg hidden-md-and-up" />
                      </template>
                    </template>

                    <template #[`item.limit`]="{ item }">
                      <template v-if="!item.limit.isInOut">
                        {{ item | formatLimit(item.limit.remaining) }}
                      </template>
                    </template>

                    <template #[`item.limitOut`]="{ item }">
                      <template v-if="item.limit.isInOut">
                        {{ item | formatLimit(item.limit.remainingOut) }}
                      </template>
                      <template v-else>
                        <v-icon class="hidden-sm-and-down">mdi-arrow-left-bottom</v-icon>
                        <i class="fas fa-long-arrow-up fa-lg hidden-md-and-up" />
                      </template>
                    </template>

                    <template #[`item.operationNatures`]="{ item }">
                      <template v-for="(nature, i) in item.operationNatures">
                        <v-chip
                          small
                          label
                          :key="i"
                          class="ma-1"
                        >
                          {{ nature.exibitionName }}
                        </v-chip>
                      </template>
                    </template>

                    <template #[`item.status`]="{ item }">
                      <template v-if="new Date() < new Date(item.limit.expireAt)">
                        <v-chip
                          small
                          label
                          :color="'#24b529'"
                        >
                          Aprovado
                        </v-chip>
                      </template>
                      <template v-else>
                        <v-chip
                          small
                          label
                          :color="'#fc3f3f'"
                        >
                          Vencido
                        </v-chip>
                      </template>
                    </template>

                    <template #[`item.actions`]="{ item }">
                      <v-menu
                        offset-y
                        left
                        :close-on-content-click="false"
                      >
                        <template #activator="{ on: menu }">
                          <v-tooltip top>
                            <template #activator="{ on: tooltip, attrs }">
                              <v-btn
                                icon
                                text
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                                @click.stop
                                data-test="btn-acoes-cliente-expandido"
                              >
                                <i class="far fa-ellipsis-v" />
                              </v-btn>
                            </template>
                            <span>Opções</span>
                          </v-tooltip>
                        </template>

                        <v-list>
                          <v-list-item 
                            @click="createOperationFromBank(item)"
                            :disabled="!item.limit.expireAt || new Date() > new Date(item.limit.expireAt)"
                          >
                            <v-list-item-title>
                              <i class="far fa-hand-holding-usd pr-2 menu-buttons" /> Operar
                            </v-list-item-title>
                          </v-list-item>

                          <CustomerBankStatement
                            :customer="customer"
                            :exchange-bank="item"
                          />
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      width="500"
      v-model="modalPreForm"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <h3>Novo cliente</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3" />
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                validation
                v-model="preFormValid"
                ref="preForm"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-radio-group v-model="customer.registerType.id">
                      <v-radio
                        v-for="(item, i) in listOfRegisterTypes"
                        :key="i"
                        :value="item.id"
                        :label="item.description"
                        :rules="[requiredField]"
                        data-test="rd-tipo-cadastro"
                      />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="customer.name"
                      label="Nome"
                      autocomplete="off"
                      type="text"
                      dense
                      :rules="[requiredField]"
                      data-test="ipt-nome-cliente"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="customer.email"
                      label="E-mail"
                      autocomplete="off"
                      type="email"
                      dense
                      :rules="[requiredField, validationEmail]"
                      data-test="ipt-email-cliente"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-autocomplete
                      label="Natureza da Operação"
                      :items="operationNatureList"
                      item-text="exibitionName"
                      item-value="id"
                      v-model="customer.additional.operationNature"
                      return-object
                      clearable
                      dense
                      v-disabled-icon-focus
                      data-test="complete-natureza-operacao-cliente"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>

            <!-- For now we arent sending these -->
            <!-- <v-col cols="12" md="12">
              <v-autocomplete
                label="Bancos"
                :items="listOfBanks"
                v-model="customer.banks"
                item-value="id"
                item-text="description"
                return-object
                multiple
                chips
                dense
                clearable
              ></v-autocomplete>
            </v-col> -->
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                label="Corban"
                :items="correspondentsList"
                v-model="customer.correspondent"
                item-value="id"
                item-text="name"
                return-object
                dense
                clearable
                data-test="complete-corban"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-tertiary ma-2"
            @click="closeModalPreForm"
            data-test="btn-cancelar-pre-cadastro"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            class="btn-secondary ma-2"
            :disabled="!preFormValid"
            @click="sendPreForm"
            data-test="btn-enviar-pre-cadastro"
          >
            Enviar por email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-window
      v-model="openWindowConfirmation"
      :callback-primary="callbackConfirmDeletion"
      :message="deleteMessage"
      @onChange="clearInputs"
    />

    <!-- Modal de Informações Adicionais do Template -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="20vw"
      max-width="80vh"
      v-model="customerComplentaryInfoModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Informação complementar</h1>
        </v-card-title>
        <v-divider class="" />
        <v-card-text class="pt-8">
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              md="12"
            >
              <v-textarea
                auto-grow
                outlined
                rows="1"
                v-model="customerComplentaryInfo"
                label="Informações complementares"
                data-test="txt-info-complementar"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="requestRegisterFormUpdate()"
              data-test="btn-enviar-info-complementar"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="customerComplentaryInfoModal = false"
              data-test="btn-cancelar-info-complementar"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Apis
import CustomerApi from "@/api/customer/customer-api";
import OperationNatureApi from "@/api/configuration/operation-nature-api";
import CorbanApi from "@/api/correspondent/correspondent-api";
import TriggerApi from "@/api/generic/trigger-api";
import DashboardApi from '@/api/generic/dashboard-api';

// Models
import CustomerModel from "@/model/customer-model";
import OperationNatureModel from "@/model/operation-nature-model";
import CorrespondentModel from "@/model/correspondent-model";
import TriggerModel from "@/model/generic/trigger-model";
import CustomerFiltersModel from "@/model/customer/customer-filters-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Components
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";
import CustomerBankStatement from "@/components/form-customer/CustomerBankStatement.vue";
import InfoTooltip from "@/components/comum/InfoTooltip";

// Utils
import dateUtils from "../../../../common/utils/date";
import utilsStorage from "@/utils/storage";
import numberUtils from "../../../../common/utils/number";

// Constants
import {
  REGISTER_TYPE,
  BANKS,
} from "@/constants/general-constants";
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { CUSTOMER_WORKFLOW } from "../../../../common/workflows/customersWorkflow";
import { PROFILES } from '../../../../common/constants/generic/types';

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "CustomersControl",
  mixins: [mixinMessage],
  components: {
    ConfirmationWindow,
    InfoTooltip,
    CustomerBankStatement,
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatLimit(customerExchangeBank, value) {
      if (customerExchangeBank.bank.useUsdLimit) {
        return numberUtils.formatCurrency('USD', value);
      }

      return numberUtils.formatCurrency('BRL', value);
    },
  },
  inject: ['appData'],
  data() {
    return {
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      registerTypes: REGISTER_TYPE,
      correspondentsList: [],
      listOfBanks: BANKS,
      passwordVisible: "password",
      dialog: false,
      loading: true,
      updateMode: false,
      expandedIsLoading: false,
      disableButtons: false,
      openWindowConfirmation: false,
      deleteMessage: "",
      search: "",
      modalPreForm: false,
      user: utilsStorage.getUserOnStorage(),
      customer: new CustomerModel(),
      customers: [],
      validationEmail: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
        "Informe um e-mail válido",
      callbackConfirmDeletion: () => { },
      columns: [
        { text: "Código", value: "id", align: "left", sortable: true },
        { text: "CPF/CNPJ", value: "cpfCnpj", align: "left", sortable: true, },
        { text: "Nome / Razão Social", value: "customerNameOrCompanyName", align: "left", sortable: true },
        { text: "E-mail", value: "email", align: "left", sortable: true },
        { text: "Telefone", value: "phone", align: "left", sortable: true },
        { text: "Data de cadastro", value: "createdAt", align: "center", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      preFormValid: false,
      requiredField: (v) => !!v || "Este campo é obrigatório",
      expanded: [],
      expandedColumns: [
        { text: "Tipo", value: "exchangeType.description", align: "left", width: "10%" },
        { text: "Banco Vinculado", value: "bank.companyName", align: "left", width: "20%" },
        { text: "Limite Disponível Entrada", value: "limitIn", align: "center", width: "20%" },
        { text: "", value: "limit", align: "center", width: "5%" },
        { text: "Limite Disponível Saída", value: "limitOut", align: "center", width: "20%" },
        { text: "Naturezas Aprovadas", value: "operationNatures", align: "left", },
        { text: "Status", value: "status", align: "center", width: "10%" },
        { text: "", value: "actions", align: "center", width: "10%" },
      ],
      operationNatureList: [],
      customerComplentaryInfo: '',
      customerComplentaryInfoModal: false,
      selectedCustomer: new CustomerModel(),
      options: {},
      dataTableIsLoading: false,
      customerCount: 0,
      searchTimer: undefined,
      corbanApi: new CorbanApi(this.appData.currentUser),
      customerApi: new CustomerApi(this.appData.currentUser),
      dashboardApi: new DashboardApi(this.appData.currentUser),
      operationNatureApi: new OperationNatureApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
    };
  },
  mounted() {
    this.updateDataTableItems();
    mixpanel.track("page_view", {
      name_of_page_viewed: "users_control",
      user_id: this.user.id,
    });
    this.getOperationNatureList();
    this.getCorrespondentsList();
  },

  computed: {
    minimumDate() {
      return new Date().toISOString();
    },
  },

  watch: {
    expanded() {
      if (!this.expanded.length) {
        return;
      }

      let item = this.expanded[0];

      if (item.exchangeBanks?.length) {
        this.customer = item;

        return;
      }

      this.expandedIsLoading = true;
      this.customerApi
        .findId(item.id)
        .then((response) => {
          let customer = new CustomerModel(response.data);
          let index = this.customers.findIndex(i => i.id == item.id);
          this.customers.splice(index, 1, customer);
          this.customer = customer;
          this.expandedIsLoading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    options: {
      handler () {
        this.updateDataTableItems();
      },
      deep: true,
    },

    search(){
      clearTimeout(this.searchTimer);

      this.searchTimer = setTimeout(() => {
        this.updateDataTableItems();
      }, 500);
    },
  },

  methods: {
    getOperationNatureList() {
      this.operationNatureApi
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCorrespondentsList() {
      this.corbanApi
        .findAll()
        .then((response) => {
          this.correspondentsList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateDataTableItems() {
      let filters = new CustomerFiltersModel({
        registerStatusList: [CUSTOMER_WORKFLOW.CADASTRO_APROVADO.id],
        quantity: this.options.itemsPerPage || 10,
        page: this.options.page,
        sortBy: this.options.sortBy?.[0]?.replace('customerNameOrCompanyName', 'name'),
        sortDesc: this.options.sortDesc?.[0] ?? false,
        searchText: this.search
      });

      this.dataTableIsLoading = true;
      
      let promiseList = [];
      promiseList.push(this.getCustomerCount(filters));
      promiseList.push(this.getCustomersList(filters));

      Promise.all(promiseList)
        .then(() => {
          this.dataTableIsLoading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCustomersList(filters) {
      return this.customerApi
        .findAll(filters)
        .then((response) => {
          this.customers = response.data.map((c) => new CustomerModel(c));
          this.clearInputs();
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    getCustomerCount(filters) {
      return this.dashboardApi
        .getCustomerCount(filters)
        .then((response) => {
          this.customerCount = Number(response.data.count);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    addCustomers() {
      this.$router.push({
        name: "FormCreateCustomer",
      });
    },
    async openModalPreForm() {
      this.customer = new CustomerModel();
      this.$refs.preForm && (await this.$refs.preForm.reset());
      this.modalPreForm = true;
    },

    closeModalPreForm() {
      this.modalPreForm = false;
    },

    editCustomer(customer) {
      mixpanel.track("click", {
        button_name: "edit_customer",
      });
      this.$router
        .push({
          name: "FormEditCustomer",
          params: {
            id: customer.id,
          },
        })
        .catch(() => { });
    },

    cancel() {
      this.dialog = false;
      this.customer = new CustomerModel();
      this.updateMode = false;
    },

    clearInputs() {
      this.customer = new CustomerModel();
    },

    deleteCustomerConfirmationDialog(customer) {
      // TODO Adicionar tracking
      this.customer = new CustomerModel(customer);
      this.deleteMessage = `Deseja realmente inativar o cliente &nbsp; <strong>${customer.name}</strong>?`;
      this.callbackConfirmDeletion = this.deleteCustomer;
      this.openWindowConfirmation = true;
    },

    updateListCustomer(customer, deleteFromList) {
      var index = this.customers
        .map((u) => parseInt(u.id))
        .indexOf(parseInt(customer.id));
      !deleteFromList
        ? this.customers.splice(index, 1, customer)
        : this.customers.splice(index, 1);
    },

    sendPreForm() {
      mixpanel.track("click", {
        name_of_page_viewed: "users_control",
        btn_name: "pre_register_form_save",
      });

      this.$eventBus.$emit('show-loading', true);

      this.customer.setPersonType();

      if (this.customer.isPJ()) {
        this.customer.companyName = this.customer.name;
        this.customer.name = undefined;
      }

      this.customer.registerStatus = CUSTOMER_WORKFLOW.CADASTRO_ENVIADO;

      this.customer.sendExternalLink = true;
      this.customerApi
        .add(this.customer)
        .then(() => {
          this.sendMessage("Cliente salvo com sucesso!", "success");
          this.closeModalPreForm();
          this.navigateToCustomersAuthorizations();
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    createOperationFromBank(exchangeBank) {
      this.$router.push({
        name: 'FormOperation', query: {
          idExchangeType: exchangeBank.exchangeType.id,
          idCustomer: this.expanded[0].id,
          idBank: exchangeBank.bank.id,
        }
      });
    },

    requestRegisterFormUpdate() {
      this.$eventBus.$emit('show-loading', true);
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_ATUALIZACAO_FICHA_CADASTRAL,
        idCustomer: this.selectedCustomer.id,
        customerComplementaryInfo: this.customerComplentaryInfo,
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Solicitação de atualização cadastral enviada com sucesso!", "success");
          this.selectedCustomer = new CustomerModel();
          this.customerComplentaryInfo = '';
          this.customerComplentaryInfoModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    openCustomerComplentaryInfoModal(customer) {
      this.selectedCustomer = new CustomerModel(customer);
      this.customerComplentaryInfoModal = true;
    },

    navigateToCustomersAuthorizations() {
      if (this.appData.currentUser.profileId != PROFILES.CORBAN.id) {
        this.$router.push({ name: 'CustomersAuthorizationControl' });
      }
    }
  },
};
</script>

<style scoped lang="scss">
.menu-buttons {
  color: var(--v-primary-base);
}

::v-deep .v-data-table th,
::v-deep .v-data-table td {
  min-width: 100px;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

::v-deep .v-data-table th:nth-child(1),
::v-deep .v-data-table td:nth-child(1) {
  min-width: 50px;
  max-width: 50px;
}
</style>

