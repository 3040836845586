const CUSTOMER_FORM_TAB = {
  REGISTRATION_DATA: {
    key: 'dadosCadastrais',
    dataTest: 'tab-dados-cadastrais',
    label: 'Dados cadastrais',
    administrativeAcess: false,
    show: true
  },
  PARTNERS: {
    key: 'partners',
    dataTest: 'tab-partners',
    label: 'Sócios',
    administrativeAcess: true,
    show: false
  },
  BANK_CHANNELS: {
    key: 'canaisBancarios',
    dataTest: 'tab-canais-bancarios',
    label: 'Canais bancários',
    administrativeAcess: false,
    show: true
  },
  DOCUMENTS: {
    key: 'documentos',
    dataTest: 'tab-documentos',
    label: 'Documentos',
    administrativeAcess: false,
    show: true
  },
  LIMITS: {
    key: 'limites',
    dataTest: 'tab-limites',
    label: 'Limites',
    administrativeAcess: true,
    show: true
  },
  SPREADS: {
    key: 'spreads',
    dataTest: 'tab-spreads',
    label: 'Spreads',
    administrativeAcess: true,
    show: true
  }
};

const CUSTOMER_FORM_TABS = Object.values(CUSTOMER_FORM_TAB);

export {
  CUSTOMER_FORM_TAB,
  CUSTOMER_FORM_TABS
};
