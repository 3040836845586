<template>
  <v-row>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <MoneyField
        data-test="txt-patrimonio"
        label="Patrimônio"
        v-model="customer.additional.assets"
        :rules="[validationIsRequiredFieldNumeric]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-text-field
        data-test="txt-naturalidade"
        outlined
        dense
        label="Naturalidade"
        v-model="customer.birthplace"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="4"
    >
      <v-text-field
        data-test="txt-nome-pai"
        outlined
        dense
        label="Nome do pai"
        v-model="customer.fatherName"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="4"
    >
      <v-text-field
        data-test="txt-nome-mae"
        outlined
        dense
        label="Nome da mãe"
        v-model="customer.motherName"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
  </v-row>
</template>

<script>
// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Components
import MoneyField from '@/components/comum/MoneyField';
import { CUSTOMER_FORM_TAB } from "@/constants/customer-form-constants";

export default {
  name: 'PFOnlyCompleteRemittanceFields',
  mixins: [mixinValidationRules],
  components: { MoneyField },
  data() {
    return {
      required: [(v) => !!v || `Este campo é obrigatório`],
      customer: this.customerProp,
      listOfCountries: undefined,
      customerFormTab: CUSTOMER_FORM_TAB
    };
  },
  mounted() {},
  methods: {},
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  }
};
</script>

<style></style>
