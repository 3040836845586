import CommissionPaymentOrderDocumentModel from './commission-payment-order-document-model';
import CorrespondentModel from './correspondent-model';

export default class CommissionPaymentOrderModel {
  constructor(obj) {
    obj = obj || {};

    let documents = obj.documents?.map(d => new CommissionPaymentOrderDocumentModel(d));

    this.id = obj.id;
    this.agentId = obj.agentId;
    this.value = obj.value;
    this.requestDate = obj.requestDate;
    this.paymentDate = obj.paymentDate;
    this.documents = documents?.length ? documents : [];
    this.corban = new CorrespondentModel(obj.corban);
    this.brazilianAccount = obj.brazilianAccount;
    this.bank = obj.bank;
    this.name = obj.name;
    this.agency = obj.agency;
    this.account = obj.account;
    this.digit = obj.digit;
    this.pix = obj.pix;
    this.swiftCodeBIC = obj.swiftCodeBIC;
    this.numberIBAN = obj.numberIBAN;
    this.ownerName = obj.ownerName;
    this.tabNumberRouting = obj.tabNumberRouting;
    this.accountName = obj.accountName;
    this.forFurtherCredit = obj.forFurtherCredit;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}
