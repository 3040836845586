<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          min-width="100px"
          class="my-5"
        />
        <v-divider />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        md="12"
        v-if="!editMode"
      >
        <v-card class="box card-cambio">
          <h1>Tipo de formulário</h1>
          <v-divider />
          <v-spacer class="py-2" />
          <v-radio-group
            v-model="correspondent.personType.id"
            row
          >
            <v-radio
              v-for="(item, i) in listOfCorrespondentPersonTypes"
              :key="i"
              :value="item.id"
              :label="item.description"
            />
          </v-radio-group>
        </v-card>
      </v-col>
      <v-col
        md="12"
        v-if="correspondent.personType.id"
      >
        <v-form
          ref="form"
          v-model="valid"
        >
          <div>
            <CorrespondentCover
              v-model="correspondent"
              :is-international-agent="correspondent.isInternationalAgent()"
              :is-pj="correspondent.isPJ()"
            />
            <CorrespondentFinancial v-model="correspondent" />
            <CorrespondentInternationalBank v-model="correspondent" />
            <CorrespondentPartnersShareholders
              v-model="correspondent"
              v-if="correspondent.isPJ()"
            />
            <CorrespondentContactEmail v-model="correspondent" />
            <CorrespondentAdministrative
              v-model="correspondent"
              v-if="isAdministrativeAccess"
            />
            <CorrespondentSpreads
              v-model="correspondent"
              v-if="isAdministrativeAccess && !isIndicated"
            />
            <CorrespondentIndicated
              v-model="correspondent"
              v-if="isAdministrativeAccess && !isIndicated && editMode"
            />
          </div>
        </v-form>
      </v-col>
      <v-col md="12">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              @click="cancelCorrespondentCreation"
              class="btn-tertiary float-right"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn-primary mx-2 float-right"
              @click="!editMode ? save() : update()"
              text
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import CorrespondentCover from "../../components/form-correspondent/CorrespondentCover.vue";
import CorrespondentFinancial from "../../components/form-correspondent/CorrespondentFinancial.vue";
import CorrespondentContactEmail from "../../components/form-correspondent/CorrespondentContactEmail.vue";
import CorrespondentAdministrative from "../../components/form-correspondent/CorrespondentAdministrative.vue";
import CorrespondentPartnersShareholders from "../../components/form-correspondent/CorrespondentPartnersShareholders.vue";
import CorrespondentSpreads from "../../components/form-correspondent/CorrespondentSpreads.vue";
import CorrespondentIndicated from "../../components/form-correspondent/CorrespondentIndicated.vue";
import CorrespondentInternationalBank from "../../components/form-correspondent/CorrespondentInternationalBank.vue";

// Apis
import CorbanApi from "@/api/correspondent/correspondent-api";
import apiCorrespondentPersonType from "@/api/correspondent/correspondent-person-type-api";
import TriggerApi from "@/api/generic/trigger-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAuthorization from "@/mixin/mixin-authorization";
import mixinNestedFormValidation from "@/mixin/mixin-nested-form-validation";

// Models
import CorrespondentModel from "@/model/correspondent-model";
import CorrespondentPersonTypeModel from "@/model/correspondent-person-type-model";
import TriggerModel from "@/model/generic/trigger-model";

// Tracking
import mixpanel from "mixpanel-browser";

// Utils
import storage from "@/utils/storage.js";
import utils from "@/utils/general.js";

// Constants
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";

export default {
  name: "FormCorrespondent",
  mixins: [
    mixinMessage,
    mixinGeral,
    mixinValidationRules,
    mixinAuthorization,
    mixinNestedFormValidation,
  ],
  components: {
    CorrespondentCover,
    CorrespondentFinancial,
    CorrespondentContactEmail,
    CorrespondentAdministrative,
    CorrespondentPartnersShareholders,
    CorrespondentSpreads,
    CorrespondentIndicated,
    CorrespondentInternationalBank
  },
  props: {
    isIndicated: { type: Boolean, default: false },
    indicatorId: { type: [Number, String], default: '' },
    isUpdate: { type: Boolean, default: false },
    updateId: { type: [Number, String], default: '' },
    aferSaveCallback: { type: Function, default: () => { } },
    cancelCallback: { type: Function, default: () => { } },
  },
  inject: ['appData'],
  data() {
    return {
      loading: false,
      editMode: false,
      correspondent: new CorrespondentModel(),
      externalAcess: false,
      listOfCorrespondentPersonTypes: [],
      corbanApi: new CorbanApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
      valid: false,
    };
  },
  mounted() {
    this.loading = true;

    if (this.isIndicated) {
      if (this.isUpdate) {
        this.getCorrespondentById(this.updateId);
        this.editMode = true;
      } else {
        this.getCorrespondentPersonType();
        this.editMode = false;
      }
    } else if (this.$route.params.id) {
      this.getCorrespondentById(this.$route.params.id);
      this.editMode = true;
      mixpanel.track("page_view", {
        name_of_page_viewed: "correspondent_update_form",
      });
    } else if (this.$route.params.token) {
      storage.saveTokenOnStorage(this.$route.params.token);
      let user = utils.parseJwt(this.$route.params.token);

      this.corbanApi = new CorbanApi(user);
      this.triggerApi = new TriggerApi(user);

      this.getCorrespondentById(user.correspondentId);
      this.editMode = true;
      this.externalAcess = true;
      mixpanel.track("page_view", {
        name_of_page_viewed: "correspondent_external_acces",
      });
    } else {
      this.editMode = false;
      this.getCorrespondentPersonType();
      mixpanel.track("page_view", {
        name_of_page_viewed: "correspondent_create_form",
      });
    }
  },

  methods: {
    getCorrespondentById(id) {
      this.corbanApi
        .findId(id)
        .then((response) => {
          this.correspondent = new CorrespondentModel(response.data);
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    getCorrespondentPersonType() {
      apiCorrespondentPersonType
        .findAll()
        .then((response) => {
          this.listOfCorrespondentPersonTypes = response.data.map((c) => new CorrespondentPersonTypeModel(c));
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    save() {
      mixpanel.track("click", {
        button_name: "create_correspondent",
      });

      if (!this.isFormValid()) {
        return;
      }

      this.createCorrespondent();
    },
    update() {
      mixpanel.track("click", {
        button_name: "update_correspondent",
      });

      if (!this.isFormValid()) {
        return;
      }

      this.updateCorrespondent();
    },
    cancelCorrespondentCreation() {
      if (this.isIndicated) {
        this.cancelCallback();

        return;
      }

      mixpanel.track("click", {
        button_name: "cancel_correspondent_creation",
      });
      this.$router.push({
        name: "CorrespondentsControl",
      });
    },
    createCorrespondent() {
      if (this.isIndicated) {
        this.corbanApi
          .addIndicated(this.indicatorId, this.correspondent)
          .then((response) => {
            this.sendMessage("Salvo com sucesso!", "success");
            let newCorrespondent = new CorrespondentModel(response.data);
            this.sendUserAccessTrigger(newCorrespondent.id)
              .then(() => {
                this.aferSaveCallback(newCorrespondent);
              });
          })
          .catch((error) => {
            this.sendMessage(
              (error.response && error.response.data.mensagem) || error,
              "error"
            );
          });

        return;
      }

      this.corbanApi
        .add(this.correspondent)
        .then((response) => {
          this.sendMessage("Corban salvo com sucesso!", "success");

          let newCorrespondent = new CorrespondentModel(response.data);
          this.sendUserAccessTrigger(newCorrespondent.id);

          this.$router.push({
            name: "CorrespondentsControl",
          });
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    updateCorrespondent() {
      this.corbanApi
        .update(this.correspondent)
        .then(() => {
          this.sendMessage("Salvo com sucesso!", "success");

          if (this.isIndicated) {
            this.aferSaveCallback(this.correspondent);

            return;
          }

          if (this.externalAcess) {
            this.sendUserAccessTrigger(this.correspondent.id)
              .then(() => {
                storage.removeAuth();
                this.$router.push({
                  name: "Login",
                });
              });
          } else {
            this.$router.push({
              name: "CorrespondentsControl",
            });
          }
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    sendUserAccessTrigger(id) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CORBAN_CRIACAO_USUARIO,
        idCorban: id,
      });

      return this.triggerApi
        .trigger(trigger)
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    isFormValid() {
      this.reassignFormInputs(this.$refs.form);
      this.$refs.form.validate();

      if (!this.valid) {
        let invalidElement = this.$refs.form.inputs.find(i => i.valid == false);
        invalidElement.$el.scrollIntoView({ behavior: "smooth", block: "end" });

        if (this.isAdministrativeAccess) {
          this.sendMessage("Existem campos obrigatórios não preenchidos", "error");
        }

        return false;
      }

      return true;
    },
  },
};
</script>
