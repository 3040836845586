<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col
          cols="12"
          lg="3"
          sm="6"
          offset-lg="9"
          offset-sm="6"
          class="pa-0 pb-2"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            class="pt-0 mt-0"
            data-test="ipt-pesquisar"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          v-if="!localOperations.length"
          cols="12"
        >
          <EmptyTable
            title="Nenhum registro encontrado"
            icon="far fa-hand-holding-usd"
            subtitle="Não há operações em aberto para o dia de hoje."
            height="calc(100dvh - 359px)"
            data-test="msg-nenhum-registro-encontrado"
          />
        </v-col>
        <v-col
          v-else
          cols="12"
        >
          <v-card
            class="card-cambio pt-1"
            data-test="card-operacoes"
          >
            <v-data-table
              :headers="columns"
              :items="localOperations"
              :search="search"
              sort-by="id"
              sort-asc
              :items-per-page="paginationDefault"
              :custom-filter="customFilter"
              height="calc(100dvh - 370px)"
              item-key="sketchId"
              :expanded.sync="expanded"
              show-expand
              @click:row="toggleRowExpansion"
              fixed-header
              data-test="tabela-operacoes"
            >
              <template #[`item.exchangeType.description`]="{ item }">
                <template v-if="item.exchangeType?.id == 1 && item.exchangeBuy">
                  <span data-test="item-tipo-operacao-remessa-compra">Remessa de Compra</span>
                </template>
                <template v-else-if="item.exchangeType?.id == 1">
                  <span data-test="item-tipo-operacao-remessa-venda">Remessa de Venda</span>
                </template>
                <template v-else-if="item.exchangeType?.id == 2 && item.exchangeBuy">
                  <span data-test="item-tipo-operacao-compra-me">Compra de M.E.</span>
                </template>
                <template v-else>
                  <span data-test="item-tipo-operacao-venda-me">Venda de M.E.</span>
                </template>
              </template>

              <template #[`item.updatedAt`]="{ item }">
                <span data-test="item-ultimo-update">{{ item.updatedAt | date }}</span>
              </template>

              <template #[`item.customer.customerNameOrCompanyName`]="{ item }">
                <span
                  class="customer-name"
                  v-if="item.customer.isPJ()"
                  data-test="item-nome-cliente-pj"
                >
                  {{ item.customer.companyName }}
                </span>
                <span
                  class="customer-name"
                  v-else
                  data-test="item-nome-cliente-pf"
                >
                  {{ item.customer.name }}
                </span>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      @click="goToCustomer(item.customer.id)"
                      v-bind="attrs"
                      v-on="on"
                      data-test="btn-visualizar-cliente"
                    >
                      <i class="far fa-eye" />
                    </v-btn>
                  </template>
                  <span>Visualizar cliente</span>
                </v-tooltip>
              </template>

              <template #[`item.exchangeBuy`]="{ item }">
                <v-chip
                  v-if="item.exchangeBuy"
                  small
                  label
                  color="teal lighten-4"
                  data-test="chip-compra"
                >
                  Compra
                </v-chip>
                <v-chip
                  v-else
                  small
                  label
                  color="red lighten-4"
                  data-test="chip-venda"
                >
                  Venda
                </v-chip>
              </template>

              <template #[`item.amount`]="{ item }">
                <span data-test="item-valor">
                  {{ item.currency.code }} - {{ item.amount | formatCurrency(item.currency.code) }}
                </span>
              </template>

              <template #[`item.customerRate`]="{ item }">
                <span data-test="item-taxa-cliente">
                  {{ item.customerRate | formatCurrency('BRL', item.currency.precision) }}
                </span>
              </template>

              <template #[`item.totalValue`]="{ item }">
                <span data-test="item-valor-total">{{ item | formatCurrencyTotalValue }}</span>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu
                  offset-y
                  offset-overflow
                  left
                  slide-y
                  @click.stop
                  data-test="menu-opcoes"
                >
                  <template #activator="{ on: menu }">
                    <v-tooltip top>
                      <template #activator="{ on: tooltip, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                          @click.stop
                          data-test="btn-opcoes-menu"
                        >
                          <i class="far fa-ellipsis-v" />
                        </v-btn>
                      </template>
                      <span>Opções</span>
                    </v-tooltip>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="editOperationSketch(item)"
                      data-test="item-editar"
                    >
                      <v-list-item-title>
                        <i class="far fa-pencil pr-2 menu-buttons" />Editar
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="getBankMessage(item)"
                      data-test="item-copiar-simulacao-banco"
                    >
                      <v-list-item-title>
                        <i class="far fa-copy pr-2 menu-buttons" />
                        Copiar Simulação do Banco
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="getCustomerMessage(item)"
                      data-test="item-copiar-simulacao-cliente"
                    >
                      <v-list-item-title>
                        <i class="far fa-copy pr-2 menu-buttons" />
                        Copiar Simulação do Cliente
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="openDocumentControlModal(item)"
                      data-test="item-documentos"
                    >
                      <v-list-item-title>
                        <i class="far fa-file-pdf pr-2 menu-buttons" />
                        Documentos
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="openFinishOperationDialog(item)"
                      v-administrative-access
                      data-test="item-concluir-operacao"
                    >
                      <v-list-item-title>
                        <i class="far fa-check pr-2 menu-buttons" />
                        Concluir
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template #expanded-item="{ item }">
                <td
                  :colspan="columns.length + 1"
                  class="px-1 px-md-4"
                >
                  <v-card
                    class="box card-cambio my-5"
                    data-test="card-expansao"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="6"
                        md="1"
                        class="d-flex flex-column pr-2"
                        data-test="col-numero-op"
                      >
                        <span class="expand-title">N° da OP</span>
                        <span class="expand-value">{{ item.sketchId }}</span>
                      </v-col>

                      <v-col
                        cols="6"
                        md="1"
                        class="d-flex flex-column pl-2"
                        data-test="col-spread"
                      >
                        <span class="expand-title">Spread</span>
                        <span class="expand-value">{{ item | formatSpread }}</span>
                      </v-col>

                      <v-col
                        cols="6"
                        md="1"
                        class="d-flex flex-column pr-2 pl-md-2 pt-2 pt-md-0"
                        data-test="col-iof"
                      >
                        <span class="expand-title">IOF</span>
                        <p class="expand-value">
                          {{ item.iofValue | formatCurrency('BRL') }} ({{
                            item.iof | formatNumber
                          }}%)
                        </p>
                      </v-col>

                      <v-col
                        cols="6"
                        md="1"
                        class="d-flex flex-column pl-2 pt-2 pt-md-0"
                        data-test="col-ir"
                      >
                        <span class="expand-title">IR</span>
                        <p class="expand-value">
                          {{ item.irValue | formatCurrency('BRL') }} ({{ item.ir | formatNumber }}%)
                        </p>
                      </v-col>

                      <v-col
                        cols="6"
                        md="1"
                        class="d-flex flex-column pt-2 pt-md-0"
                        data-test="col-tarifa"
                      >
                        <span class="expand-title">Tarifa</span>
                        <p class="expand-value">
                          {{ item.exchangeContractCostBrl | formatCurrency('BRL') }}
                        </p>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                        class="d-flex flex-column pt-2 pt-md-0"
                        data-test="col-agente"
                      >
                        <span class="expand-title">Agente</span>
                        <p class="expand-value">{{ item.agent.name }}</p>
                      </v-col>

                      <v-col
                        cols="6"
                        md="1"
                        class="d-flex flex-column pr-2 pt-2 pt-md-0"
                        data-test="col-spot-previsto"
                      >
                        <span class="expand-title">SPOT Previsto</span>
                        <span class="expand-value">{{ item | formatCurrencyQuotation }}</span>
                      </v-col>

                      <v-col
                        data-test="col-spot-atual"
                        cols="6"
                        md="1"
                        class="pl-2 pt-2 pt-md-0"
                      >
                        <v-row no-gutters>
                          <v-col
                            class="d-flex flex-column"
                            data-test="col-spot-atual-valor"
                          >
                            <span class="expand-title">SPOT Atual</span>
                            <span class="expand-value">{{
                              item | formatActualCurrencyQuotation
                            }}</span>
                          </v-col>
                        </v-row>

                        <v-row no-gutters>
                          <v-col>
                            <span
                              class="texto-atualizando"
                              data-test="texto-atualizando"
                            >
                              {{ `Atualizando em ${item.counterCurrencyQuotation}s` }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col
                        cols="6"
                        md="1"
                        class="d-flex flex-column pr-2 pt-2 pt-md-0"
                        data-test="col-spread-atual"
                      >
                        <span class="expand-title">Spread Atual</span>
                        <span
                          :class="
                            getActualSpread(item) >= item.spread
                              ? 'expand-value-green'
                              : 'expand-value-red'
                          "
                          data-test="valor-spread-atual"
                        >
                          {{ getActualSpread(item) | formatActualSpread }}
                        </span>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                        class="d-flex flex-column py-2 py-md-0"
                        data-test="col-natureza"
                      >
                        <span class="expand-title">Natureza</span>
                        <span>{{ item.nature.exibitionName }}</span>
                      </v-col>

                      <v-col
                        cols="12"
                        data-test="col-conta-bancaria"
                      >
                        <BankAccount
                          :account="item.account"
                          :is-international="!item.exchangeBuy"
                          data-test="conta-bancaria"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Close Operation Dialog -->
    <BaseModal
      v-model="finishOperationDialog"
      title="Métodos de pagamento"
      modal-width="500px"
      max-height-body="380px"
      :primary-button-action="finishOperation"
      :secondary-button-action="() => (finishOperationDialog = false)"
      :fullscreen="appData.isMobile"
    >
      <v-form
        id="add-method"
        ref="form"
      >
        <v-row>
          <v-col
            class="text-left"
            cols="6"
          >
            <span class="color-text-dark">COTAÇÃO</span>
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <span class="color-text-dark">{{ operation | formatCurrencyQuotation }}</span>
          </v-col>
          <v-col
            class="text-left"
            cols="6"
          >
            <span class="color-text-dark">M.E.</span>
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <span class="color-text-dark">{{ operation.currency.name }}</span>
          </v-col>
          <v-col
            class="text-left"
            cols="6"
          >
            <span class="color-text-dark">VALOR M.E.</span>
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <span class="color-text-dark">{{ operation | formatCurrencyAmount }}</span>
          </v-col>
          <v-col
            class="text-left"
            cols="6"
          >
            <span class="color-text-dark">TAXA DO CLIENTE</span>
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <span class="color-text-dark">{{ operation | formatCurrencyCustomerRate }}</span>
          </v-col>
          <v-col
            class="text-left"
            cols="6"
          >
            <span class="color-text-dark">VET</span>
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <span class="color-text-dark">{{ operation | formatVet }}</span>
          </v-col>
          <v-col
            class="text-left"
            cols="6"
          >
            <span class="color-text-dark">TOTAL</span>
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <span class="color-text-dark">{{ operation | formatCurrencyTotalValue }}</span>
          </v-col>
          <v-col cols="12">
            <MoneyField
              label="Taxa do Banco"
              prefix="R$ "
              v-model="operation.bankRate"
              autofocus
              :precision="operation.currency.precision"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
        </v-row>
      </v-form>
    </BaseModal>

    <OperationalDocumentsModal
      v-model="documentControlModal"
      :operation="operation"
      :customer-id="customerId"
      :document-post-save-callback="() => {}"
      @onDelete="deleteDocument"
      @onSelectDocument="onSelectDocument"
    />
  </v-row>
</template>

<script>
// Api
import OperationApi from '@/api/operation/operation-api';
import SketchApi from '@/api/general/sketch-api';
import TriggerApi from '@/api/generic/trigger-api';
import RateApi from '@/api/general/rate-api';
import OperationDocumentApi from '@/api/operation/operation-documents-api';

// Models
import OperationModel from '@/model/operation-model';
import TriggerModel from '@/model/generic/trigger-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinDataTableFilters from '@/mixin/mixin-data-table-filters';
import mixinExpandedDataTable from '@/mixin/mixin-data-table-expanded';
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Utils
import dateUtils from '../../../../common/utils/date';
import numberUtils from '../../../../common/utils/number';
import operationMessagesTemplate from '@/utils/operationMessagesTemplate';
import { customFilterDataTable } from '@/utils/dataTable';

// Constants
import { OPERATION_TYPE } from '../../../../common/constants/generic/types';
import { SEND_EMAIL_TRIGGER_CODES } from '../../../../common/constants/generic/triggers';
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';

// Components
import MoneyField from '@/components/comum/MoneyField.vue';
import EmptyTable from '@/components/comum/EmptyTable.vue';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';
import BankAccount from '../../components/form-operation/OperationBankAccount.vue';
import BaseModal from '../../components/comum/BaseModal.vue';
import OperationalDocumentsModal from '../../components/form-operation/OperationalDocumentsModal.vue';

// Mixpanel
import mixpanel from 'mixpanel-browser';
export default {
  name: 'OpenOperationsReport',
  mixins: [mixinMessage, mixinDataTableFilters, mixinExpandedDataTable, mixinValidationRules],
  components: { MoneyField, EmptyTable, BankAccount, BaseModal, OperationalDocumentsModal },
  inject: ['appData'],
  props: {
    operations: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      search: '',
      localOperations: [],
      columns: [
        {
          text: 'Tipo',
          value: 'exchangeType.description',
          align: 'left',
          sortable: true,
          width: '180px'
        },
        {
          text: 'Data',
          value: 'updatedAt',
          align: 'left',
          ortable: true,
          width: '180px'
        },
        {
          text: 'Cpf/Cnpj',
          value: 'customer.cpfCnpj',
          align: 'left',
          sortable: true,
          width: '160px'
        },
        {
          text: 'Cliente / Razão social',
          value: 'customer.customerNameOrCompanyName',
          align: 'left',
          sortable: true
        },
        {
          text: 'Banco',
          value: 'bank.fantasyName',
          align: 'left',
          sortable: true
        },
        {
          text: 'Tipo',
          value: 'exchangeBuy',
          align: 'center',
          sortable: true,
          width: '90px'
        },
        {
          text: 'Liquidação',
          value: 'liquidation.description',
          align: 'center',
          sortable: true,
          width: '180px'
        },
        {
          text: 'Valor M.E.',
          value: 'amount',
          align: 'left',
          sortable: true,
          width: '180px'
        },
        {
          text: 'Tx. cliente',
          value: 'customerRate',
          align: 'left',
          sortable: true,
          width: '120px'
        },
        {
          text: 'Valor Total',
          value: 'totalValue',
          align: 'left',
          sortable: true,
          width: '120px'
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
          width: '10px'
        }
      ],
      finishOperationDialog: false,
      operation: new OperationModel(),
      triggerApi: new TriggerApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT,
      sketchApi: new SketchApi(this.appData.currentUser),
      rateApi: new RateApi(this.appData.currentUser),
      operationApi: new OperationApi(this.appData.currentUser),
      operationDocumentApi: new OperationDocumentApi(this.appData.currentUser),
      documentControlModal: false,
      customerId: ''
    };
  },
  watch: {
    operations() {
      let operations = this.operations;
      operations.forEach((item) => (item.actualCurrencyQuotation = item.currencyQuotation));

      this.localOperations = operations;
    }
  },
  mounted() {
    let operations = this.operations;
    operations.forEach((item) => (item.actualCurrencyQuotation = item.currencyQuotation));

    this.localOperations = operations;
  },
  beforeDestroy() {
    this.localOperations.forEach((item) => {
      if (item.intervalCurrencyQuotation) {
        this.stopGetCurrengyQuotation(item);
      }
    });
  },
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR').format(value);
    },
    date(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
    formatCurrency(value, code, precison) {
      code = code || 'USD';

      return numberUtils.formatCurrency(code, value, precison);
    },
    formatCurrencyAmount(operation) {
      let code = operation.currency.code || 'USD';

      return numberUtils.formatCurrency(code, operation.amount);
    },
    formatCurrencyTotalValue(operation) {
      return numberUtils.formatCurrency('BRL', operation.totalValue);
    },
    formatCurrencyCustomerRate(operation) {
      return numberUtils.formatCurrency(
        'BRL',
        operation.customerRate,
        operation.currency.precision
      );
    },
    formatVet(operation) {
      return numberUtils.formatCurrency('BRL', operation.vet, operation.currency.precision);
    },
    formatCurrencyQuotation(operation) {
      return numberUtils.formatCurrency(
        'BRL',
        operation.currencyQuotation,
        operation.currency.precision
      );
    },
    formatSpread(operation) {
      return numberUtils.formatNumber(operation.spread, 2) + '%';
    },
    formatActualCurrencyQuotation(item) {
      return numberUtils.formatCurrency(
        'BRL',
        item.actualCurrencyQuotation,
        item.currency.precision
      );
    },
    formatActualSpread(value) {
      return value.replace('.', ',') + '%';
    }
  },
  methods: {
    getBankMessage(operation) {
      navigator.clipboard.writeText(operationMessagesTemplate.getBankMessage(operation));
    },

    getCustomerMessage(operation) {
      navigator.clipboard.writeText(operationMessagesTemplate.getCustomerMessage(operation));
    },

    openFinishOperationDialog(item) {
      this.operation = item;
      this.finishOperationDialog = true;
    },

    finishOperation() {
      if (!this.$refs.form.validate()) return;

      this.operation.status = OPERATION_WORKFLOW.EM_ANALISE;
      this.operation.effectiveOperationDate = new Date().toISOString();

      this.operation.documents.forEach((item) => {
        item.document = this.convertBase64ToFile(item.document, item.fileName);
      });

      this.$eventBus.$emit('show-loading', true);
      this.operationApi
        .add(this.operation)
        .then((response) => {
          this.saveDocuments(this.operation.documents, response.data);
        })
        .catch((error) => {
          this.$eventBus.$emit('show-loading', false);
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    editOperationSketch(operation) {
      this.$router
        .push({
          name: 'FormEditOperation',
          params: {
            id: operation.sketchId
          }
        })
        .catch(() => {});
    },
    operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_FECHAMENTO,
        idOperation: operation.id,
        idCustomer: operation.customer.id,
        documentIds: documentIds,
        customerOperationalDocumentIds: customerOperationalDocumentIds
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {})
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    operationIndicationAcknowledgementTrigger(operation) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_AGRADECIMENTO_INDICACAO,
        idOperation: operation.id
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {})
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    customFilter(value, search, item) {
      const getExchangeType = (item) => {
        if (item.exchangeType?.id == 1 && item.exchangeBuy) return 'Remessa de Compra';

        if (item.exchangeType?.id == 1) return 'Remessa de Venda';

        if (item.exchangeType?.id == 2 && item.exchangeBuy) return 'Compra de M.E.';

        return 'Venda de M.E.';
      };

      let customFields = [
        { name: 'currency.name' },
        { name: 'updatedAt' },
        { name: 'bank.fantasyName' },
        { name: 'nature.exibitionName' }
      ];

      customFields.push({
        name: 'customer',
        value: (item) => (item.customer.isPJ() ? item.customer.companyName : item.customer.name)
      });

      customFields.push({
        name: 'exchangeType.description',
        value: (item) => getExchangeType(item)
      });

      customFields.push({
        name: 'amount',
        value: (item) => item.amount.toString()
      });

      customFields.push({
        name: 'customerRate',
        value: (item) => item.customerRate.toString()
      });

      customFields.push({
        name: 'totalValue',
        value: (item) => item.totalValue.toString()
      });

      return customFilterDataTable(value, search, item, customFields);
    },

    getActualSpread(item) {
      if (item.exchangeBuy) {
        let difference = Number((item.actualCurrencyQuotation - item.customerRate).toFixed(6));

        return ((difference / item.customerRate) * 100).toFixed(2);
      }

      let difference = Number((item.customerRate - item.actualCurrencyQuotation).toFixed(6));

      return ((difference / item.actualCurrencyQuotation) * 100).toFixed(2);
    },

    getCurrencyQuotation(item) {
      this.rateApi.findCommercial({ currencies: item.currency.code }).then((response) => {
        item.actualCurrencyQuotation = response.data[0].ask;
      });
    },

    startGetCurrengyQuotation(item) {
      const ELEVEN_SECONDS_IN_MILLISECONDS = 11000;
      const ONE_SECOND_IN_MILLISECONDS = 1000;

      if (!item.intervalCounterCurrencyQuotation) {
        item.intervalCurrencyQuotation = setInterval(() => {
          this.getCurrencyQuotation(item);
        }, ELEVEN_SECONDS_IN_MILLISECONDS);
      }

      if (!item.intervalCounterCurrencyQuotation) {
        item.intervalCounterCurrencyQuotation = setInterval(() => {
          if (item.counterCurrencyQuotation > 0) item.counterCurrencyQuotation--;
          else item.counterCurrencyQuotation = 10;
        }, ONE_SECOND_IN_MILLISECONDS);
      }
    },

    stopGetCurrengyQuotation(item) {
      clearInterval(item.intervalCurrencyQuotation);
      clearInterval(item.intervalCounterCurrencyQuotation);
      item.intervalCurrencyQuotation = null;
      item.intervalCounterCurrencyQuotation = null;
      item.counterCurrencyQuotation = 10;
    },

    toggleRowExpansion(item, slot) {
      this.clickRow(item, slot);

      if (!slot.isExpanded) {
        this.getCurrencyQuotation(item);
        this.startGetCurrengyQuotation(item);

        return;
      }

      this.stopGetCurrengyQuotation(item);
    },

    goToCustomer(id) {
      mixpanel.track('click', {
        button_name: 'edit_customer'
      });
      this.$router
        .push({
          name: 'FormEditCustomer',
          params: {
            id: id
          }
        })
        .catch(() => {});
    },

    convertBase64ToFile(base64String, fileName) {
      const [prefix, base64Data] = (base64String || '').split(',');
      const byteString = atob(base64Data);

      const byteNumbers = new Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteNumbers[i] = byteString.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const mimeType = prefix.match(/:(.*?);/)[1];

      const blob = new Blob([byteArray], { type: mimeType });

      return new File([blob], fileName, { type: mimeType });
    },

    saveDocuments(operationDocuments, operation) {
      let documentPromisseList = [];

      operationDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append('operationId', operation.id);
        formData.append('idOperationDocumentType', document.operationDocumentType?.id);
        formData.append('document', document.document);

        documentPromisseList.push(
          this.operationDocumentApi.add(formData).then((response) => {
            return response.data;
          })
        );
      });

      Promise.all(documentPromisseList)
        .then((itens) => {
          let documentIds = itens.map((d) => d.id);
          let customerOperationalDocumentIds = this.operation.customerOperationalDocuments.map(
            (d) => d.id
          );

          if (this.operation.exchangeType.id == OPERATION_TYPE.REMMITANCE.id) {
            this.operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds);
            this.operationIndicationAcknowledgementTrigger(operation);
          }

          let index = this.localOperations.findIndex((o) => o.sketchId == this.operation.sketchId);
          this.sketchApi.remove(this.operation.sketchId);
          this.localOperations.splice(index, 1);
          this.operation = new OperationModel();

          this.finishOperationDialog = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    openDocumentControlModal(item) {
      this.operation = item;
      this.documentControlModal = true;
    },

    async onSelectDocument(file, type) {
      if (!file?.name) return;

      const document = {
        operationDocumentType: type,
        fileName: file.name,
        document: await this.convertFileToBase64(file)
      };
      
      const operationIndex = this.localOperations.findIndex(
        (o) => o.sketchId === this.operation.sketchId
      );

      if (operationIndex === -1) return;

      this.localOperations[operationIndex].documents.push(document);

      this.updateOperation(this.localOperations[operationIndex]);
    },

    async deleteDocument(document) {
      const operationIndex = this.localOperations.findIndex(
        (o) => o.sketchId === this.operation.sketchId
      );

      if (operationIndex === -1) return;

      const documentIndex = this.localOperations[operationIndex].documents.findIndex(
        (d) => d.operationDocumentType.id === document.operationDocumentType.id
      );
      documentIndex !== -1 &&
        this.localOperations[operationIndex].documents.splice(documentIndex, 1);

      this.updateOperation(this.localOperations[operationIndex]);
    },

    async updateOperation(operation) {
      let sketch = await this.getSketchById(operation.sketchId);

      if (!sketch?.id) return;

      await this.updateSketch(sketch, operation);
    },

    async getSketchById(id) {
      this.$eventBus.$emit('show-loading', true);
      try {
        const response = await this.sketchApi.findId(id);

        return response.data;
      } catch (error) {
        this.sendMessage(error.response?.data?.mensagem || error, 'error');

        return;
      } finally {
        this.$eventBus.$emit('show-loading', false);
      }
    },

    async updateSketch(sketch, operation) {
      sketch.content = JSON.stringify(operation);

      this.$eventBus.$emit('show-loading', true);
      try {
        await this.sketchApi.update(sketch.id, sketch);
      } catch (error) {
        this.sendMessage(error.response?.data?.mensagem || error, 'error');
      } finally {
        this.$eventBus.$emit('show-loading', false);
      }
    },

    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#add-method {
  color: black;
}

.theme--dark .color-text-dark {
  color: white;
}

.menu-buttons {
  color: var(--v-primary-base);
}

.texto-atualizando {
  font-size: 12px;
  color: $red;
}

::v-deep .v-data-table th,
::v-deep .v-data-table td {
  min-width: 50px;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
