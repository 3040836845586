<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <v-row :key="formKey">
          <v-col
            cols="12"
            md="12"
          >
            <h1 data-test="titulo-dados-entrega">Dados de Entrega</h1>
            <v-divider data-test="divider-dados-entrega" />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <AddressComponent
              v-model="operation"
              :allow-international="false"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              data-test="input-nome-recebedor"
              v-model="operation.receiverName"
              outlined
              dense
              label="Nome do Recebedor"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              data-test="input-documento-recebedor"
              v-model="operation.receiverDocument"
              outlined
              dense
              label="Documento do Recebedor"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <PhoneField
              v-model="operation.receiverCellphone"
              label="Celular do Recebedor"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-btn
              data-test="botao-copiar-cliente"
              class="btn-primary"
              text
              @click="copyFromCustomer"
            >
              <i class="far fa-copy" />
              Copiar do Cliente
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import AddressComponent from '@/components/comum/AddressComponent';
import PhoneField from '@/components/comum/PhoneField.vue';

// Api
import CustomerApi from '@/api/customer/customer-api';

// Models
import CustomerModel from '@/model/customer-model';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';
import mixinAuthorization from '@/mixin/mixin-authorization';
import mixinMessage from '@/mixin/mixin-message';

export default {
  name: 'OperationExchangeDeliveryAddress',
  mixins: [mixinValidationRules, mixinAuthorization, mixinMessage],
  components: { AddressComponent, PhoneField },
  inject: ['appData'],
  data() {
    return {
      operation: this.operationProp,
      customer: new CustomerModel(),
      formKey: 0,
      customerApi: new CustomerApi(this.appData.currentUser)
    };
  },
  model: {
    prop: 'operationProp',
    event: 'onChange'
  },
  props: {
    operationProp: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'operation.customer'() {
      if (this.operation.customer == undefined) {
        this.customer = new CustomerModel();

        return;
      }
    }
  },
  mounted() {
    this.getCustomerById(this.operation.customer.id);
  },
  computed: {},
  methods: {
    copyFromCustomer() {
      if (this.operation.customer.name) {
        this.operation.receiverName = this.operation.customer.name;
      } else {
        this.operation.receiverName = this.operation.customer.companyName;
      }

      this.operation.receiverDocument = this.operation.customer.cpfCnpj;
      this.operation.receiverCellphone = this.operation.customer.cellphone;
      this.formKey = this.formKey + 1;
    },

    getCustomerById(id) {
      this.customerApi
        .findId(id)
        .then((response) => {
          this.customer = new CustomerModel(response.data);
          let deliveryAddress = this.customer.addresses.find((a) =>
            a.addressType.find((t) => t.id == 2)
          );

          this.operation.cep = deliveryAddress.cep;
          this.operation.state = deliveryAddress.state;
          this.operation.city = deliveryAddress.city;
          this.operation.neighborhood = deliveryAddress.neighborhood;
          this.operation.street = deliveryAddress.street;
          this.operation.number = deliveryAddress.number;
          this.operation.complement = deliveryAddress.complement;
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    }
  }
};
</script>

<style></style>
