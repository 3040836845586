<template>
  <v-row
    :id="id"
    class="py-3"
    @drop.prevent="dragAndDrop($event)"
    @dragover.prevent
  >
    <v-col
      v-if="showInput"
      class="py-0"
      cols="12"
      md="12"
    >
      <!-- O @click.stop serve para parar propagação do evento de expansão da tabela. -->
      <!-- Usando @mouseenter.native e @mouseleave.native pois o v-file-input apenas emite eventos de focus e hover.
      Usamos ele para exibir o tooltip.   -->
      <!-- Estamos usando a div para encapsular o v-input pois o vue se perde na hora de renderizar o componente pós upload. -->
      <div>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-file-input
              @mouseenter.native="on.mouseenter"
              @mouseleave.native="on.mouseleave"
              outlined
              dense
              prepend-icon="far fa-file-upload"
              v-model="document.document"
              v-disabled-icon-focus
              @click.stop
              hide-input
            />
          </template>
          <span>Adicionar {{ downloadFileName }}</span>
        </v-tooltip>
      </div>
    </v-col>
    <v-col
      v-else
      class="py-0"
      md="12"
    >
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            text
            @click="downloadDocument()"
            v-bind="attrs"
            v-on="on"
            class="pt-1"
            @click.stop
          >
            <i
              class="far fa-file-download"
              aria-hidden="true"
            />
          </v-btn>
        </template>
        <span>Baixar {{ downloadFileName }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
// Mixins
import mixinMessage from '@/mixin/mixin-message';

export default {
  name: 'DataTableDocumentInput',
  mixins: [mixinMessage],
  components: {},
  model: {
    prop: 'input',
    event: 'onChange'
  },
  props: {
    id: { type: String, required: false },
    input: { type: Object, required: true },
    downloadFileName: { type: String, required: false, default: 'anexo' },
    callbackAddDocument: { type: Function, required: true },
    callbackDownloadDocument: { type: Function, required: true }
  },

  data() {
    return {
      document: { ...this.input }
    };
  },

  watch: {
    'document.document'() {
      this.uploadDocument();
    }
  },

  methods: {
    downloadDocument() {
      this.callbackDownloadDocument(this.document)
        .then((response) => {
          let blob = new Blob([response.data], { type: response.data.type });
          this.createDownloadLink(blob);

          this.sendMessage(`Baixando ${this.downloadFileName}`, 'success');
        })
        .catch(() => {
          this.sendMessage('Falha ao baixar o anexo!', 'error');
        });
    },

    createDownloadLink(file) {
      let link = document.createElement('a');

      link.href = URL.createObjectURL(file);
      link.setAttribute('download', `${this.downloadFileName}`);
      link.click();

      URL.revokeObjectURL(link.href);
    },

    dragAndDrop(e) {
      if (!this.showInput || e.dataTransfer.files.length == 0) {
        return;
      }

      if (e.dataTransfer.files.length > 1) {
        this.sendMessage('É possível adicionar apenas um anexo por vez!', 'error');
      }

      this.uploadDocument(e.dataTransfer.files[0]);
    },

    checkFileSizeAndShowErrorMessage() {
      const KB = 1024;
      const MB = KB * KB;
      const TEN_MB_MAX_FILE_SIZE = 10 * MB;

      if (this.document.document.size > TEN_MB_MAX_FILE_SIZE) {
        this.sendMessage(`O arquivo não pode ter mais que 10MB!`, 'error');
        this.document.document = undefined;
      }
    },

    uploadDocument(document) {
      if (!document && !this.document.document) {
        this.$emit('onChange', this.document);

        return;
      }

      this.document.document = document || this.document.document;

      this.checkFileSizeAndShowErrorMessage();

      if (!this.document.document) return;

      this.callbackAddDocument(this.document)
        .then((added) => {
          this.document = added;
          this.$emit('onChange', this.document);
          this.sendMessage('Documento adicionado com sucesso!', 'success');
        })
        .catch(() => {
          this.sendMessage('Erro ao adicionar o anexo!', 'error');
        });
    }
  },

  computed: {
    showInput() {
      return !(
        (this.document.document || this.document.id) &&
        !(this.document.document && this.document.id)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .fa-file-upload {
  color: var(--v-error-base);
}

::v-deep .v-input__prepend-outer {
  align-items: center;
  justify-self: center;
  padding: 0;
  margin: 0 auto;
}

.v-btn__content > i {
  color: var(--v-success-base);
}

.v-input__prepend-outer > button::before.v-icon {
  fill: green;
}
</style>
