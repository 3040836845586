import BaseApi from '../baseApi';

export default class RateApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  findCommercial(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/rates/commercial/', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findPtax(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/rates/ptax/', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
