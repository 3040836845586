<template>
  <div>
    <ConfirmationWindow
      v-model="openConfirmationWindow"
      :callback-primary="confirmationWindowCallback"
      :message="confimationWindowMessage"
    />

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="9"
        sm="8"
      >
        <v-btn
          class="btn-primary ml-0"
          text
          @click="creditOperations"
        >
          <i class="far fa-plus" />
          Creditar comissões do mês anterior
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="4"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <v-card class="card-cambio">
          <v-card-text class="pa-5">
            <v-row>
              <v-col cols="12">
                <v-tabs
                  v-model="currentTab"
                  data-test="tabs"
                >
                  <v-tab
                    key="correspondentes"
                    data-test="tab-correspondentes"
                  >
                    <span>Correspondentes</span>
                  </v-tab>
                  <v-tab
                    key="solicitacoesEmAberto"
                    data-test="tab-solicitacoes-em-aberto"
                  >
                    <span>Solicitações em aberto</span>
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-tabs-items v-model="currentTab">
                  <v-tab-item key="correspondentes">
                    <v-card class="card-cambio">
                      <v-data-table
                        :headers="columns"
                        :items="correspondents"
                        :search="search"
                        sort-by="id"
                        sort-desc
                        :items-per-page="paginationDefault"
                        single-expand
                        :expanded.sync="expandedCorrespondents"
                        item-key="id"
                        show-expand
                        height="calc(100dvh - 410px)"
                        fixed-header
                      >
                        <template #expanded-item="{ headers }">
                          <td :colspan="headers.length">
                            <v-card class="box card-cambio my-5">
                              <v-data-table
                                :headers="expandedColumns"
                                :items="accounts"
                                disable-pagination
                                disable-filtering
                                hide-default-footer
                                :items-per-page="-1"
                                :loading="expandedIsLoading"
                                :hide-default-header="expandedIsLoading"
                                loading-text="Carregando informações das contas"
                                sort-by="agent.type"
                                sort-asc
                              >
                                <template #[`item.balance`]="{ item }">
                                  {{ item.balance | formatCurrency }}
                                </template>

                                <template #[`item.lastPayment`]="{ item }">
                                  {{ item.lastPayment | date }}
                                </template>

                                <template #[`item.lastCredit`]="{ item }">
                                  {{ item.lastCredit | date }}
                                </template>

                                <template #[`item.actions`]="{ item }">
                                  <template>
                                    <v-menu
                                      offset-y
                                      left
                                    >
                                      <template #activator="{ on: menu }">
                                        <v-tooltip top>
                                          <template #activator="{ on: tooltip, attrs }">
                                            <v-btn
                                              icon
                                              text
                                              v-bind="attrs"
                                              v-on="{ ...tooltip, ...menu }"
                                              @click.stop
                                            >
                                              <i class="far fa-ellipsis-v" />
                                            </v-btn>
                                          </template>
                                          <span>Opções</span>
                                        </v-tooltip>
                                      </template>

                                      <v-list>
                                        <v-list-item @click="openModalCreditStatement(item)">
                                          <i class="far fa-file-export pr-2 menu-buttons" />
                                          <v-list-item-title>
                                            Extrato de Movimentação
                                          </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click="openModalCreatePaymentOrder(item)">
                                          <i class="far fa-hand-holding-usd pr-2 menu-buttons" />
                                          <v-list-item-title>Adicionar Pagamento</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </template>
                                </template>
                              </v-data-table>
                            </v-card>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item key="solicitacoesEmAberto">
                    <v-card class="card-cambio">
                      <v-data-table
                        dense
                        :headers="columnsPaymentOrders"
                        :items="openPaymentOrders"
                        :search="search"
                        sort-by="withdrawalRequestDate"
                        no-data-text="Não há informações"
                        sort-desc
                        :items-per-page="paginationDefault"
                        height="calc(100dvh - 410px)"
                        fixed-header
                        :expanded.sync="expanded"
                        show-expand
                        @click:row="clickRow"
                        item-key="id"
                      >
                        <template #[`item.requestDate`]="{ item }">
                          {{ item.requestDate | date }}
                        </template>

                        <template #[`item.value`]="{ item }">
                          <template v-if="item.value">
                            {{ item.value | formatCurrency }}
                          </template>
                        </template>

                        <template #[`item.nf`]="{ item }">
                          <template v-if="item.value && item.brazilianAccount">
                            <DataTableDocumentInput
                              download-file-name="NF"
                              :callback-add-document="addDocument"
                              :callback-download-document="downloadDocument"
                              v-model="item.nf"
                            />
                          </template>
                        </template>

                        <template #[`item.proof`]="{ item }">
                          <template v-if="item.value">
                            <DataTableDocumentInput
                              download-file-name="Comprovante"
                              :callback-add-document="addProofOpenPaymentOrder"
                              :callback-download-document="downloadDocument"
                              v-model="item.proof"
                              data-test="document-comprovante-pagamento"
                            />
                          </template>
                        </template>

                        <!-- Expanded Content -->
                        <template #expanded-item="{ item }">
                          <td :colspan="columnsPaymentOrders.length + 1">
                            <v-card class="box card-cambio my-5">
                              <v-row>
                                <v-col cols="12">
                                  <OperationBankAccount
                                    :account="item"
                                    :is-international="!item.brazilianAccount"
                                  />
                                </v-col>
                              </v-row>
                            </v-card>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal Credit Statement -->
    <v-dialog
      persistent
      v-model="modalCreditStatement"
      max-width="75vw"
    >
      <v-card>
        <v-card-title>
          <h3>Extrato de Comissões - {{ this.selectedAccount.agent.name }}</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3" />
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="2"
              sm="3"
            >
              <Datepicker
                v-model="startDate"
                label="Data Inicial"
                v-disabled-icon-focus
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="3"
            >
              <Datepicker
                v-model="endDate"
                label="Data Final"
                v-disabled-icon-focus
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="2"
            >
              <v-btn
                text
                class="btn-secondary"
                @click="applyFilterByDate"
              >
                Aplicar filtro
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="2"
              sm="6"
            >
              <label>Saldo Disponível</label>
              <h2 class="remaining-value">{{ this.selectedAccount.balance | formatCurrency }}</h2>
            </v-col>
          </v-row>
          <v-divider class="py-4" />
          <v-card class="card-cambio">
            <v-data-table
              dense
              :headers="columnsStatement"
              :items="statementItems"
              sort-by="date"
              no-data-text="Não há informações"
              sort-desc
              :items-per-page="paginationDefault"
            >
              <template #[`item.date`]="{ item }">
                {{ item.date | date }}
              </template>
              <template #[`item.indicatorValue`]="{ item }">
                <template v-if="item.indicatorValue">
                  {{ item.indicatorValue | formatCurrency }}
                </template>
              </template>
              <template #[`item.agentValue`]="{ item }">
                <template v-if="item.agentValue">
                  {{ item.agentValue | formatCurrency }}
                </template>
              </template>
              <template #[`item.withdrawalValue`]="{ item }">
                <template v-if="item.withdrawalValue">
                  {{ item.withdrawalValue | formatCurrency }}
                </template>
              </template>

              <template #[`item.nf`]="{ item }">
                <template v-if="item.withdrawalValue">
                  <DataTableDocumentInput
                    download-file-name="NF"
                    :callback-add-document="addDocument"
                    :callback-download-document="downloadDocument"
                    v-model="item.nf"
                  />
                </template>
              </template>

              <template #[`item.proof`]="{ item }">
                <template v-if="item.withdrawalValue">
                  <DataTableDocumentInput
                    download-file-name="Comprovante"
                    :callback-add-document="addDocument"
                    :callback-download-document="downloadDocument"
                    v-model="item.proof"
                  />
                </template>
              </template>
            </v-data-table>
          </v-card>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="6"
            >
              <label>Total do Período</label>
              <h2 class="limit-value">{{ totalAmount || 0 | formatCurrency }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-tertiary ma-2"
            @click="closeModalCreditStatement"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create Payment Order -->
    <v-dialog
      persistent
      v-model="modalCreatePaymentOrder"
      max-width="30vw"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">
            Criar Ordem de pagamento - {{ this.selectedAccount.agent.name }}
          </h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-payment-order"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <MoneyField
                      label="Valor Pago"
                      prefix="R$ "
                      v-model="paymentOrder.value"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-file-input
                      label="Nota Fiscal"
                      outlined
                      dense
                      prepend-icon="fa-paperclip"
                      v-model="paymentOrderDocumentNf"
                      v-disabled-icon-focus
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-file-input
                      label="Comprovante de Pagamento"
                      outlined
                      dense
                      prepend-icon="fa-paperclip"
                      v-model="paymentOrderDocumentProof"
                      v-disabled-icon-focus
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="createPaymentOrder"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="modalCreatePaymentOrder = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Apis
import CorbanApi from '@/api/correspondent/correspondent-api';
import OperationApi from '@/api/operation/operation-api';
import AgentCommissionAccountApi from '@/api/correspondent/agent-commission-account-api';
import PaymentOrderApi from '@/api/operation/payment-order-api';
import PaymentOrderDocumentsApi from '@/api/operation/payment-order-documents-api';
import TriggerApi from '@/api/generic/trigger-api';

// Models
import CorrespondentModel from '@/model/correspondent-model';
import OperationFiltersModel from '@/model/operation-filters-model';
import AgentCommissionAccountModel from '@/model/agent-commission-account-model';
import OperationModel from '@/model/operation-model';
import CommissionStatementModel from '@/model/commission-statement-model';
import CommissionPaymentOrderModel from '@/model/commission-payment-order-model';
import CommissionPaymentOrderDocumentModel from '@/model/commission-payment-order-document-model';
import TriggerModel from '@/model/generic/trigger-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinValidationRules from '@/mixin/mixin-validation-rules';
import mixinExpandedDataTable from '@/mixin/mixin-data-table-expanded';

// Utils
import dateUtils from '../../../../common/utils/date';
import utilsStorage from '@/utils/storage';
import numberUtils from '../../../../common/utils/number';

// Components
import Datepicker from '@/components/comum/Datepicker.vue';
import MoneyField from '@/components/comum/MoneyField';
import DataTableDocumentInput from '@/components/comum/DataTableDocumentInput.vue';
import ConfirmationWindow from '@/components/comum/ConfirmationWindow';
import OperationBankAccount from '../../components/form-operation/OperationBankAccount.vue';

// Constants
import { COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE } from '../../../../common/constants/generic/types';
import { SEND_EMAIL_TRIGGER_CODES } from '../../../../common/constants/generic/triggers';
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: 'AdministrativeComissionsControl',
  mixins: [mixinMessage, mixinValidationRules, mixinExpandedDataTable],
  components: {
    Datepicker,
    MoneyField,
    DataTableDocumentInput,
    ConfirmationWindow,
    OperationBankAccount
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatCurrency(value) {
      return numberUtils.formatCurrency('BRL', value);
    }
  },
  inject: ['appData'],
  data() {
    return {
      loading: true,
      search: '',
      user: utilsStorage.getUserOnStorage(),
      correspondents: [],
      columns: [
        { text: 'Código', value: 'id', align: 'left', sortable: true, width: '100px' },
        { text: 'Nome', value: 'name', align: 'left', sortable: true }
      ],
      accounts: [],
      expandedCorrespondents: [],
      expandedIsLoading: false,
      expandedColumns: [
        { text: 'Código', value: 'agent.id', align: 'left' },
        { text: 'Nome', value: 'agent.name', align: 'left' },
        { text: 'Tipo', value: 'agent.type', align: 'left' },
        { text: 'Saldo', value: 'balance', align: 'center' },
        { text: 'Último Pagamento', value: 'lastPayment', align: 'center' },
        { text: 'Data de Crédito', value: 'lastCredit', align: 'center' },
        { text: '', value: 'actions', align: 'center' }
      ],
      indicatorOperations: [],
      agentOperations: [],
      paymentOrders: [],
      startDate: new Date(),
      endDate: new Date(),
      selectedAccount: new AgentCommissionAccountModel(),
      modalCreditStatement: false,
      columnsStatement: [
        { text: 'Código Operação', value: 'operationId', align: 'right' },
        { text: 'Data', value: 'date', align: 'center', sortable: true },
        { text: 'Comissões Corban', value: 'indicatorValue', align: 'right' },
        { text: 'Comissões Indicador', value: 'agentValue', align: 'right' },
        { text: 'Saques', value: 'withdrawalValue', align: 'right' },
        { text: 'NF', value: 'nf', align: 'center' },
        { text: 'Comprovante', value: 'proof', align: 'center' },
        { text: '', value: 'actions', align: 'right' }
      ],
      paymentOrder: new CommissionPaymentOrderModel(),
      modalCreatePaymentOrder: false,
      paymentOrderDocumentNf: undefined,
      paymentOrderDocumentProof: undefined,
      openConfirmationWindow: false,
      confirmationWindowCallback: () => {},
      confimationWindowMessage: '',
      corbanApi: new CorbanApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT,
      operationApi: new OperationApi(this.appData.currentUser),
      paymentOrderApi: new PaymentOrderApi(this.appData.currentUser),
      agentCommissionAccountApi: new AgentCommissionAccountApi(this.appData.currentUser),
      paymentOrderDocumentsApi: new PaymentOrderDocumentsApi(this.appData.currentUser),
      currentTab: 0,
      columnsPaymentOrders: [
        { text: 'Data de Solicitação', value: 'requestDate', align: 'center', sortable: true },
        { text: 'Nome/Razão Social', value: 'corban.name', align: 'left', sortable: true },
        { text: 'Valor', value: 'value', align: 'right' },
        { text: 'NF', value: 'nf', align: 'center' },
        { text: 'Comprovante', value: 'proof', align: 'center' }
      ],
      openPaymentOrders: []
    };
  },
  mounted() {
    this.getCorrespondentsList();
    this.getOpenPaymentOrders();
  },

  computed: {
    statementItems() {
      let items = [];

      this.indicatorOperations.forEach((operation) => {
        let statementItem = new CommissionStatementModel({
          date: operation.effectiveOperationDate,
          operationId: operation.id,
          indicatorValue: operation.administrative.indicatorValue
        });
        items.push(statementItem);
      });

      this.agentOperations.forEach((operation) => {
        let statementItem = new CommissionStatementModel({
          date: operation.effectiveOperationDate,
          operationId: operation.id,
          agentValue: operation.administrative.agentValue
        });
        items.push(statementItem);
      });

      this.paymentOrders.forEach((order) => {
        let nf =
          order.documents.find(
            (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id
          ) ||
          new CommissionPaymentOrderDocumentModel({
            paymentOrderId: order.id,
            documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF
          });
        let proof =
          order.documents.find(
            (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF.id
          ) ||
          new CommissionPaymentOrderDocumentModel({
            paymentOrderId: order.id,
            documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF
          });

        let statementItem = new CommissionStatementModel({
          date: order.paymentDate || 'Pagamento Pendente',
          withdrawalValue: order.value,
          nf,
          proof
        });
        items.push(statementItem);
      });

      return items;
    },

    totalAmount() {
      let total = 0;

      this.indicatorOperations.forEach((operation) => {
        total += +operation.administrative.indicatorValue;
      });

      this.agentOperations.forEach((operation) => {
        total += +operation.administrative.agentValue;
      });

      return total;
    }
  },

  watch: {
    expandedCorrespondents() {
      this.accounts = [];

      if (!this.expandedCorrespondents.length) {
        return;
      }

      this.findRelatedAccount();
    }
  },

  methods: {
    getCorrespondentsList() {
      this.corbanApi
        .findIndicators()
        .then((response) => {
          this.correspondents = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    creditOperations() {
      this.loading = true;

      let lastDayLastMonth = dateUtils.lastDayOfLastMonth();
      let firstDayLastMonth = dateUtils.firstDayOfLastMonth();

      let filters = new OperationFiltersModel({
        statusList: [OPERATION_WORKFLOW.LIQUIDADA.id],
        startDate: new Date(firstDayLastMonth),
        endDate: new Date(lastDayLastMonth)
      });

      this.operationApi
        .creditOperationCommissions(filters)
        .then(() => {
          this.getCorrespondentsList();
          this.expandedCorrespondents = [];
          this.confirmationWindowCallback = this.callbackSendCommissionReports;
          this.confimationWindowMessage = 'Deseja enviar o relatório de comissões aos corbans?';
          this.openConfirmationWindow = true;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openModalCreditStatement(commissionAccount) {
      this.startDate = dateUtils.firstDayOfLastMonth();
      this.endDate = dateUtils.lastDayOfLastMonth();
      this.selectedAccount = commissionAccount;

      this.getStatementData();

      this.modalCreditStatement = true;
    },

    closeModalCreditStatement() {
      this.modalCreditStatement = false;
    },

    applyFilterByDate() {
      this.getStatementData();
    },

    getStatementData() {
      this.getAgentOperations();
      this.getIndicatorOperations();
      this.getPaymentOrders();
    },

    getAgentOperations() {
      let operationFilter = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate,
        agentId: this.selectedAccount.agent.id,
        statusList: [OPERATION_WORKFLOW.LIQUIDADA.id]
      });

      this.operationApi
        .getOperations(operationFilter)
        .then((response) => {
          this.agentOperations = response.data.map((o) => new OperationModel(o));
        })
        .catch(() => {
          this.agentOperations = [];
        });
    },

    getIndicatorOperations() {
      let operationFilter = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate,
        indicatorId: this.selectedAccount.agent.id,
        statusList: [OPERATION_WORKFLOW.LIQUIDADA.id]
      });

      this.operationApi
        .getOperations(operationFilter)
        .then((response) => {
          this.indicatorOperations = response.data.map((o) => new OperationModel(o));
        })
        .catch(() => {
          this.indicatorOperations = [];
        });
    },

    getPaymentOrders() {
      let filters = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate,
        agentId: this.selectedAccount.agent.id
      });

      this.paymentOrderApi
        .find(filters)
        .then((response) => {
          this.paymentOrders = response.data.map((o) => new CommissionPaymentOrderModel(o));
        })
        .catch(() => {
          this.paymentOrders = [];
        });
    },

    findRelatedAccount() {
      let item = this.expandedCorrespondents[0];

      this.expandedIsLoading = true;
      this.agentCommissionAccountApi
        .findRelated(item.id)
        .then((response) => {
          this.accounts = response.data.map((a) => {
            a = new AgentCommissionAccountModel(a);
            a.agent.type = a.agent.id == this.expandedCorrespondents[0].id ? 'Corban' : 'Indicador';

            return a;
          });
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.expandedIsLoading = false;
        });
    },

    openModalCreatePaymentOrder(commissionAccount) {
      this.selectedAccount = commissionAccount;
      this.paymentOrder = new CommissionPaymentOrderModel();
      this.paymentOrderDocumentNf = undefined;
      this.paymentOrderDocumentProof = undefined;

      this.modalCreatePaymentOrder = true;
    },

    createPaymentOrder() {
      this.paymentOrder.agentId = this.selectedAccount.agent.id;
      this.paymentOrder.paymentDate = new Date();

      this.paymentOrderApi
        .add(this.paymentOrder)
        .then((response) => {
          this.createPaymentOrderNfDocument(response.data);
          this.createPaymentOrderProofDocument(response.data);
          this.findRelatedAccount();
          this.modalCreatePaymentOrder = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    createPaymentOrderNfDocument(paymentOrder) {
      let formData = new FormData();

      formData.append('paymentOrderId', paymentOrder.id);
      formData.append('documentTypeId', COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id);
      formData.append('document', this.paymentOrderDocumentNf);

      this.paymentOrderDocumentsApi
        .add(formData)
        .then(() => {})
        .catch(() => {});
    },

    createPaymentOrderProofDocument(paymentOrder) {
      let formData = new FormData();

      formData.append('paymentOrderId', paymentOrder.id);
      formData.append('documentTypeId', COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF.id);
      formData.append('document', this.paymentOrderDocumentProof);

      this.paymentOrderDocumentsApi
        .add(formData)
        .then(() => {})
        .catch(() => {});
    },

    addDocument(document) {
      let formData = new FormData();

      formData.append('paymentOrderId', document.paymentOrderId);
      formData.append('documentTypeId', document.documentType.id);
      formData.append('document', document.document);

      return this.paymentOrderDocumentsApi
        .add(formData)
        .then((response) => {
          return (document = {
            ...response.data
          });
        })
        .catch(() => {
          this.sendMessage('Erro ao adicionar o anexo!', 'error');
          document.document = undefined;

          return document;
        });
    },

    downloadDocument(document) {
      return this.paymentOrderDocumentsApi.download(document.id);
    },

    callbackSendCommissionReports() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CORBAN_RELATORIO_FECHAMENTO
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage('Relatórios de comissões enviados com sucesso!', 'success');
          this.openConfirmationWindow = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getOpenPaymentOrders() {
      return this.paymentOrderApi
        .findOpen()
        .then((response) => {
          this.openPaymentOrders = [];
          let orders = response.data.map((o) => new CommissionPaymentOrderModel(o));

          orders.forEach((order) => {
            order.nf =
              order.documents.find(
                (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id
              ) ||
              new CommissionPaymentOrderDocumentModel({
                paymentOrderId: order.id,
                documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF
              });

            order.proof =
              order.documents.find(
                (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF.id
              ) ||
              new CommissionPaymentOrderDocumentModel({
                paymentOrderId: order.id,
                documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF
              });

            order.requestDate = order.requestDate || 'Pagamento sem requisição';
            order.paymentDate = order.paymentDate || 'Pagamento pendente';

            this.openPaymentOrders.push(order);
          });

          this.openPaymentOrders.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate));
        })
        .catch(() => {
          this.paymentOrders = [];
        });
    },

    addProofOpenPaymentOrder(document) {
      this.paymentOrderApi.update({ id: document.paymentOrderId, paymentDate: new Date() });

      return this.addDocument(document).then((addedDocument) => {
        let index = this.openPaymentOrders.findIndex((i) => i.id == document.paymentOrderId);

        if (index !== -1) {
          this.openPaymentOrders.splice(index, 1);
        }

        return addedDocument;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.remaining-value {
  color: var(--v-primary-base);
}
</style>
