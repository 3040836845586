<template>
  <div>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="6"
      >
        <legend>Exerce ou exerceu cargo ou função relevante?</legend>
        <v-radio-group
          data-test="rd-exerce-cargo"
          row
          class="py-0"
          v-model="customer.additional.politicallyExposedPerson"
          :rules="[validationIsRequiredRadioBtn]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        >
          <v-radio
            data-test="rd-sim"
            :value="true"
            label="Sim"
          />
          <v-radio
            data-test="rd-nao"
            :value="false"
            label="Não"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="customer.additional.politicallyExposedPerson">
      <v-col
        class="py-0"
        cols="12"
        md="6"
      >
        <v-text-field
          data-test="txt-cargo"
          v-model="customer.additional.position"
          outlined
          dense
          label="Cargo"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          data-test="txt-codigo-cargo"
          v-model="customer.additional.positionCode"
          outlined
          dense
          label="Código do cargo"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <Datepicker
          data-test="dt-inicio-exercicio"
          label="Inicio do exercicio"
          v-model="customer.additional.practiceStartDate"
          v-disabled-icon-focus
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <Datepicker
          data-test="dt-fim-exercicio"
          label="Fim do exercicio"
          v-model="customer.additional.practiceEndDate"
          v-disabled-icon-focus
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          data-test="txt-empresa-orgao"
          v-model="customer.additional.companyPublicAgency"
          outlined
          dense
          label="Empresa ou orgão público"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <legend>Possui relacionamento com agente público?</legend>
        <v-radio-group
          data-test="rd-relacionamento-agente"
          row
          v-model="customer.additional.relationshipPublicAgent"
          :rules="[validationIsRequiredRadioBtn]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        >
          <v-radio
            data-test="rd-sim-relacionamento"
            :value="true"
            label="Sim"
          />
          <v-radio
            data-test="rd-nao-relacionamento"
            :value="false"
            label="Não"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="customer.additional.relationshipPublicAgent">
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          data-test="txt-nome-relacionado"
          v-model="customer.additional.relatedName"
          outlined
          dense
          label="Nome completo do relacionado"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <CpfOrCnpjField
          data-test="txt-cpf-relacionado"
          v-model="customer.additional.relatedCPF"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          data-test="txt-cargo-funcao-relacionado"
          v-model="customer.additional.relatedPosition"
          outlined
          dense
          label="Cargo ou função"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          data-test="txt-tipo-relacionamento"
          v-model="customer.additional.relationshipType"
          outlined
          dense
          label="Tipo de relacionamento / Relação"
          :rules="[validationIsRequiredField]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Datepicker from '@/components/comum/Datepicker';
import CpfOrCnpjField from '@/components/comum/CpfOrCnpjField';
import { REGISTER_TYPE } from '@/constants/general-constants.js';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Constants
import { CUSTOMER_FORM_TAB } from "@/constants/customer-form-constants";

export default {
  name: 'PFCustomerPoliticallyExposedPerson',
  mixins: [mixinValidationRules],
  components: { Datepicker, CpfOrCnpjField },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  },
  data() {
    return {
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      customer: this.customerProp,
      customerFormTab: CUSTOMER_FORM_TAB
    };
  }
};
</script>

<style></style>
