<template>
  <div>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="4"
        lg="3"
      >
        <v-text-field
          data-test="txt-apelido-conta"
          v-model="bank.accountName"
          outlined
          dense
          label="Apelido da conta"
          :rules="required ? [validationIsRequiredField] : []"
        >
          <template #append-outer>
            <InfoTooltip
              data-test="tooltip-apelido-conta"
              label="Ex: Conta do banco XYZ"
            />
          </template>
        </v-text-field> 
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
        lg="2"
      >
        <v-text-field
          data-test="txt-banco"
          v-model="bank.bank"
          outlined
          dense
          label="Banco"
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
        lg="2"
      >
        <v-text-field
          data-test="txt-swift-bic"
          v-model="bank.swiftCodeBIC"
          outlined
          dense
          label="Código SWIFT ou BIC"
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
        lg="2"
      >
        <v-text-field
          data-test="txt-numero-conta-iban"
          v-model="bank.numberIBAN"
          outlined
          dense
          label="Número da Conta ou IBAN"
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="4"
        lg="3"
      >
        <v-text-field
          data-test="txt-nome-titular"
          v-model="bank.ownerName"
          outlined
          dense
          label="Nome do titular"
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          data-test="txt-aba-routing"
          v-model="bank.tabNumberRouting"
          outlined
          dense
          label="Número ABA/ROUTING"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="4"
      >
        <v-text-field
          data-test="txt-ffc"
          v-model="bank.forFurtherCredit"
          outlined
          dense
          label="FFC (For further credit)"
        />
      </v-col>
      <v-col
        class="py-2"
        cols="12"
        md="12"
        v-if="!hideIntermediary"
      >
        <v-checkbox
          data-test="chk-banco-intermediario"
          label="Deseja cadastrar um banco intermediário"
          v-model="bank.intermediary"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="12"
        v-if="bank.intermediary"
      >
        <v-row>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <CountryField
              data-test="slc-pais"
              v-model="bank.intermediaryCountry" 
              label="País" 
              :rules="required ? [validationIsRequiredAutocompleteText] : []"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <v-text-field
              data-test="txt-nome-banco-intermediario"
              v-model="bank.intermediaryBankName"
              outlined
              dense
              label="Nome banco intermediário"
              :rules="required ? [validationIsRequiredField] : []"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <v-text-field
              data-test="txt-swift-aba-intermediario"
              v-model="bank.intermediaryAbaSwiftCode"
              outlined
              dense
              label="Código SWIFT ou ABA banco intermediário"
              :rules="required ? [validationIsRequiredField] : []"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Components
import CountryField from "@/components/comum/CountryField";
import InfoTooltip from '@/components/comum/InfoTooltip';

export default {
  name: 'InternationalBankComponent',
  mixins: [mixinValidationRules],
  components: { CountryField, InfoTooltip },
  model: {
    prop: 'bankProp',
    event: 'onChange'
  },
  props: {
    bankProp: { type: Object, default: () => ({}) },
    required: { type: Boolean, default: false },
    hideIntermediary: { type: Boolean, default: false }
  },
  data() {
    return {
      bank: this.bankProp,
    };
  },

  watch: {
    bank: {
      handler() {
        this.$emit('onChange', this.bank);
      },
      deep: true
    }
  },

  methods: {}
};
</script>

<style></style>
