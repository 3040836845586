<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo">Controle de templates</h1>
        <v-divider />
      </v-col>
      <v-col
        cols="12"
        md="9"
        sm="8"
      >
        <v-btn
          class="btn-primary ml-0"
          text
          @click="openAddTemplate"
        >
          <i class="far fa-plus" /> Adicionar
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="4"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio">
          <v-data-table
            :headers="columns"
            :items="templates"
            :search="search"
            sort-by="id"
            sort-asc
            :items-per-page="paginationDefault"
          >
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | date }}
            </template>
            <template #[`item.type.description`]="{ item }">
              <v-chip
                small
                label
                color="rgba(101,76,240,0.25)"
              >
                {{ item.type.description }}
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    text
                    color="primary"
                    @click="editTemplateModal(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="far fa-pencil" />
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    text
                    color="primary"
                    @click="previewTemplate(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="far fa-eye" />
                  </v-btn>
                </template>
                <span>Pré-visualizar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      scrollable
      v-model="preview"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">{{ template.description }}</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-5"><span v-html="template.content"></span></v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="btn-primary"
            text
            @click="preview = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Api
// import apiTemplateTypes from "@/api/generic/template-types-api";
import apiTemplates from "@/api/generic/template-api";

// Models
import TemplateModel from "@/model/generic/template-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

// Utils
import dateUtils from "../../../../../common/utils/date";
import { PAGINATION_DEFAULT } from '../../../constants/general-constants';

export default {
  name: "TemplatesControl",
  mixins: [mixinMessage, mixinDataTableFilters],

  data() {
    return {
      search: "",
      templates: [],
      columns: [
        { text: "Código", value: "id", align: "left", sortable: true, width: "100px" },
        { text: "Descrição", value: "description", align: "left", sortable: true },
        { text: "Assunto", value: "subject", align: "left", sortable: true },
        { text: "Tipo", value: "type.description", align: "right", sortable: true },
        { text: "Data de cadastro", value: "createdAt", align: "center", sortable: true, },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      template: new TemplateModel(),
      addTemplateModal: false,
      update: false,
      preview: false,
      loading: false,
      paginationDefault: PAGINATION_DEFAULT
    };
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    }
  },
  mounted() {
    this.loading = true;

    try {
      this.getTemplateList();
    } finally {
      this.loading = false;
    }
  },
  computed: {},
  methods: {
    getTemplateList() {
      apiTemplates
        .findAll()
        .then((response) => {
          this.templates = response.data.map((t) => new TemplateModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddTemplate() {
      this.update = false;
      this.template = new TemplateModel();

      this.$router
        .push({
          name: "FormTemplate"
        })
        .catch(() => { });
    },

    editTemplateModal(template) {
      // this.update = true;
      this.template = new TemplateModel(template);
      this.$router
        .push({
          name: "FormEditTemplate",
          params: {
            id: this.template.id
          },
        })
        .catch(() => { });
    },

    previewTemplate(template) {
      this.template = new TemplateModel(template);
      this.preview = true;
    },

    addTemplate() {
      apiTemplates
        .add(this.template)
        .then((response) => {
          this.templates.push(new TemplateModel(response.data));
          this.addTemplateModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateTemplate() {
      apiTemplates
        .update(this.template)
        .then((response) => {
          let index = this.templates.findIndex((c) => c.id == this.template.id);
          this.templates.splice(index, 1, new TemplateModel(response.data));
          this.addTemplateModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
