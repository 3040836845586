<template>
  <div>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-checkbox
          label="Tel. internacional"
          v-model="customerContactData.internationalLandline"
          dense
        />
      </v-col>
      <v-col
        class="py-0 pr-0"
        cols="12"
        md="1"
        v-if="customerContactData.internationalLandline"
      >
        <v-text-field
          outlined
          dense
          placeholder="+000"
          v-mask="'+###'"
          inputmode="decimal"
          label="DDI"
          :maxlength="3"
          v-model="customerContactData.ddiLandline"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        :lg="customerContactData.internationalLandline ? 3 : 4"
      >
        <PhoneField
          :is-international-phone="customerContactData.internationalLandline"
          is-landline
          v-model="customerContactData.landline"
          label="Telefone Fixo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import PhoneField from "@/components/comum/PhoneField.vue";

export default {
  name: "PFOnlyCompleteRemittanceFields",
  components: { PhoneField },
  model: {
    prop: "model",
    event: "onChange",
  },
  props: {
    model: {
      type: Object,
    },
  },
  watch: {
    customerContactData() {
      this.$emit("onChange", this.customerContactData);
    },
  },
  data() {
    return {
      customerContactData: this.model,
    };
  },
};
</script>

<style>
</style>