<template>
  <div>
    <v-row
      v-for="(item, index) in customer.partnersShareholders"
      :key="index"
      :data-test="`socio-acionista-${index}`"
    >
      <v-col
        class="py-0"
        cols="12"
        md="1"
      >
        <v-checkbox
          data-test="ck-pessoa-juridica"
          label="Pessoa Jurídica?"
          v-model="item.isLegalPerson"
          dense
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          data-test="txt-nome-socio"
          outlined
          dense
          :label="item.isLegalPerson ? 'Razão Social' : 'Nome'"
          v-model="item.name"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <CpfOrCnpjField
          data-test="txt-cpf-cnpj-socio"
          :is-p-j="item.isLegalPerson"
          v-model="item.cpfCnpj"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        lg="2"
      >
        <PhoneField
          data-test="txt-celular-socio"
          v-model="item.cellphone"
          label="Celular"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <MailField
          data-test="txt-email-socio"
          label="E-mail"
          :required="true"
          v-model="item.email"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          data-test="txt-percentual-participacao"
          outlined
          dense
          inputmode="decimal"
          max-lenght="3"
          label="% de participação"
          v-model="item.share"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="1"
        v-if="customer.partnersShareholders.length >= 2"
      >
        <v-btn
          data-test="btn-remover-socio"
          icon
          class="float-right"
          @click="removeOtherPartners(index)"
          text
        >
          <i class="far fa-trash" />
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="py-0">
      <v-col class="pt-5">
        <v-btn
          data-test="btn-adicionar-socio"
          class="btn-secondary float-right"
          @click="insertOtherPartners"
          text
        >
          <i class="far fa-plus" /> Adicionar outro
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import CpfOrCnpjField from '@/components/comum/CpfOrCnpjField.vue';
import PhoneField from '@/components/comum/PhoneField.vue';
import MailField from '@/components/comum/MailField.vue';

// Model
import PartnersShareholdersModel from '@/model/customer-partner-shareholder-model';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

export default {
  name: 'PartnersAndShareholders',
  mixins: [mixinValidationRules],
  components: {
    CpfOrCnpjField,
    PhoneField,
    MailField
  },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  },
  data() {
    return {
      customer: this.customerProp
    };
  },
  methods: {
    insertOtherPartners() {
      this.customer.partnersShareholders.push(new PartnersShareholdersModel());
    },
    removeOtherPartners(index) {
      if (this.customer.partnersShareholders.length > 1) {
        this.customer.partnersShareholders.splice(index, 1);
      }
    }
  },
  mounted() {
    if (this.customer.partnersShareholders.length == 0) {
      this.customer.partnersShareholders = [new PartnersShareholdersModel()];
    }
  }
};
</script>

<style></style>
