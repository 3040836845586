<template>
  <v-row
    no-gutters
    class="pt-2 pt-md-5"
  >
    <v-col cols="12">
      <v-card class="box card-cambio">
        <OperationalDocuments v-model="operation" />
        <CustomerOperationalDocuments v-model="operation" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CustomerOperationalDocuments from "./CustomerOperationalDocuments";
import OperationalDocuments from "./OperationalDocuments";

export default {
  name: "OperationDocuments",
  components: { CustomerOperationalDocuments, OperationalDocuments },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      operation: this.operationProp,
    };
  }
};
</script>

<style scoped></style>
