<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <legend>Deseja cadastrar uma conta no exterior?</legend>
        <v-radio-group
          data-test="rd-conta-exterior"
          row
          class="py-0"
          v-model="customer.additional.newInternationalAccount"
        >
          <v-radio
            data-test="rd-sim"
            :value="true"
            label="Sim"
          />
          <v-radio
            data-test="rd-nao"
            :value="false"
            label="Não"
          />
        </v-radio-group>
      </v-col>
      <v-col
        v-if="customer.additional.newInternationalAccount"
        cols="12"
        class="py-0"
        :key="key"
      >
        <v-row
          v-for="(bank, index) in customer.internationalBanks"
          :key="index"
          class="card-border"
        >
          <v-col
            cols="12"
            md="11"
          >
            <InternationalBankComponent
              v-model="customer.internationalBanks[index]"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="1"
            v-if="customer.internationalBanks.length >= 2"
            class="py-0"
          >
            <v-btn
              data-test="btn-remover-banco-internacional"
              icon
              class="float-right"
              @click="removeBank(index)"
              text
            >
              <i class="far fa-trash" />
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="py-0">
          <v-col class="pt-5">
            <v-btn
              data-test="btn-adicionar-banco-internacional"
              class="btn-secondary float-right"
              @click="insertBank"
              text
            >
              <i class="far fa-plus" /> Adicionar outro
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Model
import CustomerInternationalBankModel from "@/model/customer-international-bank-model";

// Components
import InternationalBankComponent from '@/components/comum/InternationalBankComponent';

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PFInternationalBank",
  mixins: [ mixinValidationRules ],
  components: { InternationalBankComponent },
  data() {
    return {
      customer: this.customerProp,
      listOfCountries: undefined,
      key: 0
    };
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  mounted() {
    if (this.customer.internationalBanks.length == 0) {
      this.customer.internationalBanks = [new CustomerInternationalBankModel()];
    }
  },
  methods: {
    insertBank() {
      this.customer.internationalBanks.push(new CustomerInternationalBankModel());
    },
    removeBank(index) {
      if (this.customer.internationalBanks.length > 1) {
        this.customer.internationalBanks.splice(index, 1);
        this.key++;
      }
    },
  },
};
</script>

<style>
</style>