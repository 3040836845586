<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Spread de remessa</h1>
        <v-divider />
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="d-flex align-end"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="openAddSpreadModal"
            >
              <i class="far fa-plus" />
              Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="correspondent.spreads"
                :search="search"
                sort-by="id"
                sort-desc
                :items-per-page="paginationDefault"
                height="300px"
                fixed-header
              >
                <template #[`item.actions`]="{ item }">
                  <td class="buttons-column">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          class="btn-primary"
                          text
                          @click="editSpreadModal(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="far fa-pencil" />
                          Editar
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Add Spread Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="40vw"
      max-width="100vh"
      v-model="addSpreadModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Spread de remessa</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-spread"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <CurrencySelect
                      :currencyList="notUsedCurrencies"
                      v-model="spread.currency"
                      :required="false"
                    />
                  </v-col>

                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="spread.minimumSpread"
                      outlined
                      dense
                      label="Spread Mínimo Venda(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="spread.recommendedSpread"
                      outlined
                      dense
                      label="Spread Recomendado Venda(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="spread.maximumSpread"
                      outlined
                      dense
                      label="Spread Máximo Venda(%)"
                      suffix="%"
                    />
                  </v-col>

                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="spread.minimumSpreadBuy"
                      outlined
                      dense
                      label="Spread Mínimo Compra(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="spread.recommendedSpreadBuy"
                      outlined
                      dense
                      label="Spread Recomendado Compra(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="spread.maximumSpreadBuy"
                      outlined
                      dense
                      label="Spread Máximo Compra(%)"
                      suffix="%"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              v-if="!update"
              text
              class="btn-primary mr-2"
              @click="addSpread"
            >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="update"
              text
              class="btn-primary mr-2"
              @click="updateSpread"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addSpreadModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import CurrencyApi from '@/api/spread/currency-api';
import apiCorrespondentSpread from '@/api/correspondent/correspondent-spread-api';

// Components
import NumberField from '@/components/comum/NumberField';
import CurrencySelect from '../form-operation/CurrencySelect.vue';

// Models
import CorrespondentSpreadModel from '@/model/correspondent-spread-model';
import CurrencyModel from '@/model/currency-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: 'CorrespondentSpreads',
  mixins: [mixinMessage],
  components: {
    NumberField,
    CurrencySelect
  },
  model: {
    prop: 'correspondentProp',
    event: 'onChange'
  },
  props: {
    correspondentProp: {
      type: Object
    }
  },
  inject: ['appData'],
  data() {
    return {
      correspondent: this.correspondentProp,
      search: '',
      currencies: [],
      columns: [
        { text: 'Código', value: 'currency.code', align: 'left', sortable: true },
        { text: 'Nome', value: 'currency.name', align: 'left', sortable: true },
        { text: 'Spread mínimo venda(%)', value: 'minimumSpread', align: 'right', sortable: true },
        {
          text: 'Spread recomendado venda(%)',
          value: 'recommendedSpread',
          align: 'right',
          sortable: true
        },
        { text: 'Spread máximo venda(%)', value: 'maximumSpread', align: 'right', sortable: true },
        {
          text: 'Spread mínimo compra(%)',
          value: 'minimumSpreadBuy',
          align: 'right',
          sortable: true
        },
        {
          text: 'Spread recomendado compra(%)',
          value: 'recommendedSpreadBuy',
          align: 'right',
          sortable: true
        },
        {
          text: 'Spread máximo compra(%)',
          value: 'maximumSpreadBuy',
          align: 'right',
          sortable: true
        },
        { text: '', value: 'actions', align: 'right', sortable: false }
      ],
      spread: new CorrespondentSpreadModel(),
      addSpreadModal: false,
      update: false,
      paginationDefault: PAGINATION_DEFAULT,
      currencyApi: new CurrencyApi(this.appData.currentUser)
    };
  },
  mounted() {
    this.getCurrencies();
  },
  computed: {
    notUsedCurrencies() {
      let filtered = this.currencies.filter((currency) => {
        return (
          this.correspondent.spreads.filter((spread) => {
            return spread.currency.id == currency.id;
          }).length == 0
        );
      });

      if (this.spread?.currency?.id) {
        filtered.push(this.spread.currency);
      }

      return filtered;
    }
  },
  methods: {
    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      this.currencyApi
        .findAll()
        .then((response) => {
          this.currencies = response.data.map((c) => new CurrencyModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    openAddSpreadModal() {
      this.update = false;
      this.spread = new CorrespondentSpreadModel();
      this.addSpreadModal = true;
    },

    editSpreadModal(spread) {
      this.update = true;
      this.spread = Object.assign({}, spread);
      this.addSpreadModal = true;
    },

    addSpread() {
      if (!this.correspondent.id) {
        this.correspondent.spreads.push(new CorrespondentSpreadModel(this.spread));
        this.addSpreadModal = false;

        return;
      }

      apiCorrespondentSpread
        .add(this.spread, this.correspondent.id)
        .then((response) => {
          this.correspondent.spreads.push(new CorrespondentSpreadModel(response.data));
          this.addSpreadModal = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    updateSpread() {
      let correspondentId = parseInt(this.correspondent.id);
      apiCorrespondentSpread
        .update(this.spread, correspondentId)
        .then((response) => {
          let index = this.correspondent.spreads.findIndex((s) => s.id == this.spread.id);
          this.correspondent.spreads.splice(index, 1, new CorrespondentSpreadModel(response.data));
          this.addSpreadModal = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    }
  }
};
</script>

<style></style>
