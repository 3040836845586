<template>
  <BaseModal
    v-model="showModal"
    modal-width="60vw"
    title="Documentos da operação"
    hide-primary-button
    secondary-button-text="Fechar"
    :secondary-button-action="() => (showModal = false)"
    :fullscreen="appData.isMobile"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        v-for="type in operationDocuments"
        :key="type.id"
        class="pb-3"
      >
        <DocumentInput
          :description="type.description"
          :input="operation.documents.find((d) => d.operationDocumentType.id == type.id) || {}"
          :api="operationDocumentApi"
          :operation-id="operation.id"
          :file-type="fileType.OPERATION_DOCUMENT"
          :document-post-save-callback="documentPostSaveCallback"
          :customer-id="customerId"
          :operation-document-type-id="type.id"
          hide-insert
          @onDelete="(document) => $emit('onDelete', document)"
          @onChange="({ document }) => $emit('onSelectDocument', document, type)"
        />
      </v-col>
    </v-row>
  </BaseModal>
</template>

<script>

// Components
import BaseModal from '../comum/BaseModal.vue';
import DocumentInput from '../comum/DocumentInput.vue';

// Constants
import { FILE_TYPE, OPERATION_DOCUMENT_TYPE_LIST } from '../../constants/general-constants';

// APIs
import OperationDocumentApi from '@/api/operation/operation-documents-api';

export default {
  name: 'OperationalDocumentsModal',

  components: {
    BaseModal,
    DocumentInput,
  },

  model: { 
    prop: 'show',
    event: 'onChange',
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    operation: {
      type: Object,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
    documentPostSaveCallback: {
      type: Function,
      required: true,
    },
  },

  inject: ['appData'],

  data() {
    return {
      fileType: FILE_TYPE,
      operationDocumentsTypeList: OPERATION_DOCUMENT_TYPE_LIST,
      operationDocuments: [],
      operationDocumentApi: new OperationDocumentApi(this.appData.currentUser),
    };
  },

  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(valor) {
        this.$emit('onChange', valor);
      }
    }
  },
  mounted () {
    this.fillOperationDocuments();
  },

  methods: {
    fillOperationDocuments() {
      this.operationDocuments = this.operationDocumentsTypeList.map((type) => {
        return {
          id: type.id,
          description: type.description,
          operationDocumentType: type.operationDocumentType,
        };
      });

      const OTHER_DOCUMENT_INDEX = 4;
      const AWBBL_DOCUMENT_INDEX = 8;

      const temp = this.operationDocuments[OTHER_DOCUMENT_INDEX];
      this.operationDocuments[OTHER_DOCUMENT_INDEX] = this.operationDocuments[8];
      this.operationDocuments[AWBBL_DOCUMENT_INDEX] = temp;
    },
  },
};
</script>
