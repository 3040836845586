import validateCpfOrCnpj from "@/utils/validateCpfOrCnpj";

const mixinValidationRules = {
  data() {
    return {

    };
  },
  methods: {
    validationIsRequiredField(value) {
      return !!value || `Este campo é obrigatório`;
    },
    validationIsRequiredAutocompleteId(value) {
      return !!value?.id || `Este campo é obrigatório`;
    },
    validationIsRequiredAutocompleteText(value) {
      return !!value || `Este campo é obrigatório`;
    },
    validationIsRequiredAutocompleteMultiple(value) {
      return !!value?.length || `É obrigatório selecionar pelo menos uma das opções`;
    },
    validationIsRequiredRadioBtn(value) {
      return !!value || value == false || `É obrigatório selecionar uma das opções`;
    },
    validationIsEmailValid(value) {
      return !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value) || "Informe um e-mail válido";
    },
    validationIsCpfCnpjValid(value) {
      if (!value || value.length == 0) {
        return true;
      }

      let valid = false;

      if (value.length == 14) {
        valid = validateCpfOrCnpj.validateCPF(value);
      } else if (value.length == 18) {
        valid = validateCpfOrCnpj.validateCNPJ(value);
      }

      return valid || "Informe um cpf/cnpj válido";
    },
    validationIsRequiredFieldNumeric(value) {
      let number = parseFloat(
        value
          .toString()
          // removes letters
          .replace(/^\D+/g, "")
          // convert the string to a valid float format
          .replace(/\./g, "")
          .replace(",", ".")
      );

      return !!number || `Este campo é obrigatório`;
    },
    validationIsRequiredCheckBox(value) {
      return !!value || `É obrigatório concordar para prosseguir`;
    },
    validationIsRequiredFile(value) {
      return !!value?.name || `Este campo é obrigatório`;
    },
    validationPasswordField(value) {
      return (value && value.trim().length >= 6) || 'Senha deve ter mínimo de 6 caracteres';
    },
    validationIsRequiredFieldNumericAllowZero(value) {
      let number = parseFloat(
        value
          .toString()
          // removes letters
          .replace(/^\D+/g, "")
          // convert the string to a valid float format
          .replace(/\./g, "")
          .replace(",", ".")
      );

      return !!number || number == 0 || `Este campo é obrigatório`;
    },
  }
};

export default mixinValidationRules;