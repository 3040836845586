function getUserOnStorage() {
  var user = localStorage.getItem('user-tc');

  if (user) {
    user = atob(user);

    return JSON.parse(user);
  }

  return undefined;
}

function saveUserOnStorage(user) {
  user = btoa(JSON.stringify(user));
  localStorage.setItem('user-tc', user);
}

function saveTokenOnStorage(token) {
  localStorage.setItem('token-tc', token);
}

function getTokenOnStorage() {
  return localStorage.getItem('token-tc');
}

function removeAuth() {
  localStorage.removeItem('token-tc');
  localStorage.removeItem('user-tc');
}

function setThemeSettings(darkMode) {
  localStorage.setItem('isDark', !!darkMode);

}

function getThemeSettings() {
  let valor = localStorage.getItem('isDark');

  return !!(valor == "true");
}

function saveCustomerOnStorage(customer) {
  customer = btoa(JSON.stringify(customer));
  localStorage.setItem('customer-ch', customer);
}

function getCustomerOnStorage() {
  var customer = localStorage.getItem('customer-ch');

  if (customer) {
    customer = atob(customer);

    return JSON.parse(customer);
  }

  return undefined;
}

function removeCustomer() {
  localStorage.removeItem('customer-ch');
}

export default {
  getUserOnStorage,
  saveUserOnStorage,
  saveTokenOnStorage,
  getTokenOnStorage,
  setThemeSettings,
  getThemeSettings,
  removeAuth,
  saveCustomerOnStorage,
  getCustomerOnStorage,
  removeCustomer,
};