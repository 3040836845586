function get(corban, firstAccessCode) {
  return [
    {
      name: "Corban",
      children: [
        {
          name: "Código",
          attribute: "Codigo_Corban",
          title: "Código do corban",
          inline: true,
          content: () => {
            return corban?.id || '';
          }
        },
        {
          name: "Nome ou razão social",
          attribute: "Nome_Razao_Social_Corban",
          title: "Nome ou razão social do corban",
          inline: true,
          content: () => {
            return corban?.name || '';
          }
        },
        {
          name: "Nome Fantasia",
          attribute: "Nome_Fantasia_Corban",
          title: "Nome fantasia do corban",
          inline: true,
          content: () => {
            return corban?.fantasyName || '';
          }
        },
        {
          name: "CPF ou CNPJ",
          attribute: "CPF_OU_CNPJ_CORBAN",
          title: "CPF ou CNPJ do corban",
          inline: true,
          content: () => {
            return corban?.cpfCnpj || '';
          }
        },
        {
          name: "Celular",
          attribute: "Celular_Corban",
          title: "Celular do corban",
          inline: true,
          content: () => {
            return corban?.cellphone || '';
          }
        },
        {
          name: "Telefone fixo",
          attribute: "Telefone_Fixo_Corban",
          title: "Telefone fixo do corban",
          inline: true,
          content: () => {
            return corban?.landline || '';
          }
        },
        {
          name: "E-mail de cadastro principal",
          attribute: "Email_Cadastro_Principal_Corban",
          title: "E-mail de cadastro principal do corban",
          inline: true,
          content: () => {
            return corban?.firstRegisterEmail || '';
          }
        },
        {
          name: "E-mail de cadastro secundario",
          attribute: "Email_Cadastro_Secundario_Corban",
          title: "E-mail de cadastro secundário do corban",
          inline: true,
          content: () => {
            return corban?.secondRegisterEmail || '';
          }
        },
        {
          name: "E-mail de cadastro terciário",
          attribute: "Email_Cadastro_Terciario_Corban",
          title: "E-mail de cadastro terciário do corban",
          inline: true,
          content: () => {
            return corban?.thirdRegisterEmail || '';
          }
        },
        {
          name: "E-mail Operacional Principal ",
          attribute: "Email_Operacional_Principal_Corban",
          title: "E-mail operacional principal do corban",
          inline: true,
          content: () => {
            return corban?.firstOperationEmail || '';
          }
        },
        {
          name: "E-mail Operacional Secundário",
          attribute: "Email_Operacional_Secundario_Corban",
          title: "E-mail operacional secundário do corban",
          inline: true,
          content: () => {
            return corban?.secondOperationEmail || '';
          }
        },
        {
          name: "E-mail Operacional Terciário",
          attribute: "Email_Operacional_Terciario_Corban",
          title: "E-mail operacional terciário do corban",
          inline: true,
          content: () => {
            return corban?.thirdOperationEmail || '';
          }
        },
        {
          name: "E-mail Documentos Principal",
          attribute: "Email_Documentos_Principal_Corban",
          title: "E-mail documentos principal do corban",
          inline: true,
          content: () => {
            return corban?.firstDocumentationEmail || '';
          }
        },
        {
          name: "E-mail Documentos Secundário",
          attribute: "Email_Documentos_Secundario_Corban",
          title: "E-mail documentos secundário do corban",
          inline: true,
          content: () => {
            return corban?.secondDocumentationEmail || '';
          }
        },
        {
          name: "E-mail Documentos Terciário",
          attribute: "Email_Documentos_Terciario_Corban",
          title: "E-mail documentos terciário do corban",
          inline: true,
          content: () => {
            return corban?.thirdDocumentationEmail || '';
          }
        },
        {
          name: "E-mail Financeiro Principal",
          attribute: "Email_Financeiro_Principal_Corban",
          title: "E-mail financeiro principal do corban",
          inline: true,
          content: () => {
            return corban?.firstFinancialEmail || '';
          }
        },
        {
          name: "E-mail Financeiro Secundário",
          attribute: "Email_Financeiro_Secundario_Corban",
          title: "E-mail financeiro secundário do corban",
          inline: true,
          content: () => {
            return corban?.secondFinancialEmail || '';
          }
        },
        {
          name: "E-mail Financeiro Terciário",
          attribute: "Email_Financeiro_Terciario_Corban",
          title: "E-mail financeiro terciário do corban",
          inline: true,
          content: () => {
            return corban?.thirdFinancialEmail || '';
          }
        },
        {
          name: "E-mail de acesso",
          attribute: "Email_Acesso_Corban",
          title: "E-mail de acesso do corban",
          inline: true,
          content: () => {
            return corban?.accessEmail || '';
          }
        },
        {
          name: "Banco",
          attribute: "Banco_Conta_Corban",
          title: "Banco do corban",
          inline: true,
          content: () => {
            return corban?.bank || '';
          }
        },
        {
          name: "Agência",
          attribute: "Agencia_Conta_Corban",
          title: "Agência do corban",
          inline: true,
          content: () => {
            return corban?.agency || '';
          }
        },
        {
          name: "Conta",
          attribute: "Conta_Corban",
          title: "Conta do corban",
          inline: true,
          content: () => {
            return corban?.account || '';
          }
        },
        {
          name: "Dígito",
          attribute: "Digito_Conta_Corban",
          title: "Dígito da conta do corban",
          inline: true,
          content: () => {
            return corban?.digit || '';
          }
        },
        {
          name: "CEP",
          attribute: "CEP_Corban",
          title: "CEP do corban",
          inline: true,
          content: () => {
            return corban?.cep || '';
          }
        },
        {
          name: "País",
          attribute: "País_Corban",
          title: "País do corban",
          inline: true,
          content: () => {
            return corban?.country || '';
          }
        },
        {
          name: "Estado",
          attribute: "Estado_Corban",
          title: "Estado do corban",
          inline: true,
          content: () => {
            return corban?.state || '';
          }
        },
        {
          name: "Cidade",
          attribute: "Cidade_Corban",
          title: "Cidade do corban",
          inline: true,
          content: () => {
            return corban?.city || '';
          }
        },
        {
          name: "Bairro",
          attribute: "Bairro_Corban",
          title: "Bairro do corban",
          inline: true,
          content: () => {
            return corban?.neighborhood || '';
          }
        },
        {
          name: "Rua",
          attribute: "Rua_Corban",
          title: "Rua do corban",
          inline: true,
          content: () => {
            return corban?.street || '';
          }
        },
        {
          name: "Número",
          attribute: "Número_Corban",
          title: "Número do corban",
          inline: true,
          content: () => {
            return corban?.number || '';
          }
        },
        {
          name: "Complemento",
          attribute: "Complemento_Corban",
          title: "Complemento do corban",
          inline: true,
          content: () => {
            return corban?.complement || '';
          }
        },
        {
          name: "Endereço",
          attribute: "Endereco_Corban",
          title: "Endereço do corban",
          inline: true,
          content: () => {
            return _getAddress(corban);
          }
        },
        {
          name: "Pix",
          attribute: "Pix_Corban",
          title: "Pix do corban",
          inline: true,
          content: () => {
            return corban?.pix || '';
          }
        },
        {
          name: "Código de primeiro acesso",
          attribute: "Codigo_Primeiro_Acesso",
          title: "Código de primeiro acesso do corban",
          inline: true,
          content: () => {
            return firstAccessCode || '';
          }
        },

      ]
    }
  ]
}

function _getAddress(address) {
  let street = address?.street || '';
  let number = address?.number || '';
  let complement = address?.complement || '';
  let neighborhood = address?.neighborhood || '';
  let city = address?.city || '';
  let state = address?.state || '';
  let cep = address?.cep || '';

  return `${street} ${number} ${complement} ${neighborhood} ${city} ${state} ${cep}`;
}

export default { get }