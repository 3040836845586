<template>
  <v-row
    no-gutters
    class="box"
  >
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12">
              <h1 class="titulo">Spread / Moeda</h1>
              <v-divider />
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="py-2"
          >
            <v-col
              cols="12"
              md="9"
              sm="8"
              align-self="center"
            >
              <v-btn
                class="btn-primary ml-0"
                text
                @click="openAddCurrency"
              >
                <i class="far fa-plus" /> Adicionar
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              md="3"
              sm="4"
              class="pa-0 pt-2 pt-md-0 pr-3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                class="pt-0 mt-0"
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <v-card class="card-cambio">
                <v-data-table
                  :headers="columns"
                  :items="currencies"
                  :search="search"
                  sort-by="id"
                  sort-asc
                  :items-per-page="paginationDefault"
                  :custom-sort="customSort"
                  :custom-filter="caseInsensitiveAccentsInsensitive"
                  height="calc(100dvh - 305px)"
                  fixed-header
                >
                  <template #[`item.priority`]="{ item }">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          left
                          :color="item.priority?.color"
                        >
                          {{ item.priority?.icon }}
                        </v-icon>
                      </template>
                      <span>{{ item.priority?.text }}</span>
                    </v-tooltip>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          color="primary"
                          @click="editCurrencyModal(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="far fa-pencil" />
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <!-- Add Currency Modal -->
    <BaseModal
      v-model="addCurrencyModal"
      title="Moedas"
      :primary-button-action="update ? updateCurrency : addCurrency"
      :secondary-button-action="() => (addCurrencyModal = false)"
      :fullscreen="appData.isMobile"
      modal-width="900px"
      :max-height-body="appData.isMobile ? '100vh' : '210px'"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <v-form
            id="add-currency"
            ref="form"
          >
            <v-row>
              <v-col
                class="py-0"
                cols="12"
                md="2"
              >
                <v-text-field
                  outlined
                  dense
                  label="Código"
                  v-model="currency.code"
                  :rules="[validationIsRequiredField]"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="6"
              >
                <v-text-field
                  outlined
                  dense
                  label="Nome"
                  v-model="currency.name"
                  :rules="[validationIsRequiredField]"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="2"
              >
                <v-select
                  :menu-props="{ offsetY: true }"
                  :items="precisionList"
                  outlined
                  dense
                  label="Casas decimais"
                  v-model="currency.precision"
                  botton
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="2"
              >
                <v-select
                  outlined
                  dense
                  label="Prioridade"
                  v-model="currency.priority"
                  :items="priorities"
                  item-text="text"
                  item-value="value"
                  return-object
                  :rules="[validationIsRequiredField]"
                  :menu-props="{ offsetY: true }"
                >
                  <template #item="{ item }">
                    <v-icon
                      left
                      :color="item.color"
                    >
                      {{ item.icon }}
                    </v-icon>
                    {{ item.text }}
                  </template>
                </v-select>
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <NumberField
                  v-model="currency.minimumSpreadSell"
                  outlined
                  dense
                  label="Spread mínimo venda remessa(%)"
                  suffix="%"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <NumberField
                  v-model="currency.recommendedSpreadSell"
                  outlined
                  dense
                  label="Spread recomendado venda remessa(%)"
                  suffix="%"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <NumberField
                  v-model="currency.maximumSpreadSell"
                  outlined
                  dense
                  label="Spread máximo venda remessa(%)"
                  suffix="%"
                />
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <NumberField
                  v-model="currency.minimumSpreadBuy"
                  outlined
                  dense
                  label="Spread mínimo compra remessa(%)"
                  suffix="%"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <NumberField
                  v-model="currency.recommendedSpreadBuy"
                  outlined
                  dense
                  label="Spread recomendado compra remessa(%)"
                  suffix="%"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <NumberField
                  v-model="currency.maximumSpreadBuy"
                  outlined
                  dense
                  label="Spread máximo compra remessa(%)"
                  suffix="%"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </BaseModal>
  </v-row>
</template>

<script>
// Api
import CurrencyApi from '@/api/spread/currency-api';

// Models
import CurrencyModel from '@/model/currency-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinDataTableFilters from '@/mixin/mixin-data-table-filters';
import mixinValidationRules from "@/mixin/mixin-validation-rules";

// Components
import NumberField from '@/components/comum/NumberField';
import { PAGINATION_DEFAULT } from '../../../constants/general-constants';
import BaseModal from '@/components/comum/BaseModal';

// Utils
import { customSortDataTable } from '@/utils/dataTable';

export default {
  name: 'CurrenciesControl',
  mixins: [mixinMessage, mixinDataTableFilters, mixinValidationRules],
  components: { NumberField, BaseModal },
  inject: ['appData'],
  data() {
    return {
      search: '',
      currencies: [],
      columns: [
        { text: 'Código', value: 'code', align: 'left', sortable: true, width: '100px' },
        { text: 'Prioridade', value: 'priority', align: 'center', sortable: true, width: '110px' },
        { text: 'Nome', value: 'name', align: 'left', sortable: true },
        {
          text: 'Mínimo venda remessa(%)',
          value: 'minimumSpreadSell',
          align: 'center',
          sortable: true
        },
        {
          text: 'Recomendado venda remessa(%)',
          value: 'recommendedSpreadSell',
          align: 'center',
          sortable: true
        },
        {
          text: 'Máximo venda remessa(%)',
          value: 'maximumSpreadSell',
          align: 'center',
          sortable: true
        },
        {
          text: 'Mínimo compra remessa(%)',
          value: 'minimumSpreadBuy',
          align: 'center',
          sortable: true
        },
        {
          text: 'Recomendado compra remessa(%)',
          value: 'recommendedSpreadBuy',
          align: 'center',
          sortable: true
        },
        {
          text: 'Máximo compra remessa(%)',
          value: 'maximumSpreadBuy',
          align: 'center',
          sortable: true
        },
        { text: 'Casas decimais', value: 'precision', align: 'center', sortable: true },
        { text: '', value: 'actions', align: 'right', sortable: false }
      ],
      priorities: [
        { text: 'Máxima', value: 5, icon: 'mdi-chevron-double-up', color: 'red' },
        { text: 'Alta', value: 4, icon: 'mdi-chevron-up', color: 'red' },
        { text: 'Média', value: 3, icon: 'mdi-equal', color: 'orange' },
        { text: 'Baixa', value: 2, icon: 'mdi-chevron-down', color: 'blue' },
        { text: 'Mínima', value: 1, icon: 'mdi-chevron-double-down', color: 'blue' }
      ],
      currency: new CurrencyModel(),
      addCurrencyModal: false,
      update: false,
      paginationDefault: PAGINATION_DEFAULT,
      currencyApi: new CurrencyApi(this.appData.currentUser),
      precisionList: Array.from({ length: 9 }, (_, i) => i + 2)
    };
  },
  mounted() {
    this.getCurrencyList();
  },
  methods: {
    getCurrencyList() {
      this.currencyApi
        .findAll()
        .then((response) => {
          this.currencies = response.data.map((n) => {
            const currency = new CurrencyModel(n);
            currency.priority = this.priorities.find((p) => p.value === currency.priority);

            return currency;
          });
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    customSort(items, sortBy, sortDesc) {
      const customFields = [
        {
          field: 'priority',
          getDescription: (a, b) => {
            const valueA = a.priority?.value || 0;
            const valueB = b.priority?.value || 0;

            return { valueA, valueB };
          }
        }
      ];

      return customSortDataTable(items, sortBy, sortDesc, customFields);
    },

    openAddCurrency() {
      this.update = false;
      this.currency = new CurrencyModel();
      this.addCurrencyModal = true;
    },

    editCurrencyModal(currency) {
      this.update = true;
      this.currency = new CurrencyModel(currency);
      this.addCurrencyModal = true;
    },

    addCurrency() {
      if (!this.isValid()) return;

      this.$eventBus.$emit('show-loading', true);
      this.currency.priority = this.currency.priority.value;
      this.currencyApi
        .add(this.currency)
        .then((response) => {
          this.currencies.push(new CurrencyModel(response.data));
          this.addCurrencyModal = false;
          this.getCurrencyList();
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    updateCurrency() {
      if (!this.isValid()) return;

      this.currency.priority = this.currency.priority.value;
      this.currencyApi
        .update(this.currency)
        .then((response) => {
          let index = this.currencies.findIndex((c) => c.id == this.currency.id);
          this.currencies.splice(index, 1, new CurrencyModel(response.data));
          this.addCurrencyModal = false;
          this.getCurrencyList();
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    isValid() {
      return this.$refs.form.validate();
    }
  }
};
</script>

<style scoped></style>
