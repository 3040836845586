/* eslint-disable no-console */
function askNotificationPermission() {
  if (Notification.permission === 'granted') {
    console.log('Permissão já concedida.');

    return;
  }

  if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        console.log('Permissão para notificações concedida');
      } else {
        console.log('Permissão para notificações negada');
      }
    });
  }
}

function sendPushNotification(socketData) {
  if (Notification.permission === 'granted') {
    new Notification(socketData.title, {
      body: socketData.message,
      tag: 'websocket-message'
    });
  } else {
    console.log('Permissão para notificações não foi concedida');
    askNotificationPermission();
  }
}

export { sendPushNotification, askNotificationPermission };
