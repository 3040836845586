<template>
  <v-row class="py-2">
    <v-col
      v-if="isImportOrExportOperation"
      class="py-0"
      cols="12"
      md="3"
    >
      <legend>Já realizou importação ou exportação anteriormente?</legend>
      <v-radio-group
        data-test="rd-import-export"
        v-model="customer.additional.importedOrExportedBefore"
        row
        :rules="[validationIsRequiredRadioBtn]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      >
        <v-radio
          data-test="rd-sim"
          :value="true"
          label="Sim"
        />
        <v-radio
          data-test="rd-nao"
          :value="false"
          label="Não"
        />
      </v-radio-group>
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="12"
    >
      <v-autocomplete
        data-test="slc-proposito-relacionamento"
        label="Propósito da relação de negócios"
        :items="businessPurposeList"
        item-text="description"
        item-value="id"
        v-model="customer.compliance.purposeBusinessRelationship"
        return-object
        clearable
        dense
        outlined
        multiple
        hint="Este campo é obrigatório"
        v-disabled-icon-focus
        :rules="[validationIsRequiredAutocompleteMultiple]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      v-if="showOtherBusinessPurpose"
      cols="12"
      md="4"
      class="py-0 pr-0"
    >
      <v-text-field
        data-test="txt-modalidade-radar"
        v-model="customer.compliance.otherPurposeBusinessRelationship"
        outlined
        dense
        label="Modalidade do Radar:"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>

    <template v-if="showHabilitationMode">
      <v-col
        cols="12"
        md="4"
        class="py-0 pr-0"
      >
        <v-autocomplete
          data-test="slc-modalidade-radar-habilitacao"
          label="Modalidade do Radar:"
          :items="habilitationModeList"
          item-value="id"
          item-text="description"
          v-model="customer.compliance.habilitationMode"
          return-object
          clearable
          dense
          outlined
          hint="Este campo é obrigatório"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteId]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="12"
      >
        <legend>Modalidade de Pagamento:</legend>
        <v-radio-group
          data-test="rd-modalidade-pagamento"
          v-model="customer.compliance.paymentMode.id"
          row
          :rules="[validationIsRequiredRadioBtn]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        >
          <v-radio
            v-for="(item, i) in paymentModeList"
            :key="i"
            :value="item.id"
            :label="item.description"
            :data-test="`rd-modalidade-pagamento-${item.id}`"
          />
        </v-radio-group>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="12"
      >
        <legend>Informe o Modo Operante:</legend>
        <v-radio-group
          data-test="rd-modo-operante"
          v-model="customer.compliance.operatingMode.id"
          row
          :rules="[validationIsRequiredRadioBtn]"
          :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
        >
          <v-radio
            v-for="(item, i) in operatingModeList"
            :key="i"
            :value="item.id"
            :label="item.description"
            :data-test="`rd-modo-operante-${item.id}`"
          />
        </v-radio-group>
      </v-col>
    </template>

    <template v-if="customer.additional.importedOrExportedBefore">
      <v-col
        cols="12"
        md="12"
      >
        <v-row
          v-for="(supplier, index) in customer.compliance.suppliersProxy"
          :key="index"
        >
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              data-test="txt-fornecedor"
              outlined
              dense
              :label="'Fornecedor ' + (index + 1)"
              v-model="supplier.name"
              :rules="[validationIsRequiredField]"
              :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
            >
              <template #append-outer>
                <v-btn
                  data-test="btn-remover-fornecedor"
                  class="pb-2"
                  v-if="customer.compliance.suppliersProxy.length >= 2"
                  icon
                  @click="removeSupplier(index)"
                  text
                >
                  <i class="far fa-trash" />
                </v-btn>
                <v-btn
                  data-test="btn-adicionar-fornecedor"
                  class="pb-2"
                  icon
                  @click="insertSupplier"
                  text
                >
                  <i class="far fa-plus" />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-row
          v-for="(product, index) in customer.compliance.productsProxy"
          :key="index"
        >
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              data-test="txt-produto"
              outlined
              dense
              :label="'Produto ' + (index + 1)"
              v-model="product.name"
              :rules="[validationIsRequiredField]"
              :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
            >
              <template #append-outer>
                <v-btn
                  data-test="btn-remover-produto"
                  class="pb-2"
                  v-if="customer.compliance.productsProxy.length >= 2"
                  icon
                  @click="removeProduct(index)"
                  text
                >
                  <i class="far fa-trash" />
                </v-btn>
                <v-btn
                  data-test="btn-adicionar-produto"
                  class="pb-2"
                  icon
                  @click="insertProduct"
                  text
                >
                  <i class="far fa-plus" />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-row
          v-for="(typeExportedProducts, index) in customer.compliance.typeExportedProductsProxy"
          :key="index"
          :data-test="`row-tipo-produto-${index}`"
        >
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              data-test="txt-tipo-produto"
              outlined
              dense
              :label="'Tipo de produto ' + (index + 1)"
              v-model="typeExportedProducts.name"
              :rules="[validationIsRequiredField]"
              :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
            >
              <template #append-outer>
                <v-btn
                  data-test="btn-remover-tipo-produto"
                  class="pb-2"
                  v-if="customer.compliance.typeExportedProductsProxy.length >= 2"
                  icon
                  @click="removeTypeExportedProduct(index)"
                  text
                >
                  <i class="far fa-trash" />
                </v-btn>
                <v-btn
                  data-test="btn-adicionar-tipo-produto"
                  class="pb-2"
                  icon
                  @click="insertTypeExportedProduct"
                  text
                >
                  <i class="far fa-plus" />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-checkbox
          data-test="chk-di-registrada"
          label="Possui alguma DI registrada nos últimos 6 meses?"
          v-model="customer.compliance.registeredDiLast6Months"
          dense
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
        v-if="customer.compliance.registeredDiLast6Months"
      >
        <v-row
          v-for="(di, index) in customer.compliance.disProxy"
          :key="index"
        >
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              data-test="txt-di"
              outlined
              dense
              v-mask="'##/######-#'"
              :label="'DI ' + (index + 1)"
              v-model="di.name"
              :rules="[validationIsRequiredField]"
              :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
            >
              <template #append-outer>
                <v-btn
                  data-test="btn-remover-di"
                  class="pb-2"
                  v-if="customer.compliance.disProxy.length >= 2"
                  icon
                  @click="removeDi(index)"
                  text
                >
                  <i class="far fa-trash" />
                </v-btn>
                <v-btn
                  data-test="btn-adicionar-di"
                  class="pb-2"
                  icon
                  @click="insertDi"
                  text
                >
                  <i class="far fa-plus" />
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <MoneyField
        data-test="txt-montante-estimado"
        label="Montante Estimado Anual (US$)"
        prefix="US$ "
        v-model="customer.compliance.estimatedAmount"
        :rules="[validationIsRequiredFieldNumeric]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="10"
    >
      <v-checkbox
        data-test="chk-processo-crime"
        label="A empresa e/ou seus sócios respondem (ou responderam) por processo crime, inquérito policial e outras ocorrências ou circunstâncias análogas? Caso afirmativo, explique nas informações adicionais."
        v-model="customer.compliance.answerCrimes"
        dense
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="12"
    >
      <v-textarea
        data-test="txt-informacoes-adicionais"
        auto-grow
        outlined
        rows="3"
        v-model="customer.compliance.additionalInformation"
        label="Informações adicionais"
      />
    </v-col>
  </v-row>
</template>

<script>
// Constants
import {
  BUSINESS_PURPOSE_TYPE,
  PAYMENT_MODE,
  HABILITATION_MODE,
  OPERATING_MODE
} from '@/constants/general-constants.js';
import { CUSTOMER_FORM_TAB } from "@/constants/customer-form-constants";

// Components
import MoneyField from '@/components/comum/MoneyField.vue';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

export default {
  name: 'PJCustomerCompliance',
  mixins: [mixinValidationRules],
  components: {
    MoneyField
  },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      customer: this.customerProp,
      businessPurposeList: BUSINESS_PURPOSE_TYPE,
      paymentModeList: PAYMENT_MODE,
      habilitationModeList: HABILITATION_MODE,
      operatingModeList: OPERATING_MODE,
      customerFormTab: CUSTOMER_FORM_TAB
    };
  },
  mounted() {
    if (this.customer.compliance.suppliersProxy.length == 0) {
      this.customer.compliance.suppliersProxy = [{ name: '' }];
    }

    if (this.customer.compliance.productsProxy.length == 0) {
      this.customer.compliance.productsProxy = [{ name: '' }];
    }

    if (this.customer.compliance.typeExportedProductsProxy.length == 0) {
      this.customer.compliance.typeExportedProductsProxy = [{ name: '' }];
    }

    if (this.customer.compliance.disProxy.length == 0) {
      this.customer.compliance.disProxy = [{ name: '' }];
    }
  },
  watch: {
    customer: {
      handler() {
        this.$emit('onChange', this.customer);
      },
      deep: true
    },
    'customer.additional.importedOrExportedBefore'() {
      if (this.customer.additional.importedOrExportedBefore == false) {
        this.customer.compliance.paymentMode.id = 1;
      }
    }
  },
  computed: {
    //TODO remover numero magico
    showOtherBusinessPurpose() {
      return (
        this.customer &&
        this.customer.compliance.purposeBusinessRelationship?.find((purpose) => purpose.id == 7)
      );
    },
    showHabilitationMode() {
      return (
        this.customer &&
        this.customer.compliance.purposeBusinessRelationship?.find(
          (purpose) => purpose.id == 1 || purpose.id == 3
        )
      );
    },
    isImportOrExportOperation() {
      return this.customer && !!this.customer.additional.operationNature?.useDiDue;
    }
  },
  methods: {
    insertSupplier() {
      this.customer.compliance.suppliersProxy.push({ name: '' });
    },
    removeSupplier(index) {
      if (this.customer.compliance.suppliersProxy.length > 1) {
        this.customer.compliance.suppliersProxy.splice(index, 1);
      }
    },
    insertProduct() {
      this.customer.compliance.productsProxy.push({ name: '' });
    },
    removeProduct(index) {
      if (this.customer.compliance.productsProxy.length > 1) {
        this.customer.compliance.productsProxy.splice(index, 1);
      }
    },
    insertTypeExportedProduct() {
      this.customer.compliance.typeExportedProductsProxy.push({ name: '' });
    },
    removeTypeExportedProduct(index) {
      if (this.customer.compliance.typeExportedProductsProxy.length > 1) {
        this.customer.compliance.typeExportedProductsProxy.splice(index, 1);
      }
    },
    insertDi() {
      this.customer.compliance.disProxy.push({ name: '' });
    },
    removeDi(index) {
      if (this.customer.compliance.disProxy.length > 1) {
        this.customer.compliance.disProxy.splice(index, 1);
      }
    }
  }
};
</script>
<style></style>
