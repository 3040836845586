import utilsNumber from "../../../utils/number";
function get(customer, token, bank, externalAccessDocumentToken, complementaryInfo) {
  return [
    {
      name: "Sócios",
      children: [
        {
          name: "Nome ou razão social (Pessoa 1)",
          attribute: "Nome_Ou_Razao_Socical_Socio_1",
          title: "Nome / Razão social sócio (Pessoa 1)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[0]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 1)",
          attribute: "CPF_Ou_CNPJ_Socio_1",
          title: "CPF ou CNPJ sócio (Pessoa 1)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[0]?.cpfCnpj || '';
          }
        },
        {
          name: "Nacionalidade (Pessoa 1)",
          attribute: "Nacionalidade_Socio_1",
          title: "Nacionalidade sócio (Pessoa 1)",
          inline: true,
          content: () => {
            if (!customer?.partnersShareholders?.[0]?.linkedCustomerId) return '';

            return customer?.partnersShareholders?.[0]?.isBrazilian ? 'Brasileiro' : 'Estrangeiro';
          }
        },
        {
          name: "Renda média mensal (Pessoa 1)",
          attribute: "Renda_Media_Mensal_Socio_1",
          title: "Renda média mensal sócio (Pessoa 1)",
          inline: true,
          content: () => {
            return utilsNumber.formatCurrency('BRL', customer?.partnersShareholders?.[0]?.averageMonthlyIncome);
          }
        }
        ,
        {
          name: "Nome ou razão social (Pessoa 2)",
          attribute: "Nome_Ou_Razao_Socical_Socio_2",
          title: "Nome / Razão social sócio (Pessoa 2)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[1]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 2)",
          attribute: "CPF_Ou_CNPJ_Socio_2",
          title: "CPF ou CNPJ sócio (Pessoa 2)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[1]?.cpfCnpj || '';
          }
        },
        {
          name: "Nacionalidade (Pessoa 2)",
          attribute: "Nacionalidade_Socio_2",
          title: "Nacionalidade sócio (Pessoa 2)",
          inline: true,
          content: () => {
            if (!customer?.partnersShareholders?.[1]?.linkedCustomerId) return '';

            return customer?.partnersShareholders?.[1]?.isBrazilian ? 'Brasileiro' : 'Estrangeiro';
          }
        },
        {
          name: "Renda média mensal (Pessoa 2)",
          attribute: "Renda_Media_Mensal_Socio_2",
          title: "Renda média mensal sócio (Pessoa 2)",
          inline: true,
          content: () => {
            return utilsNumber.formatCurrency('BRL', customer?.partnersShareholders?.[1]?.averageMonthlyIncome);
          }
        },
        {
          name: "Nome ou razão social (Pessoa 3)",
          attribute: "Nome_Ou_Razao_Socical_Socio_3",
          title: "Nome / Razão social sócio (Pessoa 3)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[2]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 3)",
          attribute: "CPF_Ou_CNPJ_Socio_3",
          title: "CPF ou CNPJ sócio (Pessoa 3)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[2]?.cpfCnpj || '';
          }
        },
        {
          name: "Nacionalidade (Pessoa 3)",
          attribute: "Nacionalidade_Socio_3",
          title: "Nacionalidade sócio (Pessoa 3)",
          inline: true,
          content: () => {
            if (!customer?.partnersShareholders?.[2]?.linkedCustomerId) return '';

            return customer?.partnersShareholders?.[2]?.isBrazilian ? 'Brasileiro' : 'Estrangeiro';
          }
        },
        {
          name: "Renda média mensal (Pessoa 3)",
          attribute: "Renda_Media_Mensal_Socio_3",
          title: "Renda média mensal sócio (Pessoa 3)",
          inline: true,
          content: () => {
            return utilsNumber.formatCurrency('BRL', customer?.partnersShareholders?.[2]?.averageMonthlyIncome);
          }
        },
        {
          name: "Nome ou razão social (Pessoa 4)",
          attribute: "Nome_Ou_Razao_Socical_Socio_4",
          title: "Nome / Razão social sócio (Pessoa 4)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[3]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 4)",
          attribute: "CPF_Ou_CNPJ_Socio_4",
          title: "CPF ou CNPJ sócio (Pessoa 4)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[3]?.cpfCnpj || '';
          }
        },
        {
          name: "Nacionalidade (Pessoa 4)",
          attribute: "Nacionalidade_Socio_4",
          title: "Nacionalidade sócio (Pessoa 4)",
          inline: true,
          content: () => {
            if (!customer?.partnersShareholders?.[3]?.linkedCustomerId) return '';

            return customer?.partnersShareholders?.[3]?.isBrazilian ? 'Brasileiro' : 'Estrangeiro';
          }
        },
        {
          name: "Renda média mensal (Pessoa 4)",
          attribute: "Renda_Media_Mensal_Socio_4",
          title: "Renda média mensal sócio (Pessoa 4)",
          inline: true,
          content: () => {
            return utilsNumber.formatCurrency('BRL', customer?.partnersShareholders?.[3]?.averageMonthlyIncome);
          }
        },
        {
          name: "Nome ou razão social (Pessoa 5)",
          attribute: "Nome_Ou_Razao_Socical_Socio_5",
          title: "Nome / Razão social sócio (Pessoa 5)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[4]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 5)",
          attribute: "CPF_Ou_CNPJ_Socio_5",
          title: "CPF ou CNPJ sócio (Pessoa 5)",
          inline: true,
          content: () => {
            return customer?.partnersShareholders?.[4]?.cpfCnpj || '';
          }
        },
        {
          name: "Nacionalidade (Pessoa 5)",
          attribute: "Nacionalidade_Socio_5",
          title: "Nacionalidade sócio (Pessoa 5)",
          inline: true,
          content: () => {
            if (!customer?.partnersShareholders?.[4]?.linkedCustomerId) return '';

            return customer?.partnersShareholders?.[4]?.isBrazilian ? 'Brasileiro' : 'Estrangeiro';
          }
        },
        {
          name: "Renda média mensal (Pessoa 5)",
          attribute: "Renda_Media_Mensal_Socio_5",
          title: "Renda média mensal sócio (Pessoa 5)",
          inline: true,
          content: () => {
            return utilsNumber.formatCurrency('BRL', customer?.partnersShareholders?.[4]?.averageMonthlyIncome);
          }
        }
      ]
    }
  ]
}

export default { get }