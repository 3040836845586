<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1 class="titulo">Apis de cotação</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            cols="12"
            lg="3"
            sm="6"
            offset-lg="9"
            offset-sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="natures"
                :search="search"
                sort-by="code"
                sort-asc
                :items-per-page="paginationDefault"
                :custom-filter="caseInsensitiveAccentsInsensitive"
              >
                <template #[`item.active`]="{ item }">
                  <template v-if="item.active">
                    <i class="far fa-check-circle fa-lg" />
                  </template>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="setActive(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-check" />
                      </v-btn>
                    </template>
                    <span>Definir como ativo</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Api
import apiRateApiRoute from "@/api/configuration/rate-api-route-api";

// Components

// Models
import RateApiRouteModel from "@/model/rate-api-route-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";
import { PAGINATION_DEFAULT } from '../../../constants/general-constants';

export default {
  name: "RateApiRouteControl",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: {  },
  data() {
    return {
      search: "",
      natures: [],
      columns: [
        { text: "Nome", value: "name", align: "left", sortable: true, width: "85%" },
        { text: "Ativo", value: "active", align: "center", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      paginationDefault: PAGINATION_DEFAULT
    };
  },
  watch: {},
  mounted() {
    this.getRateApiList();
  },
  computed: {},
  methods: {
    getRateApiList() {
      apiRateApiRoute
        .findAll()
        .then((response) => {
          this.natures = response.data.map((r) => new RateApiRouteModel(r));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    setActive(nature) {
      if (!nature.id) {
        return;
      }

      apiRateApiRoute
        .setActive(nature.id)
        .then(() => {
          this.getRateApiList();
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
