<template>
  <v-dialog
    v-model="localOpen"
    eagle
    persistent
    :width="fullscreen ? '' : modalWidth"
    :height="fullscreen ? '' : modalHeight"
    :fullscreen="fullscreen"
  >
    <v-card v-if="localOpen">
      <v-card-title v-if="!!title">
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col>
                <h1 class="titulo-modal">{{ title }}</h1>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>


      <v-card-text
        :style="heightStyle"
        class="py-4"
      >
        <v-row no-gutters>
          <v-col>
            <slot />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-0 ma-0">
        <v-col class="text-right">
          <v-btn
            v-if="!hidePrimaryButton"
            text
            class="btn-primary mr-2"
            @click="primaryButtonAction"
          >
            {{ primaryButtonText }}
          </v-btn>
          <v-btn
            text
            class="btn-tertiary"
            @click="secondaryButtonAction"
          >
            {{ secondaryButtonText }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseModal',

  inject: ['appData'],

  model: {
    prop: 'open',
    event: 'onChange',
  },

  props: {
    open: { type: Boolean, default: false },
    title: { type: String, default: '' },
    primaryButtonAction: { type: Function, default: () => {} },
    secondaryButtonAction: { type: Function, default: () => {} },
    primaryButtonText: { type: String, default: 'Salvar' },
    secondaryButtonText: { type: String, default: 'Cancelar' },
    hidePrimaryButton: { type: Boolean, default: false },
    modalWidth: { type: String, default: '80dvh'},
    modalHeight: { type: String, default: '80dvh'},
    maxHeightBody: { type: String, default: '70dvh'},
    fullscreen: { type: Boolean, default: false }
  },

  computed: {
    localOpen: {
      get() {
        return this.open;
      },
      set(valor) {
        this.$emit('onChange', valor);
      }
    },

    heightStyle() {
      const titleLimit = 30;
      const defaultHeight = '120px';
      const heightToDisconsider = '150px';

      let height = defaultHeight;

      if (this.appData.isMobile)
        height = this.title?.length > titleLimit
          ? heightToDisconsider
          : defaultHeight;

      return {
        height: this.fullscreen ? `calc(100dvh - ${height})` : this.maxHeightBody,
        overflow: 'auto'
      };
    }
  },
};
</script>
