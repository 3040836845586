import getApi from '../api';

function add(agreement, natureId) {
  agreement = agreement || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post(`/operation-natures/${natureId}/agreements`, agreement)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(agreement, natureId) {
  agreement = agreement || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/operation-natures/${natureId}/agreements/${agreement.id}`, agreement)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findByNature(natureId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/operation-natures/${natureId}/agreements`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(agreementId, natureId) {
  agreementId = agreementId || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/operation-natures/${natureId}/agreements/${agreementId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findByNature,
  findId,
  add,
  update
};
