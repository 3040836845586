
export default class CommissionStatementModel {
  constructor(obj) {
    obj = obj || {};

    this.date = obj.date;
    this.operationId = obj.operationId;
    this.agentValue = obj.agentValue;
    this.indicatorValue = obj.indicatorValue;
    this.commissionValue = obj.commissionValue;
    this.withdrawalValue = obj.withdrawalValue;
    this.withdrawalRequestDate = obj.withdrawalRequestDate;
    this.withdrawalPaymentDate = obj.withdrawalPaymentDate;
    this.nf = obj.nf;
    this.proof = obj.proof;
    this.corban = obj.corban;
  }
}
