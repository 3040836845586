<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          min-width="100px"
          class="my-5"
        />
        <v-divider />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <div class="box">
          <h1>Documentos</h1>
          <v-row class="pt-3">
            <v-col
              class="py-0"
              cols="12"
              md="12"
            >
              <v-divider class="py-3" />
            </v-col>
          </v-row>

          <v-row>
            <template v-for="(operationDocument, i) in documents">
              <v-col
                v-if="i != 0 && i % 2 == 0"
                class="py-4"
                cols="12"
                md="12"
                :key="'dividerDocument' + i"
              >
                <v-divider />
              </v-col>
              <v-col
                class="py-5"
                cols="12"
                md="6"
                :key="'fileDocument' + i"
              >
                <DocumentInput
                  :description="operationDocument.operationDocumentType.description"
                  v-model="documents[i]"
                  :api="operationDocumentApi"
                  hide-delete
                  :file-type="fileType.OPERATION_DOCUMENT"
                />
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <div class="box">
          <h1>Documentos Complementares</h1>
          <v-row class="pt-3">
            <v-col
              class="py-0"
              cols="12"
              md="12"
            >
              <v-divider class="py-3" />
            </v-col>

            <template v-for="(document, i) in customerOperationalDocuments">
              <v-col
                v-if="i != 0 && i % 2 == 0"
                class="py-4"
                cols="12"
                md="12"
                :key="'dividerDocument' + i"
              >
                <v-divider />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="6"
                :key="'operationalDocuments' + i"
              >
                <DocumentInput
                  :description="document.operationalDocumentType.description"
                  v-model="customerOperationalDocuments[i]"
                  :api="apiCustomerOperationalDocuments"
                  hide-delete
                  :file-type="fileType.CUSTOMER_OPERATIONAL_DOCUMENT"
                />
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import DocumentInput from "@/components/comum/DocumentInput";

// Constants
import { FILE_TYPE, } from "@/constants/general-constants.js";

// Apis
import OperationDocumentApi from "@/api/operation/operation-documents-api";
import CustomerOperationalDocumentsApi from "@/api/customer/customer-operational-documents-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Models
import OperationExternalAccessDocumentsModel from "@/model/operation/operation-external-access-documents-model";

// Utils
import storage from "@/utils/storage.js";


export default {
  name: "OperationDocumentsExternalAccess",
  mixins: [
    mixinMessage,
  ],
  props: {},
  components: {
    DocumentInput,
  },
  inject: ['appData'],
  data() {
    return {
      loading: false,
      documents: [],
      customerOperationalDocuments: [],
      apiCustomerOperationalDocuments: new CustomerOperationalDocumentsApi(this.appData.currentUser),
      fileType: FILE_TYPE,
      operationDocumentApi: new OperationDocumentApi(this.appData.currentUser),
    };
  },
  mounted() {
    storage.saveTokenOnStorage(this.$route.params.token);
    this.getDocuments();
  },
  watch: { },
  computed: { },
  methods: {
    getDocuments() {
      this.operationDocumentApi
        .findExternalAccess()
        .then((response) => {
          let externalAccessDocuments = new OperationExternalAccessDocumentsModel(response.data);
          this.documents = externalAccessDocuments.documents;
          this.customerOperationalDocuments = externalAccessDocuments.customerOperationalDocuments;
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
