'use strict';

import dateVariables from './variables/date-variables.js';
import customerVariables from './variables/customer-variables.js';
import corbanVariables from './variables/corban-variables.js';
import operationVariables from './variables/operation-variables.js';
import userVariables from './variables/user-variables.js';
import exchangeBankVariables from './variables/exchange-bank-variables.js';
import directorsProxiesVariables from './variables/directors-proxies-variables.js';
import partnersVariables from './variables/partners-variables.js';

/**
 * Método para construir e retornar o array de variáveis baseadas no modelo.
 * @param {CustomerModel} customer Customer model
 * @param {CorbanModel} corban Corban model
 * @param {String} token TokenJWT gerado para URL externa
 * @param {OperationModel} operation Operation model
 * @param {UserModel} user User model
 * @param {String} resetPasswordCode Código enviado por e-mail para redefinir a senha
 * @param {ExchangeBankModel} exchangeBank Bank model
 * @param {String} customerExternalAccessDocumentToken Token JWT para acesso externo de documentos do cliente
 * @param {String} operationExternalAccessDocumentToken Token JWT para acesso externo de documentos da operação
 * @param {String} customerComplementaryInfo Complementary info to be directly injected in the template for the customer
 * @param {String} bankComplementaryInfo Complementary info to be directly injected in the template for the bank
 * @param {String} firstAccessCode Código enviado por e-mail para o primeiro acesso da conta
 * @returns {Array.<Object>} Retorna o Array de variáveis baseadas no modelo
 */
function getSystemVariables(customer, corban, token, operation, user, resetPasswordCode, exchangeBank, customerExternalAccessDocumentToken, operationExternalAccessDocumentToken, customerComplementaryInfo, bankComplementaryInfo, firstAccessCode) {

  return [].concat(
    dateVariables.get(),
    customerVariables.get(customer, token, exchangeBank, customerExternalAccessDocumentToken, customerComplementaryInfo),
    directorsProxiesVariables.get(customer, token, exchangeBank, customerExternalAccessDocumentToken, customerComplementaryInfo),
    partnersVariables.get(customer, token, exchangeBank, customerExternalAccessDocumentToken, customerComplementaryInfo),
    corbanVariables.get(corban, firstAccessCode),
    operationVariables.get(operation, operationExternalAccessDocumentToken),
    userVariables.get(user, resetPasswordCode),
    exchangeBankVariables.get(exchangeBank, bankComplementaryInfo),
  );
}

/**
 * Método para substituir todas as variáveis do template.
 * @param {String} templateContent Conteúdo do template
 * @param {CustomerModel} customer Customer model
 * @param {CorbanModel} corban Corban model
 * @param {String} token TokenJWT gerado para URL externa 
 * @param {OperationModel} operation Operation model
 * @param {UserModel} user User model
 * @param {String} resetPasswordCode Código enviado por e-mail para redefinir a senha
 * @param {ExchangeBankModel} exchangeBank Bank model
 * @param {String} customerExternalAccessDocumentToken Token JWT para acesso externo de documentos do cliente
 * @param {String} operationExternalAccessDocumentToken Token JWT para acesso externo de documentos da operação
 * @param {String} customerComplementaryInfo Complementary info to be directly injected in the template for the customer
 * @param {String} bankComplementaryInfo Complementary info to be directly injected in the template for the bank
 * @param {String} firstAccessCode Código enviado por e-mail para o primeiro acesso da conta
 * @returns {String} Retorna o conteúdo do template com as variáveis substituídas
 */
function replaceAllVariablesTemplate(templateContent, customer, corban, token, operation, user, resetPasswordCode, exchangeBank, customerExternalAccessDocumentToken, operationExternalAccessDocumentToken, customerComplementaryInfo, bankComplementaryInfo, firstAccessCode) {

  let variables = getSystemVariables(customer, corban, token, operation, user, resetPasswordCode, exchangeBank, customerExternalAccessDocumentToken, operationExternalAccessDocumentToken, customerComplementaryInfo, bankComplementaryInfo, firstAccessCode);

  let templateReplaced = templateContent;

  variables.map((item) => {
    item.children.forEach((variable) => {
      let replace = `((<span custom-variable="@@${variable.attribute.toLowerCase()}@@")[A-z, :, 0-9, ?, >, ç, ã, á, õ, ê, ô, é, ó, ú, í, ", ., =, \\-, (, ),;,#,&,\\/]*(<\\/span>))`;
      let regex = new RegExp(replace, "ig");
      (templateReplaced.match(regex) || []).forEach((elemento) => {
        templateReplaced = templateReplaced.replace(
          elemento,
          variable.content()
        );
      });
    });
  });

  return templateReplaced;
}

export default { getSystemVariables, replaceAllVariablesTemplate };