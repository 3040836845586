import getApi from '../api';

function findAll() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/access-profile')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/access-profile/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function add(accessProfile) {
  accessProfile = accessProfile || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/access-profile', accessProfile)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(accessProfile) {
  accessProfile = accessProfile || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/access-profile/${accessProfile.id}`, accessProfile)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function inactive(accessProfile) {
  accessProfile = accessProfile || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .patch(`/access-profile/${accessProfile.id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
  inactive
};
