<template>
  <div
    class="mx-2 mt-4 ma-md-5"
    data-test="controle-usuarios"
  >
    <!-- Skeleton Loader -->
    <v-row
      v-if="loading"
      data-test="skeleton-loader"
    >
      <v-col cols="12">
        <v-skeleton-loader
          type="button"
          min-width="100px"
          data-test="skeleton-loader-button"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
          data-test="skeleton-loader-thead"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
          data-test="skeleton-loader-tbody"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
          data-test="skeleton-loader-tfoot"
        />
      </v-col>
    </v-row>

    <v-row
      v-else
      no-gutters
    >
      <v-col>
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="titulo">Controle de usuários</h1>
            <v-divider />
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="py-2"
        >
          <v-col
            cols="12"
            md="9"
            sm="8"
            align-self="center"
          >
            <v-btn
              data-test="btn-adicionar-usuario"
              class="btn-primary ml-0"
              text
              @click="addUserDialog"
            >
              <i class="far fa-plus" />
              Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="4"
            class="pa-0 pt-2 pt-md-0 pr-3"
          >
            <v-text-field
              data-test="txt-pesquisar-usuario"
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              class="pt-0 mt-0"
            />
          </v-col>
        </v-row>

        <v-col 
          cols="12"
          class="pa-0"
        >
          <v-card class="box card-cambio pt-2">
            <v-row no-gutters>
              <v-col cols="12">
                <v-tabs v-model="profileId">
                  <v-tab :tab-value="PROFILES.ADMINISTRATIVE.id">
                    <span>Usuários Sistêmicos</span>
                  </v-tab>
                  <v-tab :tab-value="PROFILES.CORBAN.id">
                    <span>Usuários Corbans</span>
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col
                cols="12"
                class="pt-2"
              >
                <v-tabs-items v-model="profileId">
                  <v-tab-item :value="profileId">
                    <v-card class="card-cambio">
                      <v-data-table
                        :headers="columns"
                        :items="users"
                        :search="search"
                        sort-by="id"
                        sort-desc
                        :items-per-page="paginationDefault"
                        height="calc(100dvh - 400px)"
                        fixed-header
                        data-test="tbl-usuarios"
                      >
                        <template #[`item.accessProfile`]="{ item }">
                          <v-chip-group column>
                            <v-chip
                              v-for="access in item.accessProfile"
                              :key="access.id"
                              small
                            >
                              {{ access.description }}
                            </v-chip>
                          </v-chip-group>
                        </template>
                        <template #[`item.createdAt`]="{ item }">
                          {{ item.createdAt | date }}
                        </template>
                        <template #[`item.status`]="{ item }">
                          <span v-if="!item.dateInactivation">
                            <v-chip
                              small
                              label
                              outlined
                              color="green lighten-1"
                              class="chip-status-user d-flex justify-center align-center"
                              data-test="chip-usuario-ativo"
                            >
                              Ativo
                            </v-chip>
                          </span>
                          <span v-else>
                            <v-chip
                              small
                              label
                              outlined
                              class="chip-status-user d-flex justify-center align-center"
                              color="red darken-1"
                              data-test="chip-usuario-inativo"
                            >
                              Inativo
                            </v-chip>
                          </span>
                        </template>

                        <template #[`item.actions`]="{ item }">
                          <td
                            v-if="!item.dateInactivation"
                            class="text-right"
                          >
                            <v-menu
                              offset-y
                              left
                            >
                              <template #activator="{ on: menu }">
                                <v-tooltip top>
                                  <template #activator="{ on: tooltip, attrs }">
                                    <v-btn
                                      icon
                                      text
                                      v-bind="attrs"
                                      v-on="{ ...tooltip, ...menu }"
                                      @click.stop
                                      data-test="acoes-usuario"
                                    >
                                      <i class="far fa-ellipsis-v" />
                                    </v-btn>
                                  </template>
                                  <span>Opções</span>
                                </v-tooltip>
                              </template>

                              <v-list data-test="lista-acoes-usuario">
                                <v-list-item 
                                  @click="editUser(item)"
                                  data-test="btn-editar-usuario"
                                >
                                  <i class="far fa-pencil pr-2 menu-buttons" />
                                  <v-list-item-title>Editar</v-list-item-title>
                                </v-list-item>

                                <v-list-item 
                                  @click="deleteUserConfirmationDialog(item)"
                                  data-test="btn-inativar-usuario"
                                >
                                  <i class="far fa-trash pr-2 menu-buttons" />
                                  <v-list-item-title>Inativar</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-col>
    </v-row>

    <BaseModal
      v-model="openUserModal"
      :title="`${updateMode ? 'Editar' : 'Adicionar'} usuário`"
      :primary-button-action="save"
      :disable-primary-button="!this.formIsValid() || disableButtons"
      :secondary-button-action="cancel"
      :fullscreen="appData.isMobile"
      modal-width="400px"
      max-height-body="300px"
      data-test="dialog-usuario"
    >
      <form autocomplete="off">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="user.name"
              label="Nome"
              autocomplete="off"
              dense
              :autofocus="!user.name"
              data-test="txt-nome-usuario"
            />
          </v-col>

          <v-col
            cols="12"
            class="py-2"
          >
            <v-text-field
              v-model="user.email"
              label="E-mail"
              autocomplete="off"
              type="email"
              dense
              :rules="[validationIsEmailValid]"
              :disabled="fieldDisabled || updateMode && user.profileId != PROFILES.ADMINISTRATIVE.id"
              data-test="txt-email-usuario"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              :items="accessProfileList"
              label="Perfil"
              v-model="user.accessProfile"
              item-text="description"
              item-value="id"
              return-object
              multiple
              chips
              clearable
              deletable-chips
              autocomplete="off"
              dense
              :disabled="fieldDisabled || updateMode && user.profileId != PROFILES.ADMINISTRATIVE.id"
              data-test="cplt-perfil-usuario"
            />
          </v-col>

          <v-col
            v-if="!updateMode"
            class="pt-2"
            cols="12"
          >
            <v-text-field
              label="Senha"
              v-model="user.password"
              :append-icon="passwordVisible ? 'visibility' : 'visibility_off'"
              @click:append="() => (passwordVisible = !passwordVisible)"
              :type="passwordVisible ? 'text' : 'password'"
              :rules="[validationPasswordField]"
              :disabled="fieldDisabled"
              dense
              data-test="txt-senha-usuario"
            />
          </v-col>
        </v-row>
      </form>
    </BaseModal>

    <confirmation-window
      v-model="openWindowConfirmation"
      :callback-primary="callbackConfirmDeletion"
      :message="deleteMessage"
      @onChange="clearInputs"
      data-test="janela-confirmacao"
    />
  </div>
</template>
<script>
// Apis
import apiUser from '@/api/user/user-api';

// Models
import UserModel from '@/model/user-model';
import UserFiltersModel from '@/model/user/user-filters-model';

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinValidationRules from "@/mixin/mixin-validation-rules";

// Components
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";
import BaseModal from "@/components/comum/BaseModal";

// Utils
import dateUtils from '../../../../common/utils/date';
import utilsStorage from '@/utils/storage';

// Constants
import { PROFILES, PROFILES_LIST } from '../../../../common/constants/generic/types';
import { OPTIONS_STORE_ACCESS } from '../../vuex/module/access';

// Tracking
import mixpanel from 'mixpanel-browser';
import accessProfileApi from '../../api/access/access-profile-api';
import AccessProfileModel from '../../model/access/access-profile-model';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: "UsersControl",
  mixins: [mixinMessage, mixinValidationRules],
  components: {
    ConfirmationWindow,
    BaseModal
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    dateHour(value) {
      return dateUtils.maskDateAndHourIso(value);
    }
  },
  inject: ['appData'],
  data() {
    return {
      passwordVisible: false,
      openUserModal: false,
      loading: true,
      updateMode: false,
      disableButtons: false,
      openWindowConfirmation: false,
      deleteMessage: '',
      search: '',
      columns: [
        { 
          text: "Código", 
          value: "id", 
          sortable: false 
        },
        { 
          text: "Nome", 
          value: "name", 
          align: "left", 
          sortable: true 
        },
        { 
          text: "E-mail", 
          value: "email", 
          align: "left", 
          sortable: false 
        },
        { 
          text: "Perfil", 
          value: "accessProfile", 
          sortable: false 
        },
        { 
          text: "Data de cadastro", 
          value: "createdAt", 
          align: "center" 
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: '100px'
        },
        { 
          text: "", 
          value: "actions",
          sortable: false 
        },
      ],
      user: new UserModel(),
      users: [],
      callbackConfirmDeletion: () => {},
      profiles: PROFILES_LIST,
      accessProfileList: [],
      profileId: PROFILES.ADMINISTRATIVE.id,
      PROFILES,
      paginationDefault: PAGINATION_DEFAULT,
      fieldDisabled: true
    };
  },
  mounted() {
    mixpanel.track('page_view', { name_of_page_viewed: 'users_control' });

    this.getUserList();
    this.getAccessProfileList();
  },
  computed: {
    minimumDate() {
      return new Date().toISOString();
    }
  },
  watch: {
    profileId() {
      this.getUserList();
    }
  },
  methods: {
    addUserDialog() {
      this.user = new UserModel();
      this.openUserModal = true;
      this.enableFieldsToPreventAutocomplete();
      mixpanel.track("click", {
        name_of_page_viewed: "users_control",
        button_name: "btn_new_user",
      });
    },

    enableFieldsToPreventAutocomplete() {
      const timeToPreventAutocomplete = 700;

      setTimeout(() => {
        this.fieldDisabled = false;
      }, timeToPreventAutocomplete);
    },

    getAccessProfileList() {
      accessProfileApi
        .findAll()
        .then((response) => {
          this.accessProfileList = response.data.map((a) => new AccessProfileModel(a));
          this.clearInputs();
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        });
    },

    getUserList() {
      this.users = [];
      let filters = new UserFiltersModel({ profileId: this.profileId });

      apiUser
        .findAll(filters)
        .then((response) => {
          this.users = response.data.map((u) => new UserModel(u));

          this.clearInputs();
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
          this.loading = false;
        });
    },

    save() {
      !this.updateMode ? this.addUser() : this.updateUser();
    },

    editUser(user) {
      mixpanel.track('click', {
        name_of_page_viewed: 'users_control',
        button_name: 'btn_icon_edit_user'
      });
      this.updateMode = true;
      this.user = new UserModel(user);
      this.openUserModal = true;
      this.fieldDisabled = false;
    },

    cancel() {
      this.openUserModal = false;
      this.user = new UserModel();
      this.updateMode = false;
      this.fieldDisabled = true;
    },

    clearInputs() {
      this.user = new UserModel();
    },

    addUser() {
      this.updateMode = false;
      this.disableButtons = true;
      let user = new UserModel(this.user);
      user.profileId = 1;
      user.profile = PROFILES.ADMINISTRATIVE; //Mockado como admin até rotina ser implementada

      apiUser
        .add(user)
        .then((response) => {
          this.sendMessage('Usuário cadastrado com sucesso!', 'success');
          this.users.push(new UserModel(response.data));
          this.clearInputs();
          this.fieldDisabled = true;
          this.openUserModal = false;
          this.disableButtons = false;
        })
        .catch((erro) => {
          this.sendMessage(erro.response?.data?.mensagem || erro, "error");
          this.disableButtons = false;
        });
    },

    updateUser() {
      this.updateMode = true;
      this.disableButtons = true;
      let user = new UserModel(this.user);
      user.profileId = 1;
      user.profile = PROFILES.ADMINISTRATIVE; //Mockado como admin até rotina ser implementada

      apiUser
        .update(user)
        .then((response) => {
          this.sendMessage(`${this.user.email} foi atualizado com sucesso!`, 'success');

          this.updateListUser(new UserModel(response.data));
          this.clearInputs();

          this.fieldDisabled = true;
          this.openUserModal = false;
          this.disableButtons = false;
          this.updateMode = false;

          let loggedUser = new UserModel(utilsStorage.getUserOnStorage());
          this.$store.dispatch(OPTIONS_STORE_ACCESS.ACTIONS.FIND_ACCESS, loggedUser);
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, "error");
          this.disableButtons = false;
        });
    },

    deleteUserConfirmationDialog(user) {
      mixpanel.track('click', {
        name_of_page_viewed: 'users_control',
        button_name: 'btn_icon_delete_user'
      });
      this.user = new UserModel(user);
      this.deleteMessage = `Deseja realmente inativar o usuário &nbsp; <strong>${user.email}</strong>?`;
      this.callbackConfirmDeletion = this.deleteUser;
      this.openWindowConfirmation = true;
    },

    deleteUser() {
      apiUser
        .inactive(this.user)
        .then((response) => {
          let user = new UserModel(response.data);

          this.sendMessage(`O ${this.user.email} foi inativado com sucesso!`, "success");

          this.updateListUser(user, false);
        })
        .catch((error) =>
          this.sendMessage(error.response?.data?.mensagem, "error")
        )
        .finally((this.openWindowConfirmation = false));
    },

    updateListUser(user, deleteFromList) {
      var index = this.users.map((u) => parseInt(u.id)).indexOf(parseInt(user.id));
      !deleteFromList ? this.users.splice(index, 1, user) : this.users.splice(index, 1);
    },

    formIsValid() {
      if (this.updateMode && !this.user.password) {
        return this.user.validModelUpdate();
      }

      return this.user.validModel();
    }
  }
};
</script>

<style scoped>
.chip-status-user {
  width: 100%;
}

::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  display: none !important;
}
</style>
