<template>
  <v-row v-if="!isPF">
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Responsável pelo cadastro</h1>
        <v-divider class="py-3" />
        <PJCustomerResponsibleRegistration v-model="customer" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PJCustomerResponsibleRegistration from './PJ/registrationResponsible/PJCustomerResponsibleRegistration.vue';

// Constants
import { REGISTER_TYPE } from '@/constants/general-constants.js';

export default {
  name: 'CustomerRegistrationResponsible',
  components: { PJCustomerResponsibleRegistration },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  },
  computed: {
    isPF() {
      return this.customer && this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? false
        : true;
    }
  },
  data() {
    return {
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      customer: this.customerProp
    };
  }
};
</script>

<style></style>
