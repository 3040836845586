<template>
  <div>
    <v-expansion-panels
      class="operationSimulationPanel"
      :class="positionSimulatorClass"
      v-model="visiblePanels"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          color="primary"
          expand-icon="fa-chevron-down"
          class="text-white"
        >
          <h3 class="expansion-title">
            <i class="fal fa-money-bill-wave-alt pr-2" />
            {{ textOnSimulation }}
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          eager
          :key="panelKey"
          class="py-2"
        >
          <div class="wrapper-position">
            <div v-if="leftSimulator">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    class="btn-move"
                    @click="setPositionRight()"
                    v-on="on"
                  >
                    <i class="far fa-arrow-right" />
                  </v-btn>
                </template>
                <span>Mover simulador para a direita</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    class="btn-move"
                    @click="setPositionLeft()"
                    v-on="on"
                  >
                    <i class="far fa-arrow-left" />
                  </v-btn>
                </template>
                <span>Mover simulador para a esquerda</span>
              </v-tooltip>
            </div>
          </div>
          <v-row class="py-2">
            <v-col
              cols="12"
              md="12"
            >
              <v-row class="pb-2">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-card class="box card-cambio pb-0">
                    <v-radio-group
                      v-model="calculationTypeId"
                      row
                      mandatory
                    >
                      <v-radio
                        v-for="(item, i) in calculationTypeList"
                        :key="i"
                        :value="item.id"
                        :label="item.description"
                      />
                    </v-radio-group>
                    <v-divider class="py-1" />
                    <v-radio-group
                      v-model="operation.exchangeBuy"
                      row
                      mandatory
                    >
                      <v-radio
                        :value="false"
                        label="Venda/Saída"
                      />
                      <v-radio
                        :value="true"
                        label="Compra/Entrada"
                      />
                    </v-radio-group>
                    <v-divider class="py-1" />
                    <v-radio-group
                      v-model="operation.exchangeType.id"
                      row
                      mandatory
                    >
                      <v-radio
                        v-for="(item, i) in filteredExchangeTypes"
                        :key="i"
                        :value="item.id"
                        :label="item.description"
                      />
                    </v-radio-group>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-form
                    ref="formSimulatorOperation"
                    v-model="valid"
                    v-if="operation.exchangeType.id"
                  >
                    <div v-if="operation.exchangeType.id == operationTypeConstant.EXCHANGE.id">
                      <SimulatorExchange
                        v-model="operation"
                        :calculation-type="calculationTypeId"
                      />
                    </div>
                    <div v-else>
                      <SimulatorRemittance
                        v-model="operation"
                        :calculation-type="calculationTypeId"
                      />
                    </div>
                  </v-form>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-card class="card-cambio pa-5">
                    <v-btn
                      class="btn-secondary mr-2"
                      @click="copyCustomerMessageToClipboard"
                      text
                      :disabled="!operation.currency?.id"
                    >
                      <i
                        class="far fa-copy"
                        aria-hidden="true"
                      />
                      Copiar Mensagem do cliente
                    </v-btn>
                    <v-btn
                      class="btn-tertiary"
                      @click="clearSimulation"
                      text
                    >
                      Limpar
                    </v-btn>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-right footer-actions"
                >
                  <v-btn
                    text
                    class="btn-tertiary"
                    @click="closeSimulation"
                  >
                    Fechar
                  </v-btn>
                  <v-btn
                    text
                    class="btn-primary"
                    @click="convertToOperation"
                  >
                    <i class="far fa-check" />
                    Validar
                  </v-btn>
                  <v-btn
                    text
                    class="btn-secondary"
                    @click="convertToOperationNewTab"
                  >
                    <i class="far fa-external-link" /> Validar em Nova Aba
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

// Components
import SimulatorRemittance from '@/components/simulator-operation/SimulatorRemittance';
import SimulatorExchange from '@/components/simulator-operation/SimulatorExchange';

// Apis
import ExchangeBankTypeApi from "@/api/exchangeBank/exchange-bank-type-api";
import RateApi from "@/api/general/rate-api";

// Models
import OperationModel from "@/model/operation-model";
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";
import RateModel from "@/model/rate-model";
import OperationQueryModel from "@/model/operation/operation-query-model";

// Constants
import { OPERATION_CALCULATION_TYPE, OPERATION_TYPE } from "@/constants/general-constants";

// Utils
import operationUtils from "@/utils/operation";
import mixinMessage from "@/mixin/mixin-message";
import simulatorMessagesTemplate from "@/utils/simulatorMessagesTemplate";

export default {
  name: "OperationSimulator",
  components: {
    SimulatorRemittance,
    SimulatorExchange,
  },
  mixins: [mixinMessage],
  inject: ['appData'],
  data() {
    return {
      visiblePanels: 0,
      calculationTypeList: Object.values(OPERATION_CALCULATION_TYPE),
      calculationTypeId: OPERATION_CALCULATION_TYPE.CURRENCY_AMOUNT.id,
      exchangeTypes: [],
      operationTypeConstant: OPERATION_TYPE,
      operation: new OperationModel(),
      valid: false,
      timer: null,
      panelKey: 0,
      leftSimulator: false, // default
      usdValue: 0,
      apiExchangeBankType: new ExchangeBankTypeApi(this.appData.currentUser),
      rateApi: new RateApi(this.appData.currentUser),
    };
  },
  mounted() {
    this.getExchangeTypes();
  },
  props: {
    isOpenSimulator: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'isOpenSimulator',
    event: 'onChange'
  },
  watch: {
    "operation.exchangeBuy"() {
      this.resetSimulation();
    },
    "operation.exchangeType.id"() {
      this.resetSimulation();
    },
    "operation.nature"() {
      this.updateValues();
    },
    "operation.amount"() {
      if (this.calculationTypeId == OPERATION_CALCULATION_TYPE.CURRENCY_AMOUNT.id) {
        this.updateValues();
      }
    },
    "operation.currencyQuotation"() {
      this.updateValuesKeepingCustomerRate();
    },
    "operation.currency"() {
      operationUtils.resetSpreads(this.operation);
      this.getCurrencyQuotation(true);
    },
    "operation.bank"() {
      this.operation.exchangeContractCost = undefined;
      this.updateValues();
    },
    "operation.spread"() {
      this.updateValues();
    },
    "operation.customerRate"() {
      this.updateValuesKeepingCustomerRate();
    },
    "operation.exchangeContractCost"() {
      this.updateBrlExchangeContract();
    },
    "operation.deliveryCost"() {
      this.updateValues();
    },
    "operation.totalValue"() {
      if (this.calculationTypeId == OPERATION_CALCULATION_TYPE.BRL_AMOUNT.id) {
        this.updateValues();
      }
    },
    "operation.useUsdExchangeContract"() {
      this.updateBrlExchangeContract();
    },
    "operation.exchangeContractCostBrl"() {
      this.updateValues();
    },
    "usdValue"() {
      this.updateBrlExchangeContract();
    },
  },
  computed: {
    filteredExchangeTypes() {
      let filtered = this.exchangeTypes.filter(i => i.id != this.operationTypeConstant.BOTH.id);
      filtered.sort((a, b) => a.id - b.id);

      return filtered;
    },
    textOnSimulation() {
      if (this.operation.customer?.name) {
        let operationType = this.operation.exchangeType.id == this.operationTypeConstant.EXCHANGE.id ? 'Câmbio' : 'Remessa';

        return `Simulando Operação | ${operationType} - ${this.operation.customer.name}`;
      } else {
        return `Simulador de Operação`;
      }
    },
    positionSimulatorClass() {
      return this.leftSimulator ? 'leftSimulatorClass' : 'rightSimulatorClass';
    }
  },
  methods: {
    setPositionRight() {
      this.leftSimulator = false;
    },
    setPositionLeft() {
      this.leftSimulator = true;
    },
    getExchangeTypes() {
      this.apiExchangeBankType
        .findAll()
        .then((response) => {
          this.exchangeTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCurrencyQuotation(updateCustomerRate) {
      if (!this.operation.currency?.code) {
        clearTimeout(this.timer);
        this.operation.currencyQuotation = 0;

        return;
      }

      this.rateApi.findCommercial({ currencies: `${this.operation.currency.code}` })
        .then((response) => {
          let rate = new RateModel(response.data[0]);
          this.operation.currencyQuotation = rate.ask;

          this.getUsdQuotation();

          if (updateCustomerRate) {
            this.updateValues();
          }

          this.startCurrencyTimeout();
        })
        .catch((error) => {
          this.operation.currencyQuotation = 0;
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    startCurrencyTimeout() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getCurrencyQuotation();
      }, operationUtils.REFRESH_RATE_TIME);
    },

    updateValues() {
      if (this.operation.exchangeType.id == this.operationTypeConstant.EXCHANGE.id) {
        this.updateValuesExchange();
      } else {
        this.updateValuesRemittance();
      }
    },

    updateValuesRemittance() {
      operationUtils.setBaseValuesOperationRemittance(this.operation);
      this.calculateOperationRemittance();
    },

    updateValuesExchange() {
      operationUtils.setBaseValuesOperationExchange(this.operation);
      this.calculateOperationExchange();
    },

    calculateOperationExchange() {
      if (this.calculationTypeId == OPERATION_CALCULATION_TYPE.CURRENCY_AMOUNT.id) {
        operationUtils.calculateOperationExchange(this.operation);
      } else {
        operationUtils.calculateOperationExchangeFromBrl(this.operation);
      }
    },

    calculateOperationRemittance() {
      if (this.calculationTypeId == OPERATION_CALCULATION_TYPE.CURRENCY_AMOUNT.id) {
        operationUtils.calculateOperationRemittance(this.operation);
      } else {
        operationUtils.calculateOperationRemittanceFromBrl(this.operation);
      }
    },

    updateValuesKeepingCustomerRate() {
      if (this.operation.exchangeType.id == this.operationTypeConstant.EXCHANGE.id) {
        this.updateValuesKeepingCustomerRateExchange();
      } else {
        this.updateValuesKeepingCustomerRateRemittance();
      }
    },

    updateValuesKeepingCustomerRateRemittance() {
      operationUtils.getSpreadFromCustomerRateRemittance(this.operation);
      this.calculateOperationRemittance();
    },

    updateValuesKeepingCustomerRateExchange() {
      operationUtils.getSpreadFromCustomerRateExchange(this.operation);
      this.calculateOperationExchange();
    },

    resetSimulation() {
      this.operation = new OperationModel({ exchangeBuy: this.operation.exchangeBuy, exchangeType: this.operation.exchangeType });
      this.panelKey++;
    },

    clearSimulation() {
      this.operation = new OperationModel();
      this.calculationTypeId = OPERATION_CALCULATION_TYPE.CURRENCY_AMOUNT.id;
      this.panelKey++;
    },

    closeSimulation() {
      this.visiblePanels = undefined;
      this.$emit('onChange', false);
    },

    async convertToOperation() {
      this.visiblePanels = undefined;
      await this.$router.push(this.mountOperationRouteObject());
      this.$emit('onChange', false);
      /* Needed in case the user is already on the operation form */
      this.$router.go(0);
    },

    convertToOperationNewTab() {
      this.$emit('onChange', false);
      let routeData = this.$router.resolve(this.mountOperationRouteObject());
      window.open(routeData.href, '_blank');
    },

    mountOperationRouteObject() {
      return {
        name: 'FormOperation',
        query: new OperationQueryModel({
          idCustomer: this.operation.customer.id,
          idExchangeType: this.operation.exchangeType.id,
          idBank: this.operation.bank.id,
          idNature: this.operation.nature.id,
          idCurrency: this.operation.currency.id,
          amount: this.operation.amount,
          rate: this.operation.customerRate,
          contractCost: this.operation.exchangeContractCost,
          city: this.operation.storeCity,
          idStore: this.operation.store.id,
          idDeliveryType: this.operation.deliveryType.id,
          deliveryCost: this.operation.deliveryCost,
          exchangeBuy: this.operation.exchangeBuy,
        })
      };
    },

    copyCustomerMessageToClipboard() {
      navigator.clipboard.writeText(simulatorMessagesTemplate.getCustomerMessage(this.operation));
      this.sendMessage(
        'A mensagem do cliente foi copiada em seu CTRL + C',
        "success"
      );
    },

    getUsdQuotation() {
      this.rateApi.findCommercial({ currencies: `USD` })
        .then((response) => {
          let rate = new RateModel(response.data[0]);
          this.usdValue = rate.ask;
        })
        .catch((error) => {
          this.usdValue = 0;
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateBrlExchangeContract() {
      if (!this.operation.useUsdExchangeContract) {
        this.operation.exchangeContractCostBrl = this.operation.exchangeContractCost;

        return;
      }

      this.operation.exchangeContractCostBrl = (+this.operation.exchangeContractCost * +this.usdValue).toFixed(operationUtils.SHORT_DECIMALS_PLACES);
    }
  },
};
</script>

<style lang="scss" scoped>
.leftSimulatorClass {
  left: 100px
}

.rightSimulatorClass {
  right: 20px
}

.wrapper-position {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.btn-move {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}

.operationSimulationPanel {
  position: fixed;
  max-width: 900px;
  border-radius: 0;
  bottom: 20px;
  overflow: auto;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: auto;
  z-index: 9;
}

// // Make the panel scrollable in all devices, with height of 500px.
.v-expansion-panel-content {
  height: 70vh;
  overflow-y: scroll;
}

@media screen and (max-width: 942px) {
  .operationSimulationPanel {
    width: 90vw;
    margin: 0 auto;
    left: 0;
    right: 0
  }

  .wrapper-position {
    display: none;
  }
}

// Fix heigth of the header when active
.v-expansion-panel--active>.v-expansion-panel-header {
  min-height: 45px;
}

.expansion-title {
  color: white;
  font-size: 1rem;
  font-weight: 400;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon i.v-icon {
  color: white;
  font-size: 1rem;
}

.footer-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: end;
}

@media screen and (max-width: 520px) {
  .footer-actions {
    flex-direction: column;
    gap: 10px;
  }

  .footer-actions>button {
    width: 100%;
  }

  ;
}
</style>