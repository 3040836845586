import BaseApi from '../baseApi';

export default class ExchangeBankApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  add(bank) {
    bank = bank || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/exchange-banks', bank)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  update(bank) {
    bank = bank || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/exchange-banks/${bank.id}`, bank)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findAll(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/exchange-banks', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findId(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/exchange-banks/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
