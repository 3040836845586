import BaseApi from '../baseApi';

export default class CustomerApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  add(customer) {
    customer = customer || {};

    return new Promise((resolve, reject) => {
      return this.api
        .post('/customers', customer)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  update(customer) {
    customer = customer || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/customers/${customer.id}`, customer)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findAll(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get('/customers', { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findId(id) {
    id = id || {};

    return new Promise((resolve, reject) => {
      return this.api
        .get(`/customers/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  inactive(customer) {
    customer = customer || {};

    return new Promise((resolve, reject) => {
      return this.api
        .patch(`/customers/${customer.id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateStatus(customerId, statusUpdateDto) {
    statusUpdateDto = statusUpdateDto || {};

    return new Promise((resolve, reject) => {
      return this.api
        .put(`/customers/${customerId}/update-status`, statusUpdateDto)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  addLimit(idCustomer, idExchangeBank, limit) {
    idCustomer = idCustomer || {};
    idExchangeBank = idExchangeBank || {};
    limit = limit || {};

    return new Promise((resolve, reject) => {  
      return this.api
        .post(`/customers/${idCustomer}/bank/${idExchangeBank}/limits`, limit)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  findExchangeBanks(id, idType) {
    id = id || {};

    return new Promise((resolve, reject) => {  
      return this.api
        .get(`/customers/${id}/exchange-type/${idType}/banks`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getLimits(filters) {
    return new Promise((resolve, reject) => {
      return this.api
        .get(`/customers/limits/`, { params: filters })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  downloadRegisterForm(customerId, exchangeBankId){
    return  new Promise((resolve, reject) =>{
      return this.api
        .get('/customers/'+ customerId + '/bank/' + exchangeBankId + '/form/download' , { responseType: 'blob' })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
}

