import Pagination from '../../../../common/models/Pagination';

export default class CustomerExchangeBankFiltersModel extends Pagination {
  constructor(obj) {
    obj = obj || {};
    super(obj);

    this.startApprovalDate = obj.startApprovalDate;
    this.endApprovalDate = obj.endApprovalDate;
    this.customerRegisterStatusList = obj.customerRegisterStatusList;
    this.customerExchangeBankStatusList = obj.customerExchangeBankStatusList;
    this.idCustomer = obj.idCustomer;
    this.exchangeBankList = obj.exchangeBankList;
    this.idExchangeType = obj.idExchangeType;
    this.corbanId = obj.corbanId;
    this.searchText = obj.searchText;
  }
}
