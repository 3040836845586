<template>
  <div class="ma-5">

    <v-row>
      <v-col
        cols="12"
        md="12"
        class="pb-2"
      >
        <h1 class="titulo">Controle de comissões</h1>
        <v-divider />
      </v-col>

      <v-col
        cols="12"
        md="12"
        v-if="!appData.currentUser.correspondentId"
      >
        <AdministrativeComissionsControl v-administrative-access />
      </v-col>

      <v-col
        cols="12"
        md="12"
        v-else
      >
        <CorbanComissionsControl v-corban-access />
      </v-col>
    </v-row>
  </div>
</template>
<script>

// Tracking
import mixpanel from 'mixpanel-browser';

// Components
import AdministrativeComissionsControl from '@/components/commissions/AdministrativeComissionsControl';
import CorbanComissionsControl from '@/components/commissions/CorbanComissionsControl';

export default {
  name: 'CommissionsControl',
  components: {
    AdministrativeComissionsControl,
    CorbanComissionsControl,
  },
  inject: ['appData'],
  data() {
    return {};
  },
  mounted() {
    mixpanel.track('page_view', {
      name_of_page_viewed: 'commission_control',
      user_id: this.appData.currentUser.id
    });
  },
};
</script>

<style scoped lang="scss">
.remaining-value {
  color: var(--v-primary-base);
}

::v-deep .v-data-table th,
::v-deep .v-data-table td {
  min-width: 100px;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
