<template>
  <v-row data-test="row-customer-operational-documents">
    <v-col
      cols="12"
      md=""
    >
      <div
        class="box"
        data-test="div-documentos-complementares"
      >
        <h1 data-test="h1-documentos-complementares">Documentos complementares</h1>
        <v-row
          class="pt-3"
          :key="key"
          data-test="row-documents"
        >
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-divider
              class="py-3"
              data-test="divider"
            />
          </v-col>
          <template v-for="(document, i) in documents">
            <v-col
              class="py-0"
              cols="12"
              md="2"
              :key="'type' + i"
            >
              <v-autocomplete
                data-test="slc-tipo-documento"
                label="Tipo"
                :items="documentTypes"
                item-text="description"
                item-value="id"
                v-model="documents[i].operationalDocumentType"
                return-object
                clearable
                dense
                outlined
                v-disabled-icon-focus
                :disabled="!!(documents[i].document || documents[i].path)"
              />
            </v-col>
            <v-col
              class="py-0 px-0"
              cols="12"
              md="4"
              :key="'documents' + i"
            >
              <DocumentInput
                :data-test="`document-input-${i}`"
                :description="documents[i].description || 'Compl. ' + (i + 1)"
                v-model="documents[i]"
                :api="apiDocument"
                :customer-id="customer.id"
                :disabled="!documents[i].operationalDocumentType?.id"
                :file-type="fileType.CUSTOMER_OPERATIONAL_DOCUMENT"
                @insert-document="insertDocument"
              >
                <template #append-outer>
                  <v-btn
                    v-if="documents.length >= 2 && !(documents[i].document || documents[i].path)"
                    class="py-0"
                    icon
                    @click="removeDocument(i)"
                    text
                    data-test="btn-remover-documento"
                  >
                    <i class="far fa-trash" />
                  </v-btn>
                  <v-btn
                    data-test="btn-adicionar-documento"
                    class="py-0"
                    icon
                    @click="insertDocument"
                    text
                  >
                    <i class="far fa-plus" />
                  </v-btn>
                </template>
              </DocumentInput>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import DocumentInput from '@/components/comum/DocumentInput';

// APIs
import CustomerOperationalDocumentsApi from '@/api/customer/customer-operational-documents-api';

// Constants
import { OPERATIONAL_DOCUMENT_TYPE, FILE_TYPE } from '@/constants/general-constants.js';

// Models
import CustomerOperationalDocumentModel from '@/model/customer-operational-document-model';

export default {
  name: 'CustomerOperationalDocuments',
  components: { DocumentInput },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => ({})
    }
  },
  inject: ['appData'],
  data() {
    return {
      customer: this.customerProp,
      apiDocument: new CustomerOperationalDocumentsApi(this.appData.currentUser),
      documentTypes: OPERATIONAL_DOCUMENT_TYPE,
      documents: [],
      key: 0,
      fileType: FILE_TYPE
    };
  },
  mounted() {
    this.documents = this.customer.operationalDocuments;

    if (this.documents.length == 0) {
      this.documents = [new CustomerOperationalDocumentModel()];
    }
  },
  watch: {
    documents: {
      handler() {
        this.key = this.key + 1;
        this.updateCustomerDocuments();
      },
      deep: true
    }
  },
  methods: {
    insertDocument() {
      this.documents.push(new CustomerOperationalDocumentModel());
      this.key = this.key + 1;
    },
    removeDocument(index) {
      if (this.documents.length > 1) {
        this.documents.splice(index, 1);
      }

      this.key = this.key + 1;
    },
    updateCustomerDocuments() {
      let documents = this.documents.filter((d) => d.document || d.id);
      this.customer.operationalDocuments = [...documents];
      this.$emit('onChange', this.customer);
    }
  }
};
</script>

<style>
aligment-right {
  margin-right: -20px !important;
}
</style>
