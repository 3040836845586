<template>
  <v-row
    no-gutters
    class="mx-2 mt-4 ma-md-5"
  >
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="titulo">Naturezas de operação</h1>
          <v-divider />
        </v-col>
      </v-row>

      <v-row 
        no-gutters
        class="py-2"
      >
        <v-col
          cols="12"
          md="9"
          sm="8"
          align-self="center"
        >
          <v-btn
            class="btn-primary ml-0"
            text
            @click="openAddNatureModal"
          >
            <i class="far fa-plus" /> Adicionar
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="3"
          sm="4"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            class="pt-0"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-card class="card-cambio">
            <v-data-table
              :headers="columns"
              :items="natures"
              :search="search"
              sort-by="name"
              sort-asc
              :items-per-page="paginationDefault"
              :custom-filter="caseInsensitiveAccentsInsensitive"
              height="calc(100dvh - 305px)"
              fixed-header
            >
              <template #[`item.iof`]="{ item }">
                {{ item.iof | formatNumber }}
              </template>
              <template #[`item.ir`]="{ item }">
                {{ item.ir | formatNumber }}
              </template>
              <template #[`item.cide`]="{ item }">
                {{ item.cide | formatNumber }}
              </template>
              <template #[`item.iofBuy`]="{ item }">
                {{ item.iofBuy | formatNumber }}
              </template>
              <template #[`item.irBuy`]="{ item }">
                {{ item.irBuy | formatNumber }}
              </template>
              <template #[`item.cideBuy`]="{ item }">
                {{ item.cideBuy | formatNumber }}
              </template>
              <template #[`item.useDiDue`]="{ item }">
                <i :class="`far ${item.useDiDue ? 'fa-check-circle' : 'fa-ban' } fa-lg`" />
              </template>
              <template #[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      color="primary"
                      @click="editNatureModal(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="far fa-pencil" />
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <BaseModal
      v-model="addNatureModal"
      title="Naturezas de Operação"
      modal-width="80vw"
      :primary-button-action="update ? updateNature : addNature"
      :secondary-button-action="() => addNatureModal = false"
      :fullscreen="appData.isMobile"
    >
      <v-form
        id="add-nature"
        ref="form"
      >
        <v-row>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <NumberField
              v-model="nature.code"
              outlined
              dense
              label="Código"
              :precisao-decimal="0"
              :rules="[validationIsRequiredField]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="8"
          >
            <v-text-field
              outlined
              dense
              label="Nome"
              v-model="nature.name"
              :rules="[validationIsRequiredField]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <legend>Usa DI/DUE?</legend>
            <v-radio-group
              row
              v-model="nature.useDiDue"
              class="py-0"
              dense
            >
              <v-radio
                :value="true"
                label="SIM"
              />
              <v-radio
                :value="false"
                label="NÃO"
              />
            </v-radio-group>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <NumberField
              v-model="nature.iofBuy"
              outlined
              dense
              label="IOF compra(%)"
              suffix="%"
              :precisao-decimal="6"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <NumberField
              v-model="nature.iof"
              outlined
              dense
              label="IOF venda(%)"
              suffix="%"
              :precisao-decimal="6"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <NumberField
              v-model="nature.irBuy"
              outlined
              dense
              label="IR compra(%)"
              suffix="%"
              :precisao-decimal="6"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <NumberField
              v-model="nature.ir"
              outlined
              dense
              label="IR venda(%)"
              suffix="%"
              :precisao-decimal="6"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <NumberField
              v-model="nature.cide"
              outlined
              dense
              label="CIDE venda(%)"
              suffix="%"
              :precisao-decimal="6"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <NumberField
              v-model="nature.cideBuy"
              outlined
              dense
              label="CIDE compra(%)"
              suffix="%"
              :precisao-decimal="6"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-textarea
              auto-grow
              outlined
              rows="1"
              v-model="nature.observation"
              label="Observações"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
          >
            <BilateralAgreementCountries v-model="nature" />
          </v-col>
        </v-row>
      </v-form>
    </BaseModal>
  </v-row>
</template>

<script>
// Api
import OperationNatureApi from "@/api/configuration/operation-nature-api";

// Components
import NumberField from "@/components/comum/NumberField";
import BilateralAgreementCountries from "./BilateralAgreementCountries";
import BaseModal from "@/components/comum/BaseModal";

// Models
import OperationNatureModel from "@/model/operation-nature-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";
import mixinValidationRules from "../../../mixin/mixin-validation-rules";

// Utils
import numberUtils from "../../../../../common/utils/number";
import { PAGINATION_DEFAULT } from '../../../constants/general-constants';

export default {
  name: "OperationNaturesControl",
  mixins: [mixinMessage, mixinDataTableFilters, mixinValidationRules],
  components: {
    NumberField,
    BilateralAgreementCountries,
    BaseModal
  },
  inject: ['appData'],
  data() {
    return {
      search: "",
      natures: [],
      columns: [
        { text: "Código", value: "code", align: "left", sortable: true, width: "100px" },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "IOF compra(%)", value: "iofBuy", align: "center", sortable: true },
        { text: "IOF venda(%)", value: "iof", align: "center", sortable: true },
        { text: "IR compra(%)", value: "irBuy", align: "center", sortable: true },
        { text: "IR venda(%)", value: "ir", align: "center", sortable: true },
        { text: "CIDE compra(%)", value: "cideBuy", align: "center", sortable: true },
        { text: "CIDE venda(%)", value: "cide", align: "center", sortable: true },
        { text: "D.I./D.U.E.", value: "useDiDue", align: "center", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      nature: new OperationNatureModel(),
      addNatureModal: false,
      update: false,
      key: 0,
      operationNatureApi: new OperationNatureApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT,
    };
  },
  watch: {},
  mounted() {
    this.getNatureList();
  },
  filters: {
    formatNumber(value) {
      return numberUtils.formatNumber(value);
    },
  },
  methods: {
    getNatureList() {
      this.operationNatureApi
        .findAll()
        .then((response) => {
          this.natures = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            error.response?.data?.mensagem || error,
            "error"
          );
        });
    },

    openAddNatureModal() {
      this.update = false;
      this.nature = new OperationNatureModel();
      this.addNatureModal = true;
    },

    editNatureModal(nature) {
      this.update = true;

      if (nature.bilateralAgreementCountries.length) {
        this.nature = new OperationNatureModel(nature);

        return;
      }

      this.getBilateralAgreementCountries(nature.id)
        .then(() => {
          this.addNatureModal = true;
        });
    },

    addNature() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$eventBus.$emit('loading', true);

      this.operationNatureApi
        .add(this.nature)
        .then((response) => {
          this.natures.push(new OperationNatureModel(response.data));

          this.addNatureModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            error.response?.data?.mensagem || error,
            "error"
          );
        })
        .finally(() => {
          this.$eventBus.$emit('loading', false);
        });
    },

    updateNature() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$eventBus.$emit('loading', true);
      this.operationNatureApi
        .update(this.nature)
        .then((response) => {
          let index = this.natures.findIndex((s) => s.id == this.nature.id);
          this.natures.splice(index, 1, new OperationNatureModel(response.data));
          this.addNatureModal = false;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('loading', false);
        });
    },

    getBilateralAgreementCountries(natureId) {
      return this.operationNatureApi
        .findId(natureId)
        .then((response) => {
          this.nature = new OperationNatureModel(response.data);
          this.key = this.key + 1;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, "error");
        });
    }
  },
};
</script>

<style scoped></style>
