import { MUTATIONS_ACCESS } from './mutations';
import AccessApi from '../../../api/access/access-api';

export default {
  async findAccess({ commit }, user) {
    let accessApi = new AccessApi(user);

    const response = await accessApi.findUserAccess(user);
    commit(MUTATIONS_ACCESS.UPDATE_ACCESS, response.data);
  }
};
