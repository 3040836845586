<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <v-row class="pt-4">
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="(Opcional) Cliente / CPF / CNPJ "
              :items="customerList"
              v-model="operation.customer"
              item-value="id"
              :item-text="itemTextCustomers"
              return-object
              dense
              clearable
              autofocus
              outlined
              v-disabled-icon-focus
              :filter="caseInsensitiveAccentsInsensitiveIgnoreDotsDashes"
              :loading="customerSearchLoading"
              :search-input.sync="customerSearchQuery"
              hide-no-data
              cache-items
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Banco"
              :items="bankList"
              v-model="operation.bank"
              item-value="id"
              item-text="companyName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            >
              <template #item="data">
                <v-row
                  no-gutters
                  class="py-2"
                >
                  <v-col>
                    <v-row no-gutters>
                      <v-col>
                        <span class="bank-title">{{ data.item.fantasyName }}</span>
                      </v-col>
                    </v-row>

                    <template v-if="operation.customer.id">
                      <v-row no-gutters>
                        <v-col>
                          <span class="subtitle">
                            Limite: <b>{{ getLimitBank(data.item) }}</b>
                          </span>
                        </v-col>
                      </v-row>
  
                      <v-row no-gutters>
                        <v-col>
                          <span class="subtitle">
                            Expira em: <b>{{ getExpireBank(data.item) }}</b>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Natureza"
              :items="natures"
              v-model="operation.nature"
              item-value="id"
              item-text="exibitionName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <CurrencySelect
              :currencyList="currencies"
              v-model="operation.currency"
              :required="true"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
            v-if="calculationTypeConst.CURRENCY_AMOUNT.id == calculationType"
          >
            <MoneyField
              label="Quantidade ME"
              prefix=" "
              v-model="operation.amount"
              :rules="[validationIsRequiredFieldNumeric]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
            v-else
          >
            <MoneyField
              label="Quantidade Reais"
              prefix=" "
              v-model="operation.totalValue"
              :rules="[validationIsRequiredFieldNumeric]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Spread"
              prefix=" "
              suffix=" %"
              v-model="operation.spread"
              persistent-hint
              :hint="spreadHint"
              :min="operation.spreadMin"
              :max="operation.spreadMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Taxa do Cliente"
              prefix="R$ "
              suffix=""
              :precision="operation.currency.precision"
              v-model="operation.customerRate"
              persistent-hint
              :hint="customerRateHint"
              :min="operation.customerRateMin"
              :max="operation.customerRateMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Tarifa"
              :prefix="operation.useUsdExchangeContract ? 'US$ ' : 'R$ '"
              suffix=""
              v-model="operation.exchangeContractCost"
              :rules="[validationIsRequiredFieldNumericAllowZero]"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
      class="pb-0"
    >
      <v-card class="box card-cambio">
        <v-row class="pa-5">
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">Câmbio comercial (SPOT)</h1>
            <span class="operation-value">{{
              operation.currencyQuotation || 0 | formatCurrency(operation.currency.precision)
            }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">IOF({{ operation.iof || 0 }}%)</h1>
            <span class="operation-value">{{ operation.iofValue || 0 | formatCurrency(2) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">IR({{ operation.ir || 0 }}%)</h1>
            <span class="operation-value">{{ operation.irValue || 0 | formatCurrency(2) }}</span>
          </v-col>
          <template v-if="operation.cide">
            <v-col
              cols="12"
              md="6"
              class="text-left"
            >
              <h1 class="operation-title">CIDE({{ operation.cide || 0 }}%)</h1>
              <span class="operation-value">{{
                operation.cideValue || 0 | formatCurrency(2)
              }}</span>
            </v-col>
          </template>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">
              Tarifa{{
                operationProp.useUsdExchangeContract
                  ? `(${formatUSD(operationProp.exchangeContractCost)})`
                  : ``
              }}
            </h1>
            <span class="operation-value">{{
              operation.exchangeContractCostBrl || 0 | formatCurrency
            }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
            v-if="calculationTypeConst.CURRENCY_AMOUNT.id == calculationType"
          >
            <h1 class="operation-title">Total</h1>
            <span class="operation-value">{{ operation.totalValue || 0 | formatCurrency(2) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
            v-else
          >
            <h1 class="operation-title">Total</h1>
            <span class="operation-value"
              >{{ operation.currency.code }} {{ operation.amount || 0 | formatNumber(2) }}</span
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import MoneyField from '@/components/comum/MoneyField';
import CurrencySelect from '../form-operation/CurrencySelect.vue';

// Api
import CurrencyApi from '@/api/spread/currency-api';
import ExchangeBankApi from '@/api/exchangeBank/exchange-bank-api';
import OperationNatureApi from '@/api/configuration/operation-nature-api';
import CustomerApi from '@/api/customer/customer-api';

// Models
import CurrencyModel from '@/model/currency-model';
import ExchangeBankModel from '@/model/exchange-bank-model';
import OperationNatureModel from '@/model/operation-nature-model';
import CustomerModel from '@/model/customer-model';
import CustomerExchangeBankModel from '@/model/customer-exchange-bank-model';
import CustomerFiltersModel from '@/model/customer/customer-filters-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinValidationRules from '@/mixin/mixin-validation-rules';
import mixinAutoCompleteFilters from '@/mixin/mixin-autocomplete-filters';

// Constants
import { OPERATION_TYPE, OPERATION_CALCULATION_TYPE } from '@/constants/general-constants';
import { CUSTOMER_WORKFLOW } from '../../../../common/workflows/customersWorkflow';

// Utils
import numberUtils from '../../../../common/utils/number';
import dateUtils from '../../../../common/utils/date';

export default {
  name: 'SimulatorRemittance',
  mixins: [mixinMessage, mixinValidationRules, mixinAutoCompleteFilters],
  components: {
    MoneyField,
    CurrencySelect
  },
  inject: ['appData'],
  data() {
    return {
      operation: this.operationProp,
      currencies: [],
      banks: [],
      natures: [],
      calculationTypeConst: OPERATION_CALCULATION_TYPE,
      customerList: [],
      exchangeBankList: [],
      customerSearchLoading: false,
      customerSearchQuery: '',
      customerApi: new CustomerApi(this.appData.currentUser),
      operationNatureApi: new OperationNatureApi(this.appData.currentUser),
      exchangeBankApi: new ExchangeBankApi(this.appData.currentUser),
      currencyApi: new CurrencyApi(this.appData.currentUser)
    };
  },
  model: {
    prop: 'operationProp',
    event: 'onChange'
  },
  props: {
    operationProp: {
      type: Object
    },
    calculationType: {
      type: [Number, String]
    }
  },
  filters: {
    formatCurrency(value, precison) {
      return numberUtils.formatCurrency('BRL', value, precison);
    },
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);
    }
  },
  watch: {
    operation() {
      this.emitOperation();
    },
    'operation.bank.id'() {
      this.operation.useUsdExchangeContract = !!this.operation.bank?.useUsdExchangeContract;
      this.operation.exchangeContractCost = this.operation.bank.exchangeContractCost;
    },
    'operation.customer'() {
      if (this.operation.customer == undefined) {
        this.exchangeBankList = [];
        this.operation.customer = new CustomerModel();

        return;
      }

      this.getCustomerExchangeBanks();
    },
    customerSearchQuery() {
      clearTimeout(this.customerSearchTimer);

      if (this.customerSearchQuery == this.itemTextCustomers(this.operation.customer)) {
        return;
      }

      if ((this.customerSearchQuery?.length || 0) < 3) {
        this.customerList = [];

        return;
      }

      this.customerSearchTimer = setTimeout(() => {
        this.customerSearchLoading = true;
        this.getCustomersList().then(() => {
          this.customerSearchLoading = false;
        });
      }, 500);
    }
  },
  mounted() {
    this.getBankList();
    this.getNatureList();
    this.getCurrencies();
  },
  computed: {
    bankList() {
      if (this.exchangeBankList.length) {
        return this.exchangeBankList.map((i) => i.bank) || [];
      }

      return (
        this.banks.filter(
          (i) =>
            i.exchangeType.id == OPERATION_TYPE.REMMITANCE.id ||
            i.exchangeType.id == OPERATION_TYPE.BOTH.id
        ) || []
      );
    },
    itemTextCurrencies() {
      return (item) => item.code + ' - ' + item.name;
    },
    spreadHint() {
      if (this.operation.spreadMin && this.operation.spreadMax) {
        return `Min: ${this.operation.spreadMin}% - Max: ${this.operation.spreadMax}%`;
      }

      return '';
    },
    customerRateHint() {
      if (this.operation.customerRateMin && this.operation.customerRateMax) {
        return `Min: ${this.formatBRL(this.operation.customerRateMin, 5)} - Max: ${this.formatBRL(
          this.operation.customerRateMax,
          5
        )}`;
      }

      return '';
    },
    itemTextCustomers() {
      return (item) =>
        !item.name ? item.companyName + ' - ' + item.cpfCnpj : item.name + ' - ' + item.cpfCnpj;
    }
  },
  methods: {
    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      this.currencyApi
        .findAll()
        .then((response) => {
          this.currencies = response.data.map((c) => new CurrencyModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getBankList() {
      if (this.banks[0]) {
        return;
      }

      this.exchangeBankApi
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getNatureList() {
      if (this.natures[0]) {
        return;
      }

      this.operationNatureApi
        .findAll()
        .then((response) => {
          this.natures = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    formatBRL(value, precision) {
      return numberUtils.formatCurrency('BRL', value, precision);
    },

    formatUSD(value) {
      return numberUtils.formatCurrency('USD', value);
    },

    emitOperation() {
      this.$emit('onChange', this.operation);
    },

    getCustomersList() {
      let filters = new CustomerFiltersModel({
        registerStatusList: [CUSTOMER_WORKFLOW.CADASTRO_APROVADO.id],
        searchText: this.customerSearchQuery
      });

      return this.customerApi
        .findAll(filters)
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getCustomerExchangeBanks() {
      if (!this.operation.customer?.id) {
        return;
      }

      this.customerApi
        .findExchangeBanks(this.operation.customer.id, this.operation.exchangeType.id)
        .then((response) => {
          this.exchangeBankList = response.data
            .map((c) => new CustomerExchangeBankModel(c))
            .filter((i) => i.limit.id);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getLimitBank(item) {
      let limit = this.exchangeBankList
        .filter((i) => i.bank.id == item.id)
        .map((i) => i.limit)
        .pop();
      let remainingLimit = 0;

      remainingLimit = limit?.isInOut
        ? (this.operation.exchangeBuy ? limit?.remainingIn : limit?.remainingOut) || 0
        : limit?.remaining || 0;

      return item.useUsdLimit
          ? this.formatUSD(remainingLimit)
          : this.formatBRL(remainingLimit);
    },

    getExpireBank(item) {
      let limit = this.exchangeBankList
        .filter((i) => i.bank.id == item.id)
        .map((i) => i.limit)
        .pop();

      return dateUtils.maskDateIso(limit?.expireAt);
    },
  }
};
</script>

<style lang="scss" scoped>
.operation-title {
  text-align: left;
  font-size: 0.9rem;
}

.operation-value {
  font-size: 1.3rem;
  text-align: right;
  color: var(--v-primary-base);
}

.bank-title {
  font-weight: 400;
}

.theme--light .subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}

.theme--dark .subtitle-dark {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px !important;
}
</style>
