<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo">E-mails enviados</h1>
        <v-divider />
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="4"
        offset-md="9"
        offset-sm="8"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio">
          <v-data-table
            :headers="columns"
            :items="logs"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="paginationDefault"
            :options.sync="options"
            :loading="dataTableIsLoading"
            :server-items-length="emailCount"
          >
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | date }}
            </template>

            <template #[`item.customer.name`]="{ item }">
              <span v-if="item.customer.isPF()">
                {{ item.customer.name }}
              </span>
              <span v-else>
                {{ item.customer.companyName }}
              </span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    text
                    color="primary"
                    @click="resendEmail(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="far fa-inbox-out" />
                  </v-btn>
                </template>
                <span>Reenviar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

// Apis
import apiEmailLog from "@/api/logger/email-log-api";
import DashboardApi from '@/api/generic/dashboard-api';

// Models
import EmailLogModel from "@/model/logger/email-log-model";
import EmailLogFiltersModel from "@/model/logger/email-log-filters-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Utils
import utilsStorage from "@/utils/storage";

// Tracking
import mixpanel from "mixpanel-browser";

// Utils
import dateUtils from "../../../../common/utils/date";
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: "EmailLogsControl",
  mixins: [mixinMessage],
  inject: ['appData'],
  data() {
    return {
      loading: true,
      search: "",
      user: utilsStorage.getUserOnStorage(),
      logs: [],
      columns: [
        { text: "Data de cadastro", value: "createdAt", align: "left", sortable: true },
        { text: "Assunto", value: "subject", align: "left", sortable: true },
        { text: "Cliente", value: "customer.name", align: "left", sortable: true },
        { text: "Corban", value: "corban.name", align: "left", sortable: true },
        { text: "Banco", value: "bank.companyName", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      options: {},
      dataTableIsLoading: false,
      emailCount: 0,
      searchTimer: undefined,
      dashboardApi: new DashboardApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT
    };
  },
  mounted() {
    this.updateDataTableItems();
    mixpanel.track("page_view", {
      name_of_page_viewed: "email_logs_control",
      user_id: this.user.id,
    });
  },

  filters: {
    date(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
  },

  watch: {
    options: {
      handler () {
        this.updateDataTableItems();
      },
      deep: true,
    },

    search(){
      clearTimeout(this.searchTimer);

      this.searchTimer = setTimeout(() => {
        this.updateDataTableItems();
      }, 500);
    },
  },

  methods: {
    updateDataTableItems() {
      let filters = new EmailLogFiltersModel({
        quantity: this.options.itemsPerPage || 10,
        page: this.options.page,
        sortBy: this.options.sortBy?.[0],
        sortDesc: this.options.sortDesc?.[0] ?? false,
        searchText: this.search
      });

      this.dataTableIsLoading = true;
      
      let promiseList = [];
      promiseList.push(this.getEmailCount(filters));
      promiseList.push(this.getLogList(filters));

      Promise.all(promiseList)
        .then(() => {
          this.dataTableIsLoading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getLogList(filters) {
      return apiEmailLog
        .findAll(filters)
        .then((response) => {
          this.logs = response.data.map((b) => new EmailLogModel(b));
        })
        .catch(() => {
          this.sendMessage(
            "Não foi possível obter os logs de e-mails enviados. Tente novamente mais tarde!",
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getEmailCount(filters) {
      return this.dashboardApi
        .getEmailCount(filters)
        .then((response) => {
          this.emailCount = Number(response.data.count);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    resendEmail(email) {
      apiEmailLog
        .resendEmail(email.id)
        .then(() => {
          this.sendMessage('E-mail reenviado!', 'success');
        })
        .catch(() => {
          this.sendMessage(
            "Não foi possível reenviar o e-mail. Tente novamente mais tarde!",
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
