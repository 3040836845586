import utilsDate from "../../../utils/date";

function get(customer, token, bank, externalAccessDocumentToken, complementaryInfo) {
  return [
    {
      name: "Diretores e procuradores",
      children: [
        {
          name: "Nome (Pessoa 1)",
          attribute: "Nome_Diretor_1",
          title: "Nome diretor/procurador (Pessoa 1)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[0]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 1)",
          attribute: "CPF_OU_CNPJ_Diretor_1",
          title: "CPF ou CNPJ diretor/procurador (Pessoa 1)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[0]?.cpfCnpj || '';
          }
        },
        {
          name: "Cargo (Pessoa 1)",
          attribute: "Cargo_Diretor_1",
          title: "Cargo diretor/procurador (Pessoa 1)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[0]?.position || '';
          }
        },
        {
          name: "Data inicial do mandato (Pessoa 1)",
          attribute: "Data_Inicial_Mandato_Diretor_1",
          title: "Data inicial do mandato diretor/procurador (Pessoa 1)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[0]?.termStartDate) || '';
          }
        },
        {
          name: "Data Final do mandato (Pessoa 1)",
          attribute: "Data_Final_Mandato_Diretor_1",
          title: "Data Final do mandato diretor/procurador (Pessoa 1)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[0]?.termEndDate) || '';
          }
        },
        {
          name: "Nome (Pessoa 2)",
          attribute: "Nome_Diretor_2",
          title: "Nome diretor/procurador (Pessoa 2)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[1]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 2)",
          attribute: "CPF_OU_CNPJ_Diretor_2",
          title: "CPF ou CNPJ diretor/procurador (Pessoa 2)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[1]?.cpfCnpj || '';
          }
        },
        {
          name: "Cargo (Pessoa 2)",
          attribute: "Cargo_Diretor_2",
          title: "Cargo diretor/procurador (Pessoa 2)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[1]?.position || '';
          }
        },
        {
          name: "Data inicial do mandato (Pessoa 2)",
          attribute: "Data_Inicial_Mandato_Diretor_2",
          title: "Data inicial do mandato diretor/procurador (Pessoa 2)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[1]?.termStartDate) || '';
          }
        },
        {
          name: "Data Final do mandato (Pessoa 2)",
          attribute: "Data_Final_Mandato_Diretor_2",
          title: "Data Final do mandato diretor/procurador (Pessoa 2)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[1]?.termEndDate) || '';
          }
        },
        {
          name: "Nome (Pessoa 3)",
          attribute: "Nome_Diretor_3",
          title: "Nome diretor/procurador (Pessoa 3)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[2]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 3)",
          attribute: "CPF_OU_CNPJ_Diretor_3",
          title: "CPF ou CNPJ diretor/procurador (Pessoa 3)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[2]?.cpfCnpj || '';
          }
        },
        {
          name: "Cargo (Pessoa 3)",
          attribute: "Cargo_Diretor_3",
          title: "Cargo diretor/procurador (Pessoa 3)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[2]?.position || '';
          }
        },
        {
          name: "Data inicial do mandato (Pessoa 3)",
          attribute: "Data_Inicial_Mandato_Diretor_3",
          title: "Data inicial do mandato diretor/procurador (Pessoa 3)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[2]?.termStartDate) || '';
          }
        },
        {
          name: "Data Final do mandato (Pessoa 3)",
          attribute: "Data_Final_Mandato_Diretor_3",
          title: "Data Final do mandato diretor/procurador (Pessoa 3)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[2]?.termEndDate) || '';
          }
        },
        {
          name: "Nome (Pessoa 4)",
          attribute: "Nome_Diretor_4",
          title: "Nome diretor/procurador (Pessoa 4)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[3]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 4)",
          attribute: "CPF_OU_CNPJ_Diretor_4",
          title: "CPF ou CNPJ diretor/procurador (Pessoa 4)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[3]?.cpfCnpj || '';
          }
        },
        {
          name: "Cargo (Pessoa 4)",
          attribute: "Cargo_Diretor_4",
          title: "Cargo diretor/procurador (Pessoa 4)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[3]?.position || '';
          }
        },
        {
          name: "Data inicial do mandato (Pessoa 4)",
          attribute: "Data_Inicial_Mandato_Diretor_4",
          title: "Data inicial do mandato diretor/procurador (Pessoa 4)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[3]?.termStartDate) || '';
          }
        },
        {
          name: "Data Final do mandato (Pessoa 4)",
          attribute: "Data_Final_Mandato_Diretor_4",
          title: "Data Final do mandato diretor/procurador (Pessoa 4)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[3]?.termEndDate) || '';
          }
        },
        {
          name: "Nome (Pessoa 5)",
          attribute: "Nome_Diretor_5",
          title: "Nome diretor/procurador (Pessoa 5)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[4]?.name || '';
          }
        },
        {
          name: "CPF ou CNPJ (Pessoa 5)",
          attribute: "CPF_OU_CNPJ_Diretor_5",
          title: "CPF ou CNPJ diretor/procurador (Pessoa 5)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[4]?.cpfCnpj || '';
          }
        },
        {
          name: "Cargo (Pessoa 5)",
          attribute: "Cargo_Diretor_5",
          title: "Cargo diretor/procurador (Pessoa 5)",
          inline: true,
          content: () => {
            return customer?.directorsProxies?.[4]?.position || '';
          }
        },
        {
          name: "Data inicial do mandato (Pessoa 5)",
          attribute: "Data_Inicial_Mandato_Diretor_5",
          title: "Data inicial do mandato diretor/procurador (Pessoa 5)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[4]?.termStartDate) || '';
          }
        },
        {
          name: "Data Final do mandato (Pessoa 5)",
          attribute: "Data_Final_Mandato_Diretor_5",
          title: "Data Final do mandato diretor/procurador (Pessoa 5)",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(customer?.directorsProxies?.[4]?.termEndDate) || '';
          }
        }
      ]
    }
  ]
}

export default { get }