<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col>
          <h1>Spread de remessa</h1>
          <v-divider />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="d-flex align-center">
          <v-btn
            data-test="btn-adicionar-spread"
            class="btn-primary ml-0"
            text
            @click="openAddSpreadModal"
          >
            <i class="far fa-plus" />
            Adicionar
          </v-btn>
        </v-col>

        <v-col>
          <v-text-field
            data-test="ipt-pesquisar-moeda"
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-data-table
            data-test="tbl-spreads"
            :headers="columns"
            :items="customer.spreads"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="paginationDefault"
          >
            <template #[`item.actions`]="{ item }">
              <td class="buttons-column">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="btn-primary"
                      text
                      @click="editSpreadModal(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="far fa-pencil" />
                      Editar
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>

    <!-- Add Spread Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="40vw"
      max-width="100vh"
      v-model="addSpreadModal"
      :fullscreen="appData.isMobile"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Spread</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-spread"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <CurrencySelect
                      data-test="slc-moeda"
                      :currency-list="notUsedCurrencies"
                      v-model="spread.currency"
                      :required="false"
                    />
                  </v-col>

                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      data-test="txt-spread-minimo-venda"
                      v-model="spread.minimumSpreadSell"
                      outlined
                      dense
                      label="Spread Mínimo Venda(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      data-test="txt-spread-recomendado-venda"
                      v-model="spread.recommendedSpreadSell"
                      outlined
                      dense
                      label="Spread Recomendado Venda(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      data-test="txt-spread-maximo-venda"
                      v-model="spread.maximumSpreadSell"
                      outlined
                      dense
                      label="Spread Máximo Venda(%)"
                      suffix="%"
                    />
                  </v-col>

                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      data-test="txt-spread-minimo-compra"
                      v-model="spread.minimumSpreadBuy"
                      outlined
                      dense
                      label="Spread Mínimo Compra(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      data-test="txt-spread-recomendado-compra"
                      v-model="spread.recommendedSpreadBuy"
                      outlined
                      dense
                      label="Spread Recomendado Compra(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      data-test="txt-spread-maximo-compra"
                      v-model="spread.maximumSpreadBuy"
                      outlined
                      dense
                      label="Spread Máximo Compra(%)"
                      suffix="%"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              data-test="btn-salvar-spread"
              v-if="!update"
              text
              class="btn-primary mr-2"
              @click="addSpread"
            >
              Salvar
            </v-btn>
            <v-btn
              data-test="btn-atualizar-spread"
              v-else-if="update"
              text
              class="btn-primary mr-2"
              @click="updateSpread"
            >
              Salvar
            </v-btn>
            <v-btn
              data-test="btn-cancelar-spread"
              text
              class="btn-tertiary"
              @click="addSpreadModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import CurrencyApi from '@/api/spread/currency-api';

// Components
import NumberField from '@/components/comum/NumberField';
import CurrencySelect from '../form-operation/CurrencySelect.vue';

// Models
import CustomerSpreadModel from '@/model/customer-spread-model';
import CurrencyModel from '@/model/currency-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import { PAGINATION_DEFAULT } from '@/constants/general-constants';

export default {
  name: 'CustomerSpreads',
  mixins: [mixinMessage],
  components: {
    NumberField,
    CurrencySelect
  },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      required: true
    }
  },
  inject: ['appData'],
  data() {
    return {
      customer: this.customerProp,
      search: '',
      currencies: [],
      columns: [
        { text: 'Código', value: 'currency.code', align: 'left', sortable: true },
        { text: 'Nome', value: 'currency.name', align: 'left', sortable: true },
        {
          text: 'Spread mínimo venda(%)',
          value: 'minimumSpreadSell',
          align: 'right',
          sortable: true
        },
        {
          text: 'Spread recomendado venda(%)',
          value: 'recommendedSpreadSell',
          align: 'right',
          sortable: true
        },
        {
          text: 'Spread máximo venda(%)',
          value: 'maximumSpreadSell',
          align: 'right',
          sortable: true
        },
        {
          text: 'Spread mínimo compra(%)',
          value: 'minimumSpreadBuy',
          align: 'right',
          sortable: true
        },
        {
          text: 'Spread recomendado compra(%)',
          value: 'recommendedSpreadBuy',
          align: 'right',
          sortable: true
        },
        {
          text: 'Spread máximo compra(%)',
          value: 'maximumSpreadBuy',
          align: 'right',
          sortable: true
        },
        { text: '', value: 'actions', align: 'right', sortable: false }
      ],
      spread: new CustomerSpreadModel(),
      addSpreadModal: false,
      update: false,
      paginationDefault: PAGINATION_DEFAULT,
      currencyApi: new CurrencyApi(this.appData.currentUser)
    };
  },
  mounted() {
    this.getCurrencies();
  },
  computed: {
    notUsedCurrencies() {
      let filtered = this.currencies.filter((currency) => {
        return (
          this.customer.spreads.filter((spread) => {
            return spread.currency.id == currency.id;
          }).length == 0
        );
      });

      if (this.spread?.currency?.id) {
        filtered.push(this.spread.currency);
      }

      return filtered;
    }
  },
  methods: {
    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      this.currencyApi
        .findAll()
        .then((response) => {
          this.currencies = response.data.map((c) => new CurrencyModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    openAddSpreadModal() {
      this.update = false;
      this.spread = new CustomerSpreadModel();
      this.addSpreadModal = true;
    },

    editSpreadModal(spread) {
      this.update = true;
      this.spread = Object.assign({}, spread);
      this.addSpreadModal = true;
    },

    addSpread() {
      this.customer.spreads.push(new CustomerSpreadModel(this.spread));
      this.addSpreadModal = false;
    },

    updateSpread() {
      let index = this.customer.spreads.findIndex((s) => s.id == this.spread.id);
      this.customer.spreads.splice(index, 1, new CustomerSpreadModel(this.spread));
      this.addSpreadModal = false;
    }
  }
};
</script>

<style></style>
