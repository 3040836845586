import BaseApi from '../baseApi';

export default class ExchangeBankTypeApi extends BaseApi {
  constructor(obj){
      obj = obj || {};
      super(obj);
  }

  findAll() {
    return new Promise((resolve, reject) => {
      return this.api
        .get(`/exchange-banks/types`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}