<template>
  <v-row class="py-2">
    <v-col
      class="py-0"
      cols="12"
      md="3"
    >
      <v-text-field
        data-test="txt-nome-responsavel-cadastro"
        v-model="customer.additional.nameRegistrationResponsible"
        outlined
        dense
        label="Nome"
        :rules="[validationIsRequiredField]"
        :data-tab-key="customerFormTab.REGISTRATION_DATA.key"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="3"
    >
      <v-text-field
        data-test="txt-cargo"
        v-model="customer.additional.positonRegistrationResponsible"
        outlined
        dense
        label="Cargo"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="3"
    >
      <v-text-field
        data-test="txt-email"
        v-model="customer.additional.emailRegistrationResponsible"
        outlined
        dense
        label="Email"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="3"
    >
      <v-text-field
        data-test="txt-telefone"
        v-model="customer.additional.cellphoneRegistrationResponsible"
        outlined
        dense
        label="Telefone"
      />
    </v-col>
  </v-row>
</template>

<script>
// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Constants
import { CUSTOMER_FORM_TAB } from "@/constants/customer-form-constants";

export default {
  name: 'PJCustomerResponsibleRegistration',
  mixins: [mixinValidationRules],
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      customer: this.customerProp,
      customerFormTab: CUSTOMER_FORM_TAB
    };
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  }
};
</script>

<style></style>
